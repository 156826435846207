import { Fragment, useEffect } from 'react';
import { compose } from 'redux';

import { FilesItem, FilesNavigation, NotFound } from './Files.styles';
import { Box } from '../../components/atom/containers/Box.styles';
import {
	Table2,
	TableData2,
} from '../../components/molecule/table/Table.styles';
import { LoadingList } from '../../components/atom/loader/Loader.component';

import { WithReduxFiles } from '../../components/hoc/WithReduxFiles.hoc';

import { ReactComponent as FileIcon } from '../../assets/file.svg';
import { ReactComponent as FolderIcon } from '../../assets/folder.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/arrow-left.svg';

const FilesList = ({
	files,
	folders,
	back,
	fullPath,
	fetchFilesStart,
	fetchFilesFromFolderStart,
	fetchFilesFromBackStart,
	clearFilesReducer,
	isFetching,
}) => {
	const nothingFound = files.length === 0 && folders.length === 0;

	useEffect(() => {
		clearFilesReducer();
		fetchFilesStart();

		return () => {
			clearFilesReducer();
		};
	}, [fetchFilesStart, clearFilesReducer]);

	return isFetching ? (
		<LoadingList />
	) : (
		<Box padding='48px'>
			<Table2 gridColumns='1fr'>
				{fullPath && (
					<FilesNavigation>
						<ArrowLeftIcon
							onClick={() => fetchFilesFromBackStart(back)}
						/>
						<div>
							<span className='folder-text'>Folder:</span>
							<span className='folder-location'>{fullPath}</span>
						</div>
					</FilesNavigation>
				)}
				{nothingFound ? (
					<NotFound>No files or folders were found</NotFound>
				) : (
					<Fragment>
						{folders.map((folder, index) => (
							<FilesItem key={index}>
								<TableData2
									className='flex-center'
									onClick={() =>
										fetchFilesFromFolderStart(
											fullPath,
											folder
										)
									}
								>
									<FolderIcon />
									{folder}
								</TableData2>
							</FilesItem>
						))}
						{files.map((file, index) => (
							<FilesItem
								key={index}
								href={file.downloadURL}
								target='_blank'
							>
								<TableData2 className='flex-center'>
									<FileIcon className='file-icon' />
									{file.name}
								</TableData2>
							</FilesItem>
						))}
					</Fragment>
				)}
			</Table2>
		</Box>
	);
};

export default compose(WithReduxFiles)(FilesList);
