import { OppositionsTypes } from './types';

/* ================================================================ */
/*  Process Start  	                                                */
/* ================================================================ */

export const fetchOppositionsStart = () => ({
	type: OppositionsTypes.FETCH_OPPOSITIONS_START,
});

export const setFilteredOppositions = oppositions => ({
	type: OppositionsTypes.SET_FILTERED_OPPOSITIONS,
	payload: oppositions,
});

export const setOppositionsIsFetching = () => ({
	type: OppositionsTypes.SET_OPPOSITIONS_IS_FETCHING,
});

export const setOppositionsIsEdit = bool => ({
	type: OppositionsTypes.SET_OPPOSITIONS_IS_EDIT,
	payload: bool,
});

export const setOppositionFilterTeamTypeId = id => ({
	type: OppositionsTypes.SET_OPPOSITIONS_FILTER_TEAM_TYPE_ID,
	payload: id,
});

export const clearOppositionsFilterTeamTypeId = () => ({
	type: OppositionsTypes.CLEAR_OPPOSITIONS_FILTER_TEAM_TYPE_ID,
});

export const clearOppositionsReducer = () => ({
	type: OppositionsTypes.CLEAR_OPPOSITIONS_REDUCER,
});

/* ================================================================ */
/*  Process Success	                                                */
/* ================================================================ */

export const fetchOppositionsSuccess = oppositions => ({
	type: OppositionsTypes.FETCH_OPPOSITIONS_SUCCESS,
	payload: oppositions,
});

/* ================================================================ */
/*  Process Failure	       	                                        */
/* ================================================================ */

export const fetchOppositionsFailure = errorMessage => ({
	type: OppositionsTypes.FETCH_OPPOSITIONS_FAILURE,
	payload: errorMessage,
});
