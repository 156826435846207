import { PlayersActionTypes } from './types';

/* ================================================================ */
/*  Process Start  	                                                */
/* ================================================================ */

export const fetchPlayersStart = () => ({
	type: PlayersActionTypes.FETCH_PLAYERS_START,
});

export const setPlayersIsFetching = () => ({
	type: PlayersActionTypes.SET_PLAYERS_IS_FETCHING,
});

export const setPlayersIsEdit = bool => ({
	type: PlayersActionTypes.SET_PLAYERS_IS_EDIT,
	payload: bool,
});

export const setPlayersFilterTeamTypeId = id => ({
	type: PlayersActionTypes.SET_PLAYERS_FILTER_TEAM_TYPE_ID,
	payload: id,
});

export const setFilteredPlayers = players => ({
	type: PlayersActionTypes.SET_FILTERED_PLAYERS,
	payload: players,
});

export const setPlayersSearchFilter = search => ({
	type: PlayersActionTypes.SET_PLAYERS_SEARCH_FILTER,
	payload: search,
});

export const clearPlayersReducer = () => ({
	type: PlayersActionTypes.CLEAR_PLAYERS_REDUCER,
});

export const clearPlayersSearchFilter = () => ({
	type: PlayersActionTypes.CLEAR_PLAYERS_SEARCH_FILTER,
});

export const clearAllPlayersFilter = () => ({
	type: PlayersActionTypes.CLEAR_ALL_PLAYERS_FILTERS,
});

/* ================================================================ */
/*  Process Success	                                                */
/* ================================================================ */

export const fetchPlayersSuccess = players => ({
	type: PlayersActionTypes.FETCH_PLAYERS_SUCCESS,
	payload: players,
});

/* ================================================================ */
/*  Process Failure	       	                                        */
/* ================================================================ */

export const fetchPlayersFailure = errorMessage => ({
	type: PlayersActionTypes.FETCH_PLAYERS_FAILURE,
	payload: errorMessage,
});
