import TeamTypesActionTypes from './types';

/* ================================================================ */
/*  Process Start  	                                                */
/* ================================================================ */

export const fetchTeamTypesStart = () => ({
	type: TeamTypesActionTypes.FETCH_TEAM_TYPES_START,
});

export const clearTeamTypesReducer = () => ({
	type: TeamTypesActionTypes.CLEAR_TEAM_TYPES_REDUCER,
});

/* ================================================================ */
/*  Process Success	                                                */
/* ================================================================ */

export const fetchTeamTypesSuccess = teamTypes => ({
	type: TeamTypesActionTypes.FETCH_TEAM_TYPES_SUCCESS,
	payload: teamTypes,
});

/* ================================================================ */
/*  Process Failure	       	                                        */
/* ================================================================ */

export const fetchTeamTypesFailure = errorMessage => ({
	type: TeamTypesActionTypes.FETCH_TEAM_TYPES_FAILURE,
	payload: errorMessage,
});
