import { UserTypes } from './types';

/* ================================================================ */
/*  Process Start  	                                                */
/* ================================================================ */

export const emailSignInStart = () => ({
	type: UserTypes.EMAIL_SIGN_IN_START,
});

export const setLoginEmail = email => ({
	type: UserTypes.SET_LOGIN_EMAIL,
	payload: email,
});

export const setLoginPassword = password => ({
	type: UserTypes.SET_LOGIN_PASSWORD,
	payload: password,
});

export const setResetPassword = bool => ({
	type: UserTypes.SET_RESET_PASSWORD,
	payload: bool,
});

export const clearLoginCredentials = () => ({
	type: UserTypes.CLEAR_LOGIN_CREDENTIALS,
});

export const clearAfterPasswordReset = () => ({
	type: UserTypes.CLEAR_AFTER_PASSWORD_RESET,
});

export const clearLoginErrorMessage = () => ({
	type: UserTypes.CLEAR_LOGIN_ERROR_MESSAGE,
});

export const sendPasswordResetEmailStart = () => ({
	type: UserTypes.SEND_PASSWORD_RESET_EMAIL_START,
});

export const logOut = () => ({
	type: UserTypes.LOG_OUT,
});

/* ================================================================ */
/*  Process Success	                                                */
/* ================================================================ */

export const emailSignInSuccess = user => ({
	type: UserTypes.EMAIL_SIGN_IN_SUCCESS,
	payload: user,
});

export const sendPasswordResetEmailSuccess = () => ({
	type: UserTypes.SEND_PASSWORD_RESET_EMAIL_SUCCESS,
});

/* ================================================================ */
/*  Process Failure	       	                                        */
/* ================================================================ */

export const emailSignInFailure = errorMessage => ({
	type: UserTypes.EMAIL_SIGN_IN_FAILURE,
	payload: errorMessage,
});

export const sendPasswordResetEmailFailure = errorMessage => ({
	type: UserTypes.SEND_PASSWORD_RESET_EMAIL_FAILURE,
	payload: errorMessage,
});
