import { takeLatest, put, all, call, select } from 'redux-saga/effects';

// Firebase
import { storage } from '../../../../firebase/firebase.utils';

// Types
import { FilesTypes } from './types';

// Actions
import {
	fetchFilesFailure,
	fetchFilesSuccess,
	fetchFilesFromFolderFailure,
	fetchFilesFromFolderSuccess,
	fetchFilesFromBackFailure,
	fetchFilesFromBackSuccess,
} from './actions';

// Selectors
import { selectFiles } from './selectors';

/* ================================================================ */
/*  Actions                                                         */
/* ================================================================ */
const createStateObject = async (listAll, state) => {
	const files = listAll.items.map(item => item.name);
	const folders = listAll.prefixes.map(folder => folder.name);
	const newFiles = files.map(async file => {
		const downloadPath = state.fullPath
			? `${state.fullPath}/${file}`
			: file;
		const downloadURL = await storage
			.ref()
			.child(downloadPath)
			.getDownloadURL();

		return { name: file, downloadURL };
	});
	const resolvedFiles = await Promise.all(newFiles.map(file => file));

	return { ...state, files: resolvedFiles, folders };
};

const createBackPath = (path = '') => {
	const array = path.split('/');

	if (!array.length || array.length === 1) return '';
	if (array.length > 1) {
		array.pop();
		return array.join('/');
	}
};

const createNewState = async (path, state) => {
	const back = createBackPath(path);
	const listOfFiles = await storage.ref().child(path).listAll();
	const newState = await createStateObject(listOfFiles, {
		...state,
		fullPath: path,
		back,
	});

	return newState;
};

export function* fetchFilesStart() {
	try {
		const { isFetching, errorMessage, ...state } = yield select(
			selectFiles
		);

		const storageRef = yield storage.ref();
		const listAll = yield storageRef.listAll();
		const newFiles = yield createStateObject(listAll, state);

		yield put(fetchFilesSuccess(newFiles));
	} catch (error) {
		console.error(error);
		yield put(fetchFilesFailure(error));
	}
}

export function* fetchFilesFromFolderStart({ payload: { fullPath, folder } }) {
	try {
		const { isFetching, errorMessage, ...state } = yield select(
			selectFiles
		);

		const path = yield fullPath ? `${fullPath}/${folder}` : folder;
		const newState = yield createNewState(path, state);

		yield put(fetchFilesFromFolderSuccess(newState));
	} catch (error) {
		console.error(error);
		yield put(fetchFilesFromFolderFailure);
	}
}

export function* fetchFilesFromBackStart({ payload: path }) {
	try {
		const { isFetching, errorMessage, ...state } = yield select(
			selectFiles
		);

		const newState = yield createNewState(path, state);

		yield put(fetchFilesFromBackSuccess(newState));
	} catch (error) {
		console.error(error);
		yield put(fetchFilesFromBackFailure(error));
	}
}

/* ================================================================ */
/*  Listeners                                                       */
/* ================================================================ */

export function* onFetchFilesStart() {
	yield takeLatest(FilesTypes.FETCH_FILES_START, fetchFilesStart);
}

export function* onFetchFilesFromFolderStart() {
	yield takeLatest(
		FilesTypes.FETCH_FILES_FROM_FOLDER_START,
		fetchFilesFromFolderStart
	);
}

export function* onFetchFilesFromBackStart() {
	yield takeLatest(
		FilesTypes.FETCH_FILES_FROM_BACK_START,
		fetchFilesFromBackStart
	);
}
/* ================================================================ */
/*  Root Saga                                                       */
/* ================================================================ */

export default function* filesSagas() {
	yield all([
		call(onFetchFilesStart),
		call(onFetchFilesFromFolderStart),
		call(onFetchFilesFromBackStart),
	]);
}
