import { FilesTypes } from './types';

const INITIAL_STATE = {
	folders: [],
	files: [],
	fullPath: '',
	back: '',
	isFetching: false,
	errorMessage: '',
};

const filesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* ================================================================ */
		/*  Process Start                                                   */
		/* ================================================================ */
		case FilesTypes.FETCH_FILES_START:
		case FilesTypes.FETCH_FILES_FROM_FOLDER_START:
		case FilesTypes.FETCH_FILES_FROM_BACK_START:
			return {
				...state,
				isFetching: true,
			};
		/* ================================================================ */
		/*  Process Success                                                 */
		/* ================================================================ */
		case FilesTypes.FETCH_FILES_SUCCESS:
		case FilesTypes.FETCH_FILES_FROM_FOLDER_SUCCESS:
		case FilesTypes.FETCH_FILES_FROM_BACK_SUCCESS:
			return {
				...state,
				folders: action.payload.folders,
				files: action.payload.files,
				fullPath: action.payload.fullPath,
				back: action.payload.back,
				isFetching: false,
			};
		/* ================================================================ */
		/*  Process Failure                                                 */
		/* ================================================================ */
		case FilesTypes.FETCH_FILES_FAILURE:
		case FilesTypes.FETCH_FILES_FROM_FOLDER_FAILURE:
		case FilesTypes.FETCH_FILES_FROM_BACK_FAILURE:
			return {
				...state,
				errorMessage: action.payload,
				isFetching: false,
			};
		/* ================================================================ */
		/*  Clear Reducer                                                   */
		/* ================================================================ */
		case FilesTypes.CLEAR_FILES_REDUCER:
			return {
				...state,
				folders: [],
				files: [],
				fullPath: '',
				back: '',
				isFetching: false,
				errorMessage: '',
			};
		default:
			return state;
	}
};

export default filesReducer;
