import styled from 'styled-components';

export const InputGrid = styled.div`
	display: grid;
	width: 100%;
	grid-auto-columns: 1fr;
	grid-column-gap: 40px;
	grid-row-gap: 32px;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: auto;

	.full-width {
		grid-column-end: span 3;
	}
`;

export const ScoreBlock = styled.div`
	display: grid;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-columns: 1fr 20px 1fr;
	grid-template-rows: auto;
	align-items: center;
`;

export const PlayerBlock = styled.div`
	margin-top: 32px;
`;

export const AddPlayerBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	.label {
		margin-right: 40px;
		font-size: 16px;
		font-weight: 700;
	}
`;

export const PlayersListGrid = styled.div`
	display: grid;
	margin-top: 32px;
	grid-auto-columns: 1fr;
	grid-column-gap: 40px;
	grid-row-gap: 4px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
`;

export const PlayerItem = styled.div`
	display: flex;
	padding: 8px 12px;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);

	.player-name {
		cursor: pointer;
	}
`;

export const PlayerInputBlock = styled.div`
	display: flex;

	.input {
		width: 240px;
	}

	.first-input {
		margin-right: 40px;
	}

	.vertical {
		margin: 0 12px;
	}
`;

export const UploadFileBlock = styled.div`
	display: flex;
	align-items: center;
	margin: 32px 0;

	input {
		display: none;
	}

	.upload-button {
		margin-right: 20px;
	}

	.upload-file-name {
		margin-right: 8px;
	}
`;
