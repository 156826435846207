import styled, { css } from 'styled-components';

export const InputBlock = styled.div`
	display: grid;
	grid-auto-columns: 1fr;
	grid-column-gap: 40px;
	grid-row-gap: 20px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;

	.full-width {
		grid-column-end: span 2;
	}

	${props =>
		props.gridColumns &&
		css`
			grid-template-columns: ${props.gridColumns};
		`}
`;

export const ButtonBlock = styled.div`
	display: flex;
	margin-top: 60px;
	justify-content: flex-end;

	.save-button {
		margin-left: 20px;
	}
`;

// TODO: New Design

export const ButtonBlock2 = styled.div`
	display: flex;
	margin-top: 60px;
	justify-content: center;

	.save-button {
		margin-left: 20px;
	}
`;

// TODO: Possible removal
export const SwitchBlock = styled.div`
	display: flex;
	flex-direction: column;

	.MuiSwitch-root {
		margin-left: -12px;
	}
`;

export const ListFilterBlock = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 24px 0;

	.MuiFormControl-root {
		width: 280px;
	}

	.MuiButton-containedPrimary {
		color: #fff;
		background-color: #094899;
	}
	.MuiButton-containedPrimary:hover {
		background-color: #5173ca;
	}
`;
