export const MatchesTypes = {
	FETCH_MATCHES_START: 'FETCH_MATCHES_START',
	FETCH_MATCHES_SUCCESS: 'FETCH_MATCHES_SUCCESS',
	FETCH_MATCHES_FAILURE: 'FETCH_MATCHES_FAILURE',

	SET_MATCHES_IS_EDIT: 'SET_MATCHES_IS_EDIT',

	SET_MATCHES_FILTER_TEAM_ID: 'SET_MATCHES_FILTER_TEAM_ID',
	SET_FILTERED_MATCHES: 'SET_FILTERED_MATCHES',

	CLEAR_MATCHES_FILTER_TEAM_ID: 'CLEAR_MATCHES_FILTER_TEAM_ID',
	CLEAR_MATCHES_REDUCER: 'CLEAR_MATCHES_REDUCER',
};
