import styled from 'styled-components';
import Flatpickr from 'react-flatpickr';

import 'flatpickr/dist/themes/material_blue.css';

import { colorStyles, inputStyles } from '../../../styles/global.styles';

export const StyledFlatpickr = styled(Flatpickr)`
	${inputStyles.input}
`;

export const DatePickerBlock = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	label {
		margin-bottom: 4px;
		text-transform: capitalize;
		color: ${colorStyles.greyScale4};

		span {
			color: ${colorStyles.error};
		}
	}

	.error-label {
		height: 16px;

		p {
			font-size: 14px;
			color: red;
		}
	}
`;
