import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import axios from 'axios';
import _ from 'lodash';

import { BASEAPI } from '../../../routes/routes';
import { getCurrentUserToken } from '../../../firebase/firebase.utils';

// Types
import { MatchesTypes } from './types';

// Actions
import {
	fetchMatchesFailure,
	fetchMatchesSuccess,
	setFilteredMatches,
	clearMatchesFilterTeamId,
} from './actions';
import { fetchMyTeamsSuccess } from '../../my-teams/redux/list-screen/actions';

// Selectors
import { selectMatches } from './selectors';

/* ================================================================ */
/*  Actions                                                         */
/* ================================================================ */

export function* fetchMatchesStart() {
	try {
		yield put(clearMatchesFilterTeamId());

		const token = yield call(getCurrentUserToken);
		const HEADERS = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		const [{ data: matches }, { data: teams }] = yield all([
			call(axios.get, `${BASEAPI}/matches`, HEADERS),
			call(axios.get, `${BASEAPI}/teams?is_dropdown=true`, HEADERS),
		]);

		yield put(fetchMatchesSuccess(matches));
		yield put(setFilteredMatches(matches));
		yield put(fetchMyTeamsSuccess(teams));
	} catch (error) {
		console.error(error);
		yield put(fetchMatchesFailure(error));
	}
}

export function* setFilteredMatchesStart({ payload: id }) {
	try {
		const matches = yield select(selectMatches);

		const filteredMatches = yield _.filter(matches, ['teamId', id]);

		yield put(setFilteredMatches(filteredMatches));
	} catch (error) {
		console.error(error);
	}
}

/* ================================================================ */
/*  Listeners                                                       */
/* ================================================================ */

export function* onFetchMatchesStart() {
	yield takeLatest(MatchesTypes.FETCH_MATCHES_START, fetchMatchesStart);
}

export function* onSetFilteredMatchesStart() {
	yield takeLatest(
		MatchesTypes.SET_MATCHES_FILTER_TEAM_ID,
		setFilteredMatchesStart
	);
}

/* ================================================================ */
/*  Root Saga                                                       */
/* ================================================================ */

export default function* matchesSagas() {
	yield all([call(onFetchMatchesStart), call(onSetFilteredMatchesStart)]);
}
