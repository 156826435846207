import { createSelector } from 'reselect';

const selectMyTeamsStore = state => state.myTeams;

// Selects myTeams.myTeams from the state
export const selectMyTeams = createSelector(
	[selectMyTeamsStore],
	myTeams => myTeams.myTeams
);

// Selects myTeams.isFetching from the state
export const selectMyTeamsIsFetching = createSelector(
	[selectMyTeamsStore],
	myTeams => myTeams.isFetching
);

// Selects myTeams.isEdit from the state
export const selectMyTeamsIsEdit = createSelector(
	[selectMyTeamsStore],
	myTeams => myTeams.isEdit
);
