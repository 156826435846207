import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// Redux: Selectors
import {
	selectCountries,
	selectCountriesIsFetching,
} from '../../redux/countries/selectors';

// Redux: Actions
import { fetchCountriesStart } from '../../redux/countries/actions';

export const WithReduxCountries = WrappedComponent => {
	// Add Redux actions and state to the WrappedComponent
	const WithData = props => {
		return <WrappedComponent {...props} />;
	};

	// Redux: Selectors
	const mapStateToProps = createStructuredSelector({
		countries: selectCountries,
		countriesIsFetching: selectCountriesIsFetching,
	});

	// Redux: Actions
	const mapDispatchToProps = dispatch => ({
		fetchCountriesStart: () => dispatch(fetchCountriesStart()),
	});

	return connect(mapStateToProps, mapDispatchToProps)(WithData);
};
