import { createSelector } from 'reselect';

const selectSingleMyTeamStore = state => state.singleMyTeam;

// Selects singleMyTeam.singleMyTeam from the state
export const selectSingleMyTeam = createSelector(
	[selectSingleMyTeamStore],
	singleMyTeam => singleMyTeam.singleMyTeam
);

// Selects singleMyTeam.isSingleFetching from the state
export const selectSingleMyTeamIsSingleFetching = createSelector(
	[selectSingleMyTeamStore],
	singleMyTeam => singleMyTeam.isSingleFetching
);
