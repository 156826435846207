import { compose } from 'redux';

import { FilterBlock2 } from '../../../components/molecule/table/Table.styles';
import {
	IconButton,
	Button,
} from '../../../components/atom/buttons/Button.styles';
import { InputField } from '../../../components/molecule/input-field/InputField.component';
import { Dropdown } from '../../../components/atom/dropdown/Dropdown.styles';
import { FilterButtons, Filters } from '../PlayersList.styles';
import { ReactComponent as PlusIcon } from '../../../assets/plus.svg';
import { ReactComponent as CloseIcon } from '../../../assets/close.svg';

import { WithReduxPlayers } from '../../../components/hoc/WithReduxPlayers.hoc';
import { WithReduxMyTeams } from '../../../components/hoc/WithReduxMyTeams.hoc';

const ListFilter = ({
	playersFilterSearch,
	playersFilterTeamTypeId,
	setPlayersSearchFilter,
	setPlayersFilterTeamTypeId,
	clearPlayersSearchFilter,
	clearAllPlayersFilter,
	newSinglePlayerStart,
	myTeams,
}) => {
	const filtersSize = '260px';
	const hasFilters =
		playersFilterSearch !== '' || playersFilterTeamTypeId !== '';
	const handlDropdown = event => {
		const value = event.target.value;
		value
			? setPlayersFilterTeamTypeId(parseInt(value, 10))
			: setPlayersFilterTeamTypeId('');
	};

	return (
		<FilterBlock2>
			<div>
				<Filters>
					<InputField
						disableError
						size={filtersSize}
						placeholder='Search for Players'
						value={playersFilterSearch}
						action={setPlayersSearchFilter}
					/>
					{playersFilterSearch && (
						<CloseIcon onClick={clearPlayersSearchFilter} />
					)}
					<Dropdown
						className='filter-team'
						size={filtersSize}
						value={playersFilterTeamTypeId}
						onChange={handlDropdown}
					>
						<option value=''>- Filter by Team -</option>
						{myTeams.map((team, index) => (
							<option key={index} value={team.id}>
								{team.name}
							</option>
						))}
					</Dropdown>
				</Filters>
				<FilterButtons>
					<Button
						disabled={!hasFilters}
						onClick={clearAllPlayersFilter}
					>
						Reset All
					</Button>
				</FilterButtons>
			</div>
			<IconButton className='new-button' onClick={newSinglePlayerStart}>
				<PlusIcon />
				New Player
			</IconButton>
		</FilterBlock2>
	);
};

export default compose(WithReduxPlayers, WithReduxMyTeams)(ListFilter);
