import { compose } from 'redux';
import { Route, Switch } from 'react-router-dom';

import * as ROUTES from '../../../routes/routes';

import { ContentContainer2, TopMenu, Content } from './ContentBlock.styles';
import CompetitionList from '../../../pages/competition/CompetitionList.page';
import OppositionsList from '../../../pages/oppositions/OppositionsList.page';
import MyTeamsList from '../../../pages/my-teams/MyTeamsList.page';
import PlayersList from '../../../pages/players/PlayersList.page';
import MatchesList from '../../../pages/matches/MatchesList.page';
import MatchEdit from '../../../pages/matches/edit-page/MatchEdit.page';
import FilesList from '../../../pages/files/Files.page';
import { AvatarInitial } from '../../atom/avatar/Avatar.styles';
import { ReactComponent as PowerIcon } from '../../../assets/power.svg';

import { WithReduxUser } from '../../hoc/WithReduxUser.hoc';

const ContentBlock = ({ currentUser, logOut }) => {
	return (
		<Switch>
			<ContentContainer2>
				<TopMenu>
					<AvatarInitial>{currentUser.initial}</AvatarInitial>
					<div className='seperator' />
					<PowerIcon onClick={logOut} />
				</TopMenu>
				<Content>
					<Route exact path={`/${ROUTES.MATCHES}`}>
						<MatchesList />
					</Route>
					<Route exact path={`/${ROUTES.MATCHES}/:matchId`}>
						<MatchEdit />
					</Route>
					<Route exact path={`/${ROUTES.MY_TEAMS}`}>
						<MyTeamsList />
					</Route>
					<Route exact path={`/${ROUTES.PLAYERS}`}>
						<PlayersList />
					</Route>
					<Route exact path={`/${ROUTES.OPPOSITIONS}`}>
						<OppositionsList />
					</Route>
					<Route exact path={`/${ROUTES.COMPETITION}`}>
						<CompetitionList />
					</Route>
					<Route exact path={`/${ROUTES.FILES}`}>
						<FilesList />
					</Route>
				</Content>
			</ContentContainer2>
		</Switch>
	);
};

export default compose(WithReduxUser)(ContentBlock);
