import { SingleCompetitionTypes } from './types';

const INITIAL_STATE = {
	singleCompetition: {
		id: 0,
		name: '',
		countryId: '',
		teamTypeId: '',
	},
	isSingleFetching: false,
	errorMessage: '',
};

const singleCompetitionReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* ================================================================ */
		/*  Process Start                                                   */
		/* ================================================================ */
		case SingleCompetitionTypes.FETCH_SINGLE_COMPETITION_START:
			return {
				...state,
				isSingleFetching: true,
			};
		case SingleCompetitionTypes.SET_COMPETITION_NAME:
		case SingleCompetitionTypes.SET_COMPETITION_COUNTRY_ID:
		case SingleCompetitionTypes.SET_COMPETITION_TEAM_TYPE_ID:
			return {
				...state,
				singleCompetition: {
					...state.singleCompetition,
					[action.inputName]: action.payload,
				},
			};
		case SingleCompetitionTypes.CLEAR_SINGLE_COMPETITION:
			return {
				...state,
				singleCompetition: {
					id: 0,
					name: '',
					countryId: '',
					teamTypeId: '',
				},
				isSingleFetching: false,
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Success                                                 */
		/* ================================================================ */
		case SingleCompetitionTypes.FETCH_SINGLE_COMPETITION_SUCCESS:
			return {
				...state,
				singleCompetition: action.payload,
				isSingleFetching: false,
				errorMessage: '',
			};
		case SingleCompetitionTypes.CREATE_SINGLE_COMPETITION_SUCCESS:
		case SingleCompetitionTypes.UPDATE_SINGLE_COMPETITION_SUCCESS:
			return {
				...state,
				singleCompetition: {
					id: 0,
					name: '',
					countryId: '',
					teamTypeId: '',
				},
				isSingleFetching: false,
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Failure                                                 */
		/* ================================================================ */
		case SingleCompetitionTypes.FETCH_SINGLE_COMPETITION_FAILURE:
		case SingleCompetitionTypes.CREATE_SINGLE_COMPETITION_FAILURE:
		case SingleCompetitionTypes.UPDATE_SINGLE_COMPETITION_FAILURE:
			return {
				...state,
				errorMessage: action.payload,
				isSingleFetching: false,
			};
		default:
			return state;
	}
};

export default singleCompetitionReducer;
