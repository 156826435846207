import styled from 'styled-components';
import { shadowStyles } from '../../../styles/global.styles';

export const ContentContainer = styled.div`
	grid-area: content;
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: auto;
	padding: 40px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	.MuiFab-root {
		position: fixed;
		left: auto;
		top: auto;
		right: 40px;
		bottom: 40px;
		background-color: #094899;
		color: #fff;

		&:hover {
			background-color: #5173ca;
		}
	}
`;

export const ContentContainer2 = styled.div`
	grid-area: content;
	display: grid;
	grid-auto-columns: 1fr;
	grid-template-areas:
		'top-menu'
		'content';
	grid-template-columns: 1fr;
	grid-template-rows: 74px auto;
	overflow: hidden;
`;

export const TopMenu = styled.div`
	grid-area: top-menu;
	display: flex;
	padding: 12px;
	justify-content: flex-end;
	align-items: center;
	background-color: #fff;
	z-index: 1;
	${shadowStyles.shadow1};

	.seperator {
		width: 1px;
		height: 100%;
		margin-right: 12px;
		margin-left: 12px;
		background-color: rgba(0, 0, 0, 0.1);
	}

	svg {
		width: 28px;
		cursor: pointer;
	}
`;

export const Content = styled.div`
	grid-area: content;
	padding: 40px;
	overflow: auto;
`;
