import { Fragment } from 'react';
import { compose } from 'redux';

import {
	Table2,
	TableHeader2,
	TableData2,
	Chip,
	TableDataChip2,
} from '../../../components/molecule/table/Table.styles';

import { WithReduxOppositions } from '../../../components/hoc/WithReduxOppositions.hoc';

export const List = ({ filteredOppositions, fetchSingleOppositionStart }) => {
	return (
		<Table2 gridColumns='1fr 1fr 1fr'>
			<TableHeader2>Opposition Team Name</TableHeader2>
			<TableHeader2>Opposition Team Type</TableHeader2>
			<TableHeader2>Is Active</TableHeader2>
			{filteredOppositions.map((opposition, index) => (
				<Fragment key={index}>
					<TableData2
						onClick={() => {
							fetchSingleOppositionStart(opposition.id);
						}}
					>
						{opposition.name}
					</TableData2>
					<TableData2
						onClick={() => {
							fetchSingleOppositionStart(opposition.id);
						}}
					>
						{opposition.teamTypeLabel}
					</TableData2>
					<TableDataChip2
						onClick={() => {
							fetchSingleOppositionStart(opposition.id);
						}}
					>
						<Chip isActive={opposition.isActive}>
							{opposition.isActive ? 'Active' : 'Inactive'}
						</Chip>
					</TableDataChip2>
				</Fragment>
			))}
		</Table2>
	);
};

export default compose(WithReduxOppositions)(List);
