import { Fragment } from 'react';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';

import * as ROUTES from '../../../routes/routes';

import { ButtonBlock2 } from '../../../components/atom/inputs/Inputs.styles';
import { Seperator } from '../../../components/atom/seperator/Seperator.styles';
import InputSection from './components/InputSection.component';
import UploadSection from './components/UploadSection.component';
import PlayersSection from './components/PlayersSection.component';
import AddPlayerPopup from './components/AddPlayerPopup.component';
import { Box } from '../../../components/atom/containers/Box.styles';
import {
	InverseButton,
	Button,
} from '../../../components/atom/buttons/Button.styles';

import { WithReduxMatches } from '../../../components/hoc/WithReduxMatches.hoc';
import { LoadingList } from '../../../components/atom/loader/Loader.component';

const MatchEdit = ({
	matchesIsEdit,
	singleMatch,
	matchesIsSingleFetching,
	createSingleMatchStart,
	clearSingleMatchReducer,
	updateSingleMatchStart,
}) => {
	const { push } = useHistory();
	const isFormEmpty =
		singleMatch.teamId === '' ||
		singleMatch.teamScore === '' ||
		singleMatch.oppositionScore === '' ||
		singleMatch.oppositionId === '' ||
		singleMatch.competitionId === '' ||
		singleMatch.date === null;

	const handleOnClick = () => {
		if (singleMatch.id) {
			updateSingleMatchStart();
			push(`/${ROUTES.MATCHES}`);
		}
		if (!singleMatch.id && !isFormEmpty) createSingleMatchStart();
		if (!singleMatch.id && isFormEmpty) return;
	};

	return (
		<Fragment>
			<Box padding='48px'>
				{!matchesIsSingleFetching ? (
					<div>
						<InputSection />
						<UploadSection />
						<Seperator className='full-width' />
						{singleMatch.id ? <PlayersSection /> : null}
					</div>
				) : (
					<LoadingList />
				)}
				<ButtonBlock2>
					<InverseButton
						onClick={() => {
							clearSingleMatchReducer();
							push(`/${ROUTES.MATCHES}`);
						}}
					>
						Cancel
					</InverseButton>
					<Button
						className='save-button'
						disabled={isFormEmpty}
						onClick={handleOnClick}
					>
						{singleMatch.id ? 'Update' : 'Create'}
					</Button>
				</ButtonBlock2>
			</Box>
			{matchesIsEdit && <AddPlayerPopup />}
		</Fragment>
	);
};

export default compose(WithReduxMatches)(MatchEdit);
