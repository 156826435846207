import { SingleCompetitionTypes } from './types';

/* ================================================================ */
/*  Process Start  	                                                */
/* ================================================================ */

export const fetchSingleCompetitionStart = id => ({
	type: SingleCompetitionTypes.FETCH_SINGLE_COMPETITION_START,
	payload: id,
});

export const setCompetitionName = name => ({
	type: SingleCompetitionTypes.SET_COMPETITION_NAME,
	payload: name,
	inputName: 'name',
});

export const setCompetitionCountryId = id => ({
	type: SingleCompetitionTypes.SET_COMPETITION_COUNTRY_ID,
	payload: id,
	inputName: 'countryId',
});

export const setCompetitionTeamTypeId = id => ({
	type: SingleCompetitionTypes.SET_COMPETITION_TEAM_TYPE_ID,
	payload: id,
	inputName: 'teamTypeId',
});

export const createSingleCompetitionStart = () => ({
	type: SingleCompetitionTypes.CREATE_SINGLE_COMPETITION_START,
});

export const updateSingleCompetitionStart = () => ({
	type: SingleCompetitionTypes.UPDATE_SINGLE_COMPETITION_START,
});

export const clearSingleCompetition = () => ({
	type: SingleCompetitionTypes.CLEAR_SINGLE_COMPETITION,
});

/* ================================================================ */
/*  Process Success	                                                */
/* ================================================================ */

export const fetchSingleCompetitionSuccess = competition => ({
	type: SingleCompetitionTypes.FETCH_SINGLE_COMPETITION_SUCCESS,
	payload: competition,
});

export const createSingleCompetitionSuccess = competition => ({
	type: SingleCompetitionTypes.CREATE_SINGLE_COMPETITION_SUCCESS,
	payload: competition,
});

export const updateSingleCompetitionSuccess = competition => ({
	type: SingleCompetitionTypes.UPDATE_SINGLE_COMPETITION_SUCCESS,
	payload: competition,
});

/* ================================================================ */
/*  Process Failure	       	                                        */
/* ================================================================ */

export const fetchSingleCompetitionFailure = errorMessage => ({
	type: SingleCompetitionTypes.FETCH_SINGLE_COMPETITION_FAILURE,
	payload: errorMessage,
});

export const createSingleCompetitionFailure = errorMessage => ({
	type: SingleCompetitionTypes.CREATE_SINGLE_COMPETITION_FAILURE,
	payload: errorMessage,
});

export const updateSingleCompetitionFailure = errorMessage => ({
	type: SingleCompetitionTypes.UPDATE_SINGLE_COMPETITION_FAILURE,
	payload: errorMessage,
});
