import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import axios from 'axios';
import _ from 'lodash';

import { getCurrentUserToken } from '../../../../firebase/firebase.utils';
import { BASEAPI } from '../../../../routes/routes';

// Types
import { OppositionsTypes } from './types';

// Actions
import {
	clearOppositionsFilterTeamTypeId,
	fetchOppositionsFailure,
	fetchOppositionsSuccess,
	setFilteredOppositions,
} from './actions';

// selectors
import { selectOppositions } from './selectors';

/* ================================================================ */
/*  Actions                                                         */
/* ================================================================ */

export function* fetchOppositionsStart() {
	try {
		yield put(clearOppositionsFilterTeamTypeId());

		const token = yield call(getCurrentUserToken);

		const { data: oppositions } = yield call(
			axios.get,
			`${BASEAPI}/oppositions`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		yield put(fetchOppositionsSuccess(oppositions));
		yield put(setFilteredOppositions(oppositions));
	} catch (error) {
		console.error(error);
		yield put(fetchOppositionsFailure(error));
	}
}

export function* setFilteredOppositionsStart({ payload: id }) {
	try {
		const oppositions = yield select(selectOppositions);

		const filteredOppositions = yield _.filter(oppositions, [
			'teamTypeId',
			id,
		]);

		yield put(setFilteredOppositions(filteredOppositions));
	} catch (error) {
		console.error(error);
	}
}

/* ================================================================ */
/*  Listeners                                                       */
/* ================================================================ */

export function* onFetchOppositionsStart() {
	yield takeLatest(
		OppositionsTypes.FETCH_OPPOSITIONS_START,
		fetchOppositionsStart
	);
}

export function* onSetFilteredOppositionsStart() {
	yield takeLatest(
		OppositionsTypes.SET_OPPOSITIONS_FILTER_TEAM_TYPE_ID,
		setFilteredOppositionsStart
	);
}

/* ================================================================ */
/*  Root Saga                                                       */
/* ================================================================ */

export default function* oppositionsSagas() {
	yield all([
		call(onFetchOppositionsStart),
		call(onSetFilteredOppositionsStart),
	]);
}
