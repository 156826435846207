import { Fragment, useEffect } from 'react';
import { compose } from 'redux';

import ListFilter from './components/ListFilter2.component';
import List from './components/List2.component';
import EditPopup from './components/EditPopup2.component';
import { Box } from '../../components/atom/containers/Box.styles';
import { LoadingList } from '../../components/atom/loader/Loader.component';

import { WithReduxCompetition } from '../../components/hoc/WithReduxCompetition.hoc';

const CompetitionList = ({
	fetchCompetitionStart,
	competitionIsEdit,
	competitionIsFetching,
}) => {
	useEffect(() => {
		fetchCompetitionStart();
	}, [fetchCompetitionStart]);

	return (
		<Fragment>
			{!competitionIsFetching ? (
				<Box padding='48px'>
					<ListFilter />
					<List />
				</Box>
			) : (
				<LoadingList />
			)}
			{competitionIsEdit && <EditPopup />}
		</Fragment>
	);
};

export default compose(WithReduxCompetition)(CompetitionList);
