import styled from 'styled-components';
import loginBG from '../../../../assets/login-bg-1.svg';

export const LoginLogoBlock = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-image: url(${loginBG});
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;

	.logo-info {
		display: flex;
		margin-top: 20vh;
		flex-direction: column;
		align-items: center;

		img {
			width: 100px;
		}

		.logo-label-top {
			margin-top: 32px;
			color: #fff;
			font-size: 36px;
			line-height: 36px;
			font-weight: 900;
			text-align: center;
			letter-spacing: 8px;
			text-transform: uppercase;
		}

		.logo-label-bottom {
			color: #fff;
			font-size: 36px;
			line-height: 36px;
			font-weight: 100;
			letter-spacing: 20px;
			text-transform: uppercase;
		}

		.seperator {
			width: 250px;
			height: 1px;
			margin-top: 32px;
			margin-bottom: 32px;
			background-color: #fff;
		}
	}
`;
