import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import { BASEAPI } from '../../routes/routes';
import { getCurrentUserToken } from '../../firebase/firebase.utils';

// Types
import PositionTypesActionTypes from './types';

// Actions
import {
	fetchPositionTypesSuccess,
	fetchPositionTypesFailure,
} from './actions';

/* ================================================================ */
/*  Actions                                                         */
/* ================================================================ */

export function* fetchPositionTypesStart() {
	try {
		const token = yield call(getCurrentUserToken);

		const { data: positionTypes } = yield call(
			axios.get,
			`${BASEAPI}/position-types`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		yield put(fetchPositionTypesSuccess(positionTypes));
	} catch (error) {
		console.error(error);
		yield put(fetchPositionTypesFailure(error));
	}
}

/* ================================================================ */
/*  Listeners                                                       */
/* ================================================================ */

export function* onFetchPositionTypesStart() {
	yield takeLatest(
		PositionTypesActionTypes.FETCH_POSITION_TYPES_START,
		fetchPositionTypesStart
	);
}

/* ================================================================ */
/*  Root Saga                                                       */
/* ================================================================ */

export default function* positionTypesSagas() {
	yield all([call(onFetchPositionTypesStart)]);
}
