import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import axios from 'axios';

import { getCurrentUserToken } from '../../../../firebase/firebase.utils';
import { BASEAPI } from '../../../../routes/routes';

// Types
import { SingleOppositionTypes } from './types';

// Actions
import {
	fetchSingleOppositionSuccess,
	fetchSingleOppositionFailure,
	createSingleOppositionFailure,
	createSingleOppositionSuccess,
	updateSingleOppositionSuccess,
} from './actions';
import {
	clearOppositionsFilterTeamTypeId,
	fetchOppositionsStart,
	setOppositionsIsEdit,
} from '../list-screen/actions';

// selectors
import { selectSingleOpposition } from './selectors';

/* ================================================================ */
/*  Actions                                                         */
/* ================================================================ */

export function* fetchSingleOppositionStart({ payload: id }) {
	try {
		yield put(setOppositionsIsEdit(true));

		const token = yield call(getCurrentUserToken);
		const { data: opposition } = yield call(
			axios.get,
			`${BASEAPI}/oppositions/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		yield put(fetchSingleOppositionSuccess(opposition));
	} catch (error) {
		console.error(error);
		yield put(fetchSingleOppositionFailure(error));
		yield put(setOppositionsIsEdit(false));
	}
}

export function* createSingleOppositionStart() {
	try {
		const token = yield call(getCurrentUserToken);

		const { id, ...singleOpposition } = yield select(
			selectSingleOpposition
		);

		yield call(
			axios.post,
			`${BASEAPI}/oppositions`,
			{ ...singleOpposition },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		yield put(fetchOppositionsStart());
		yield put(setOppositionsIsEdit(false));
		yield put(clearOppositionsFilterTeamTypeId());
		yield put(createSingleOppositionSuccess());
	} catch (error) {
		console.error(error);
		yield put(createSingleOppositionFailure(error));
	}
}

export function* updateSingleOppositionStart() {
	try {
		const token = yield call(getCurrentUserToken);

		const { id, ...singleOpposition } = yield select(
			selectSingleOpposition
		);

		yield call(
			axios.patch,
			`${BASEAPI}/oppositions/${id}`,
			{ ...singleOpposition },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		yield put(fetchOppositionsStart());
		yield put(setOppositionsIsEdit(false));
		yield put(clearOppositionsFilterTeamTypeId());
		yield put(updateSingleOppositionSuccess());
	} catch (error) {
		console.error(error);
		yield put(createSingleOppositionFailure(error));
	}
}

/* ================================================================ */
/*  Listeners                                                       */
/* ================================================================ */

export function* onFetchSingleOppositionStart() {
	yield takeLatest(
		SingleOppositionTypes.FETCH_SINGLE_OPPOSITION_START,
		fetchSingleOppositionStart
	);
}

export function* onCreateSingleOppositionStart() {
	yield takeLatest(
		SingleOppositionTypes.CREATE_SINGLE_OPPOSITION_START,
		createSingleOppositionStart
	);
}

export function* onUpdateSingleOppositionStart() {
	yield takeLatest(
		SingleOppositionTypes.UPDATE_SINGLE_OPPOSITION_START,
		updateSingleOppositionStart
	);
}

/* ================================================================ */
/*  Root Saga                                                       */
/* ================================================================ */

export default function* singleOppositionsSagas() {
	yield all([
		call(onFetchSingleOppositionStart),
		call(onCreateSingleOppositionStart),
		call(onUpdateSingleOppositionStart),
	]);
}
