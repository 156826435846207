const PositionTypesActionTypes = {
	FETCH_POSITION_TYPES_START: 'FETCH_POSITION_TYPES_START',
	FETCH_POSITION_TYPES_SUCCESS: 'FETCH_POSITION_TYPES_SUCCESS',
	FETCH_POSITION_TYPES_FAILURE: 'FETCH_POSITION_TYPES_FAILURE',

	SET_POSITION_TYPES_IS_FETCHING: 'SET_POSITION_TYPES_IS_FETCHING',

	CLEAR_POSITION_TYPES_REDUCER: 'CLEAR_POSITION_TYPES_REDUCER',
};

export default PositionTypesActionTypes;
