import { compose } from 'redux';

import { WithReduxOppositions } from '../../../components/hoc/WithReduxOppositions.hoc';
import { WithReduxTeamTypes } from '../../../components/hoc/WithReduxTeamTypes.hoc';
import { FilterBlock2 } from '../../../components/molecule/table/Table.styles';
import { Dropdown } from '../../../components/atom/dropdown/Dropdown.styles';
import { IconButton } from '../../../components/atom/buttons/Button.styles';
import { ReactComponent as PlusIcon } from '../../../assets/plus.svg';

const ListFilter = ({
	oppositionsFilterTeamTypeId,
	setOppositionFilterTeamTypeId,
	setOppositionsIsEdit,
	clearSingleOpposition,
	clearOppositionsFilterTeamTypeId,
	teamTypes,
}) => {
	const handlDropdown = event => {
		const value = event.target.value;
		value
			? setOppositionFilterTeamTypeId(parseInt(value, 10))
			: clearOppositionsFilterTeamTypeId();
	};

	return (
		<FilterBlock2>
			<Dropdown
				size='260px'
				value={oppositionsFilterTeamTypeId}
				onChange={handlDropdown}
			>
				<option value=''>- Filter by Types -</option>
				{teamTypes.map((teamtype, index) => (
					<option key={index} value={teamtype.id}>
						{teamtype.label}
					</option>
				))}
			</Dropdown>
			<IconButton
				onClick={() => {
					clearSingleOpposition();
					setOppositionsIsEdit(true);
				}}
			>
				<PlusIcon />
				new opposition
			</IconButton>
		</FilterBlock2>
	);
};

export default compose(WithReduxOppositions, WithReduxTeamTypes)(ListFilter);
