import NumberFormat from 'react-number-format';

// Convert an array to an object
export const convertArrayToObject = (array, key) => {
	const initialValue = {};

	return array.reduce((obj, item) => {
		return {
			...obj,
			[item[key]]: item,
		};
	}, initialValue);
};

export const validateEmail = email => {
	const regEx =
		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

	if (regEx.test(email)) return true;

	return false;
};

export const inputValidator = (condition, errorText) => {
	if (condition) {
		return {
			isError: true,
			errorText,
		};
	} else {
		return {
			isError: false,
			errorText: '',
		};
	}
};

export const NumberFormatter = props => {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			isNumericString
			allowNegative={false}
		/>
	);
};
