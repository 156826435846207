import { createSelector } from 'reselect';

const selectSingleOppositionsStore = state => state.singleOpposition;

// Selects singleOpposition.singleOpposition from the state
export const selectSingleOpposition = createSelector(
	[selectSingleOppositionsStore],
	singleOpposition => singleOpposition.singleOpposition
);

// Selects singleOpposition.isFetching from the state
export const selectSingleOppositionIsSingleFetching = createSelector(
	[selectSingleOppositionsStore],
	singleOpposition => singleOpposition.isSingleFetching
);
