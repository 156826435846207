import { Fragment } from 'react';
import { compose } from 'redux';

import { UploadFileBlock } from '../MatchEdit.styles';
import { IconButton } from '../../../../components/atom/buttons/Button.styles';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';
import { ReactComponent as FileReplaceIcon } from '../../../../assets/file-replace.svg';

import { WithReduxMatches } from '../../../../components/hoc/WithReduxMatches.hoc';
import { colorStyles } from '../../../../styles/global.styles';

const UploadSection = ({
	setSingleMatchXmlFile,
	singleMatch,
	xmlFile,
	clearSingleMatchXmlFile,
}) => {
	return (
		<UploadFileBlock>
			<div className='upload-button'>
				<input
					accept='.xml'
					id='button-file'
					type='file'
					onChange={event =>
						setSingleMatchXmlFile(event.target.files[0])
					}
				/>
				<label htmlFor='button-file'>
					<IconButton>
						{singleMatch.hasXmlBucketFilename ? (
							<FileReplaceIcon />
						) : (
							<PlusIcon />
						)}
						{singleMatch.hasXmlBucketFilename ? 'Replace ' : 'Add '}
						XML File
					</IconButton>
				</label>
			</div>
			{xmlFile && (
				<Fragment>
					<div className='upload-file-name'>{xmlFile.name}</div>
					<IconButton
						round
						color={colorStyles.error}
						onClick={clearSingleMatchXmlFile}
					>
						<CloseIcon />
					</IconButton>
				</Fragment>
			)}
		</UploadFileBlock>
	);
};

export default compose(WithReduxMatches)(UploadSection);
