import styled from 'styled-components';

export const MainBlock = styled.main`
	display: grid;
	height: 100vh;
	grid-auto-columns: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	grid-template-areas: 'menu content';
	grid-template-columns: 220px 1fr;
	grid-template-rows: auto;
	background-color: #eee;
`;

export const MainBlock2 = styled.main`
	display: grid;
	width: 100%;
	height: 100vh;
	grid-auto-columns: 1fr;
	grid-template-areas: 'menu content';
	grid-template-columns: 280px 1fr;
	grid-template-rows: auto;
	background-color: #f5f8fa;
`;
