import { all, call } from 'redux-saga/effects';

import oppositionsSagas from '../pages/oppositions/redux/list-screen/sagas';
import singleOppositionsSagas from '../pages/oppositions/redux/edit-screen/sagas';
import myTeamsSagas from '../pages/my-teams/redux/list-screen/sagas';
import singleMyTeamSagas from '../pages/my-teams/redux/edit-screen/sagas';
import playersSagas from '../pages/players/redux/list-screen/sagas';
import singlePlayerSagas from '../pages/players/redux/edit-screen/sagas';
import userSagas from '../pages/login/redux/sagas';
import competitionSagas from '../pages/competition/redux/list-screen//sagas';
import singleCompetitionSagas from '../pages/competition/redux/edit-screen//sagas';
import matchesSagas from '../pages/matches/redux/sagas';
import singleMatchSagas from '../pages/matches/edit-page/redux/sagas';
import filesSagas from '../pages/files/redux/list-screen/sagas';

import teamTypesSagas from './team-types/sagas';
import positionTypesSagas from './position-types/sagas';
import countriesSagas from './countries/sagas';

export default function* rootSaga() {
	yield all([
		call(oppositionsSagas),
		call(singleOppositionsSagas),
		call(myTeamsSagas),
		call(singleMyTeamSagas),
		call(playersSagas),
		call(singlePlayerSagas),
		call(userSagas),
		call(matchesSagas),
		call(singleMatchSagas),
		call(competitionSagas),
		call(singleCompetitionSagas),
		call(filesSagas),
		call(countriesSagas),
		call(teamTypesSagas),
		call(positionTypesSagas),
	]);
}
