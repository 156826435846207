import { createSelector } from 'reselect';

const selectCountriesStore = state => state.countries;

// Selects countries.countries from the state
export const selectCountries = createSelector(
	[selectCountriesStore],
	countries => countries.countries
);

// Selects countries.isFetching from the state
export const selectCountriesIsFetching = createSelector(
	[selectCountriesStore],
	countries => countries.isFetching
);
