import styled, { css } from 'styled-components';
import { colorStyles } from '../../styles/global.styles';

export const FilesItem = styled.a`
	text-decoration: none;

	svg {
		width: 24px;
		margin-right: 8px;

		path {
			fill: ${colorStyles.primary};
		}
	}

	.file-icon {
		path {
			fill: ${colorStyles.error};
		}
	}

	.flex-center {
		display: flex;
		align-items: center;
		text-transform: none;
	}
`;

export const FilesNavigation = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: 12px;

	svg {
		width: 24px;
		margin-right: 24px;
		cursor: pointer;
	}

	.folder-text {
		color: ${colorStyles.greyScale5};
		margin-right: 8px;
	}

	.folder-location {
		color: ${colorStyles.primary};
		margin-right: 8px;
	}
`;

export const NotFound = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
	color: ${colorStyles.greyScale5};
	font-style: italic;
`;
