import { SingleMyTeamTypes } from './types';

const INITIAL_STATE = {
	singleMyTeam: {
		name: '',
		isActive: true,
		storageFolder: '',
		defaultMatchMinutes: '',
		countryId: '',
		teamTypeId: '',
		id: 0,
	},
	isSingleFetching: false,
	errorMessage: '',
};

const singleMyTeamReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* ================================================================ */
		/*  Process Start                                                   */
		/* ================================================================ */
		case SingleMyTeamTypes.FETCH_SINGLE_MY_TEAM_START:
			return {
				...state,
				isSingleFetching: true,
			};
		case SingleMyTeamTypes.SET_SINGLE_MY_TEAM_NAME:
		case SingleMyTeamTypes.SET_SINGLE_MY_TEAM_COUNTRY_ID:
		case SingleMyTeamTypes.SET_SINGLE_MY_TEAM_TYPE_ID:
		case SingleMyTeamTypes.SET_SINGLE_MY_TEAM_STORAGE_FOLDER:
		case SingleMyTeamTypes.SET_SINGLE_MY_TEAM_DEFAULT_MATCH_MINUTES:
		case SingleMyTeamTypes.SET_SINGLE_MY_TEAM_IS_ACTIVE:
			return {
				...state,
				singleMyTeam: {
					...state.singleMyTeam,
					[action.inputName]: action.payload,
				},
			};
		case SingleMyTeamTypes.CLEAR_SINGLE_MY_TEAM:
		case SingleMyTeamTypes.CREATE_SINGLE_MY_TEAM_SUCCESS:
		case SingleMyTeamTypes.UPDATE_SINGLE_MY_TEAM_SUCCESS:
			return {
				...state,
				singleMyTeam: {
					name: '',
					isActive: true,
					storageFolder: '',
					defaultMatchMinutes: '',
					countryId: '',
					teamTypeId: '',
					id: 0,
				},
				isSingleFetching: false,
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Success                                                 */
		/* ================================================================ */
		case SingleMyTeamTypes.FETCH_SINGLE_MY_TEAM_SUCCESS:
			return {
				...state,
				singleMyTeam: action.payload,
				isSingleFetching: false,
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Failure                                                 */
		/* ================================================================ */
		case SingleMyTeamTypes.FETCH_SINGLE_MY_TEAM_FAILURE:
		case SingleMyTeamTypes.CREATE_SINGLE_MY_TEAM_FAILURE:
		case SingleMyTeamTypes.UPDATE_SINGLE_MY_TEAM_FAILURE:
			return {
				...state,
				errorMessage: action.payload,
				isSingleFetching: false,
			};
		default:
			return state;
	}
};

export default singleMyTeamReducer;
