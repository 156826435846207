import { createSelector } from 'reselect';

const selectMatchesStore = state => state.matches;

// Selects matches.matches from the state
export const selectMatches = createSelector(
	[selectMatchesStore],
	matches => matches.matches
);

// Selects matches.filteredMatches from the state
export const selectFilteredMatches = createSelector(
	[selectMatchesStore],
	matches => matches.filteredMatches
);

// Selects matches.filterTeamId from the state
export const selectMatchesFilterTeamId = createSelector(
	[selectMatchesStore],
	matches => matches.filterTeamId
);

// Selects matches.isFetching from the state
export const selectMatchesIsFetching = createSelector(
	[selectMatchesStore],
	matches => matches.isFetching
);

// Selects matches.isEdit from the state
export const selectMatchesIsEdit = createSelector(
	[selectMatchesStore],
	matches => matches.isEdit
);
