import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// Redux: Selectors
import {
	selectFilesFiles,
	selectFilesFolders,
	selectFilesBack,
	selectFilesFullPath,
	selectFilesErrorMessage,
	selectFilesIsFetching,
} from '../../pages/files/redux/list-screen/selectors';

// Redux: Actions
import {
	fetchFilesStart,
	fetchFilesFromFolderStart,
	fetchFilesFromBackStart,
	clearFilesReducer,
} from '../../pages/files/redux/list-screen/actions';

export const WithReduxFiles = WrappedComponent => {
	// Add Redux actions and state to the WrappedComponent
	const WithData = props => {
		return <WrappedComponent {...props} />;
	};

	// Redux: Selectors
	const mapStateToProps = createStructuredSelector({
		files: selectFilesFiles,
		folders: selectFilesFolders,
		back: selectFilesBack,
		fullPath: selectFilesFullPath,
		errorMessage: selectFilesErrorMessage,
		isFetching: selectFilesIsFetching,
	});

	// Redux: Actions
	const mapDispatchToProps = dispatch => ({
		fetchFilesStart: () => dispatch(fetchFilesStart()),
		fetchFilesFromFolderStart: (fullPath, folder) =>
			dispatch(fetchFilesFromFolderStart(fullPath, folder)),
		fetchFilesFromBackStart: path =>
			dispatch(fetchFilesFromBackStart(path)),
		clearFilesReducer: () => dispatch(clearFilesReducer()),
	});

	return connect(mapStateToProps, mapDispatchToProps)(WithData);
};
