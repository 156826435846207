import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import { getCurrentUserToken } from '../../firebase/firebase.utils';
import { BASEAPI } from '../../routes/routes';

// Types
import CountriesActionTypes from './types';

// Actions
import { fetchCountriesFailure, fetchCountriesSuccess } from './actions';

/* ================================================================ */
/*  Actions                                                         */
/* ================================================================ */

export function* fetchCountriesStart() {
	try {
		const token = yield call(getCurrentUserToken);

		const { data: countries } = yield call(
			axios.get,
			`${BASEAPI}/countries`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		yield put(fetchCountriesSuccess(countries));
	} catch (error) {
		console.error(error);
		yield put(fetchCountriesFailure(error));
	}
}

/* ================================================================ */
/*  Listeners                                                       */
/* ================================================================ */

export function* onFetchCountriesStart() {
	yield takeLatest(
		CountriesActionTypes.FETCH_COUNTRIES_START,
		fetchCountriesStart
	);
}

/* ================================================================ */
/*  Root Saga                                                       */
/* ================================================================ */

export default function* countriesSagas() {
	yield all([call(onFetchCountriesStart)]);
}
