import { compose } from 'redux';

import {
	EditBlock,
	PopupOverlay,
} from '../../../components/atom/pop-up/PopUp.styles';
import {
	ButtonBlock2,
	InputBlock,
} from '../../../components/atom/inputs/Inputs.styles';
import {
	InverseButton,
	Button,
} from '../../../components/atom/buttons/Button.styles';
import { InputField } from '../../../components/molecule/input-field/InputField.component';
import { DropdownField } from '../../../components/molecule/dropdown-field/DropdownField.component';
import { Switch } from '../../../components/molecule/switch/Switch.component';
import { LoadingList } from '../../../components/atom/loader/Loader.component';

import { WithReduxOppositions } from '../../../components/hoc/WithReduxOppositions.hoc';
import { WithReduxCountries } from '../../../components/hoc/WithReduxCountries.hoc';
import { WithReduxTeamTypes } from '../../../components/hoc/WithReduxTeamTypes.hoc';

const EditPopup = ({
	setOppositionsIsEdit,
	setOppositionName,
	setOppositionCode,
	setOppositionCountryId,
	setOppositionTeamTypeId,
	setOppositionIsActive,
	clearSingleOpposition,
	createSingleOppositionStart,
	updateSingleOppositionStart,
	singleOpposition,
	oppositionIsSingleFetching,
	countries,
	teamTypes,
}) => {
	const inputSize = '260px';
	const isFormFilled =
		singleOpposition.name !== '' &&
		singleOpposition.code !== '' &&
		singleOpposition.countryId !== '' &&
		singleOpposition.teamTypeId !== '';

	return (
		<PopupOverlay>
			<EditBlock>
				{!oppositionIsSingleFetching ? (
					<InputBlock>
						<InputField
							required
							size={inputSize}
							label='Name'
							value={singleOpposition.name}
							action={setOppositionName}
						/>
						<InputField
							required
							size={inputSize}
							label='Code'
							value={singleOpposition.code}
							action={setOppositionCode}
						/>
						<DropdownField
							required
							size={inputSize}
							label='Countries'
							nullLabel='- Select -'
							value={singleOpposition.countryId}
							data={countries}
							dataProp='name'
							action={setOppositionCountryId}
						/>
						<DropdownField
							required
							size={inputSize}
							label='Team Types'
							nullLabel='- Select -'
							value={singleOpposition.teamTypeId}
							data={teamTypes}
							dataProp='label'
							action={setOppositionTeamTypeId}
						/>
						<Switch
							id='oppositions-is-active'
							label='Is Active'
							value={singleOpposition.isActive}
							action={setOppositionIsActive}
						/>
					</InputBlock>
				) : (
					<LoadingList />
				)}
				<ButtonBlock2>
					<InverseButton
						onClick={() => {
							setOppositionsIsEdit(false);
							clearSingleOpposition();
						}}
					>
						Cancel
					</InverseButton>
					<Button
						className='save-button'
						disabled={!isFormFilled}
						onClick={() =>
							singleOpposition.id
								? updateSingleOppositionStart()
								: createSingleOppositionStart()
						}
					>
						{singleOpposition.id ? 'Update' : 'Create'}
					</Button>
				</ButtonBlock2>
			</EditBlock>
		</PopupOverlay>
	);
};

export default compose(
	WithReduxOppositions,
	WithReduxCountries,
	WithReduxTeamTypes
)(EditPopup);
