export const SingleMyTeamTypes = {
	FETCH_SINGLE_MY_TEAM_START: 'FETCH_SINGLE_MY_TEAM_START',
	FETCH_SINGLE_MY_TEAM_SUCCESS: 'FETCH_SINGLE_MY_TEAM_SUCCESS',
	FETCH_SINGLE_MY_TEAM_FAILURE: 'FETCH_SINGLE_MY_TEAM_FAILURE',

	NEW_SINGLE_MY_TEAM_START: 'NEW_SINGLE_MY_TEAM_START',
	NEW_SINGLE_MY_TEAM_SUCCESS: 'NEW_SINGLE_MY_TEAM_SUCCESS',
	NEW_SINGLE_MY_TEAM_FAILURE: 'NEW_SINGLE_MY_TEAM_FAILURE',

	CREATE_SINGLE_MY_TEAM_START: 'CREATE_SINGLE_MY_TEAM_START',
	CREATE_SINGLE_MY_TEAM_SUCCESS: 'CREATE_SINGLE_MY_TEAM_SUCCESS',
	CREATE_SINGLE_MY_TEAM_FAILURE: 'CREATE_SINGLE_MY_TEAM_FAILURE',

	UPDATE_SINGLE_MY_TEAM_START: 'UPDATE_SINGLE_MY_TEAM_START',
	UPDATE_SINGLE_MY_TEAM_SUCCESS: 'UPDATE_SINGLE_MY_TEAM_SUCCESS',
	UPDATE_SINGLE_MY_TEAM_FAILURE: 'UPDATE_SINGLE_MY_TEAM_FAILURE',

	SET_SINGLE_MY_TEAM_NAME: 'SET_SINGLE_MY_TEAM_NAME',
	SET_SINGLE_MY_TEAM_COUNTRY_ID: 'SET_SINGLE_MY_TEAM_COUNTRY_ID',
	SET_SINGLE_MY_TEAM_TYPE_ID: 'SET_SINGLE_MY_TEAM_TYPE_ID',
	SET_SINGLE_MY_TEAM_STORAGE_FOLDER: 'SET_SINGLE_MY_TEAM_STORAGE_FOLDER',
	SET_SINGLE_MY_TEAM_DEFAULT_MATCH_MINUTES:
		'SET_SINGLE_MY_TEAM_DEFAULT_MATCH_MINUTES',
	SET_SINGLE_MY_TEAM_IS_ACTIVE: 'SET_SINGLE_MY_TEAM_IS_ACTIVE',

	CLEAR_SINGLE_MY_TEAM: 'CLEAR_SINGLE_MY_TEAM',
};
