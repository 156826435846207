import { SingleOppositionTypes } from './types';

const INITIAL_STATE = {
	singleOpposition: {
		id: 0,
		name: '',
		isActive: true,
		code: '',
		countryId: '',
		teamTypeId: '',
	},
	isSingleFetching: false,
	errorMessage: '',
};

const singleOppositionReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* ================================================================ */
		/*  Process Start                                                   */
		/* ================================================================ */
		case SingleOppositionTypes.FETCH_SINGLE_OPPOSITION_START:
			return {
				...state,
				isSingleFetching: true,
			};
		case SingleOppositionTypes.SET_SINGLE_OPPOSITION_NAME:
		case SingleOppositionTypes.SET_SINGLE_OPPOSITION_CODE:
		case SingleOppositionTypes.SET_SINGLE_OPPOSITION_COUNTRY_ID:
		case SingleOppositionTypes.SET_SINGLE_OPPOSITION_TEAM_TYPE_ID:
		case SingleOppositionTypes.SET_SINGLE_OPPOSITION_IS_ACTIVE:
			return {
				...state,
				singleOpposition: {
					...state.singleOpposition,
					[action.inputName]: action.payload,
				},
			};
		case SingleOppositionTypes.CLEAR_SINGLE_OPPOSITION:
			return {
				...state,
				singleOpposition: {
					id: 0,
					name: '',
					isActive: true,
					code: '',
					countryId: '',
					teamTypeId: '',
				},
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Success                                                 */
		/* ================================================================ */
		case SingleOppositionTypes.FETCH_SINGLE_OPPOSITION_SUCCESS:
			return {
				...state,
				singleOpposition: action.payload,
				isSingleFetching: false,
				errorMessage: '',
			};
		case SingleOppositionTypes.CREATE_SINGLE_OPPOSITION_SUCCESS:
		case SingleOppositionTypes.UPDATE_SINGLE_OPPOSITION_SUCCESS:
			return {
				...state,
				singleOpposition: {
					id: 0,
					name: '',
					isActive: true,
					code: '',
					countryId: '',
					teamTypeId: '',
				},
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Failure                                                 */
		/* ================================================================ */
		case SingleOppositionTypes.FETCH_SINGLE_OPPOSITION_FAILURE:
		case SingleOppositionTypes.CREATE_SINGLE_OPPOSITION_FAILURE:
		case SingleOppositionTypes.UPDATE_SINGLE_OPPOSITION_FAILURE:
			return {
				...state,
				errorMessage: action.payload,
				isSingleFetching: false,
			};
		default:
			return state;
	}
};

export default singleOppositionReducer;
