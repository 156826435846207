import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';
import { BASEAPI } from '../../../../routes/routes';
import { getCurrentUserToken } from '../../../../firebase/firebase.utils';

// Types
import { MyTeamsTypes } from './types';

// Actions
import { fetchMyTeamsFailure, fetchMyTeamsSuccess } from './actions';

// Selectors

/* ================================================================ */
/*  Actions                                                         */
/* ================================================================ */

export function* fetchMyTeamsStart() {
	try {
		const token = yield call(getCurrentUserToken);

		const { data: myTeams } = yield call(axios.get, `${BASEAPI}/teams`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		yield put(fetchMyTeamsSuccess(myTeams));
	} catch (error) {
		console.error(error);
		yield put(fetchMyTeamsFailure(error));
	}
}

/* ================================================================ */
/*  Listeners                                                       */
/* ================================================================ */

export function* onFetchMyTeamsStart() {
	yield takeLatest(MyTeamsTypes.FETCH_MY_TEAMS_START, fetchMyTeamsStart);
}
/* ================================================================ */
/*  Root Saga                                                       */
/* ================================================================ */

export default function* myTeamsSagas() {
	yield all([call(onFetchMyTeamsStart)]);
}
