import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// Redux: Selectors
import {
	selectPlayers,
	selectPlayersIsFetching,
	selectPlayersIsEdit,
	selectFilterSearch,
	selectFilteredPlayers,
	selectPlayersFilterTeamTypeId,
} from '../../pages/players/redux/list-screen/selectors';
import {
	selectSinglePlayer,
	selectPlayerSingleIsFetching,
	selectSinglePlayerTeams,
} from '../../pages/players/redux/edit-screen/selectors';

// Redux: Actions
import {
	fetchPlayersStart,
	setPlayersIsEdit,
	setPlayersSearchFilter,
	setPlayersFilterTeamTypeId,
	clearAllPlayersFilter,
	clearPlayersSearchFilter,
} from '../../pages/players/redux/list-screen/actions';
import {
	fetchSinglePlayerStart,
	newSinglePlayerStart,
	createSinglePlayerStart,
	updateSinglePlayerStart,
	setSinglePlayerIsTeamsStart,
	setSinglePlayerFirstName,
	setSinglePlayerLastName,
	setSinglePlayerIsActive,
	setSinglePlayerDateOfBirth,
	setSinglePlayerTagName,
	setSinglePlayerAlternateTagName,
	setSinglePlayerMonitorName,
	setSinglePlayerPositionTypeId,
	clearSinglePlayer,
} from '../../pages/players/redux/edit-screen/actions';

export const WithReduxPlayers = WrappedComponent => {
	// Add Redux actions and state to the WrappedComponent
	const WithData = props => {
		return <WrappedComponent {...props} />;
	};

	// Redux: Selectors
	const mapStateToProps = createStructuredSelector({
		players: selectPlayers,
		singlePlayer: selectSinglePlayer,
		filteredPlayers: selectFilteredPlayers,
		playersFilterSearch: selectFilterSearch,
		playersFilterTeamTypeId: selectPlayersFilterTeamTypeId,
		singlePlayerTeams: selectSinglePlayerTeams,
		playersIsFetching: selectPlayersIsFetching,
		playerSingleIsFetching: selectPlayerSingleIsFetching,
		playersIsEdit: selectPlayersIsEdit,
	});

	// Redux: Actions
	const mapDispatchToProps = dispatch => ({
		fetchPlayersStart: () => dispatch(fetchPlayersStart()),
		fetchSinglePlayerStart: id => dispatch(fetchSinglePlayerStart(id)),
		newSinglePlayerStart: () => dispatch(newSinglePlayerStart()),
		createSinglePlayerStart: () => dispatch(createSinglePlayerStart()),
		updateSinglePlayerStart: () => dispatch(updateSinglePlayerStart()),
		setPlayersIsEdit: bool => dispatch(setPlayersIsEdit(bool)),
		setPlayersSearchFilter: search =>
			dispatch(setPlayersSearchFilter(search)),
		setPlayersFilterTeamTypeId: id =>
			dispatch(setPlayersFilterTeamTypeId(id)),
		setSinglePlayerIsTeamsStart: (bool, index) =>
			dispatch(setSinglePlayerIsTeamsStart(bool, index)),
		setSinglePlayerFirstName: firstName =>
			dispatch(setSinglePlayerFirstName(firstName)),
		setSinglePlayerLastName: lastName =>
			dispatch(setSinglePlayerLastName(lastName)),
		setSinglePlayerIsActive: bool =>
			dispatch(setSinglePlayerIsActive(bool)),
		setSinglePlayerDateOfBirth: dateOfBirth =>
			dispatch(setSinglePlayerDateOfBirth(dateOfBirth)),
		setSinglePlayerTagName: tagName =>
			dispatch(setSinglePlayerTagName(tagName)),
		setSinglePlayerAlternateTagName: alternateTagName =>
			dispatch(setSinglePlayerAlternateTagName(alternateTagName)),
		setSinglePlayerMonitorName: monitorName =>
			dispatch(setSinglePlayerMonitorName(monitorName)),
		setSinglePlayerPositionTypeId: positionTypeId =>
			dispatch(setSinglePlayerPositionTypeId(positionTypeId)),
		clearSinglePlayer: () => dispatch(clearSinglePlayer()),
		clearPlayersSearchFilter: () => dispatch(clearPlayersSearchFilter()),
		clearAllPlayersFilter: () => dispatch(clearAllPlayersFilter()),
	});

	return connect(mapStateToProps, mapDispatchToProps)(WithData);
};
