export const CompetitionTypes = {
	FETCH_COMPETITION_START: 'FETCH_COMPETITION_START',
	FETCH_COMPETITION_SUCCESS: 'FETCH_COMPETITION_SUCCESS',
	FETCH_COMPETITION_FAILURE: 'FETCH_COMPETITION_FAILURE',

	SET_COMPETITION_IS_FETCHING: 'SET_COMPETITION_IS_FETCHING',
	SET_COMPETITION_IS_EDIT: 'SET_COMPETITION_IS_EDIT',
	SET_COMPETITION_FILTER_TEAM_TYPE_ID: 'SET_COMPETITION_FILTER_TEAM_TYPE_ID',
	SET_FILTERED_COMPETITIONS: 'SET_FILTERED_COMPETITIONS',

	CLEAR_COMPETITION_FILTER_TEAM_TYPE_ID:
		'CLEAR_COMPETITION_FILTER_TEAM_TYPE_ID',
	CLEAR_COMPETITION_REDUCER: 'CLEAR_COMPETITION_REDUCER',
};
