export const SingleCompetitionTypes = {
	FETCH_SINGLE_COMPETITION_START: 'FETCH_SINGLE_COMPETITION_START',
	FETCH_SINGLE_COMPETITION_SUCCESS: 'FETCH_SINGLE_COMPETITION_SUCCESS',
	FETCH_SINGLE_COMPETITION_FAILURE: 'FETCH_SINGLE_COMPETITION_FAILURE',

	CREATE_SINGLE_COMPETITION_START: 'CREATE_SINGLE_COMPETITION_START',
	CREATE_SINGLE_COMPETITION_SUCCESS: 'CREATE_SINGLE_COMPETITION_SUCCESS',
	CREATE_SINGLE_COMPETITION_FAILURE: 'CREATE_SINGLE_COMPETITION_FAILURE',

	UPDATE_SINGLE_COMPETITION_START: 'UPDATE_SINGLE_COMPETITION_START',
	UPDATE_SINGLE_COMPETITION_SUCCESS: 'UPDATE_SINGLE_COMPETITION_SUCCESS',
	UPDATE_SINGLE_COMPETITION_FAILURE: 'UPDATE_SINGLE_COMPETITION_FAILURE',

	SET_COMPETITION_NAME: 'SET_COMPETITION_NAME',
	SET_COMPETITION_COUNTRY_ID: 'SET_COMPETITION_COUNTRY_ID',
	SET_COMPETITION_TEAM_TYPE_ID: 'SET_COMPETITION_TEAM_TYPE_ID',

	CLEAR_SINGLE_COMPETITION: 'CLEAR_SINGLE_COMPETITION',
};
