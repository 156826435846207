import { compose } from 'redux';

import {
	EditBlock,
	PopupOverlay,
} from '../../../components/atom/pop-up/PopUp.styles';
import {
	ButtonBlock2,
	InputBlock,
} from '../../../components/atom/inputs/Inputs.styles';
import {
	InverseButton,
	Button,
} from '../../../components/atom/buttons/Button.styles';
import { Seperator } from '../../../components/atom/seperator/Seperator.styles';
import { InputField } from '../../../components/molecule/input-field/InputField.component';
import { DropdownField } from '../../../components/molecule/dropdown-field/DropdownField.component';
import { NumberField } from '../../../components/molecule/number-field/NumberField.component';
import { LoadingList } from '../../../components/atom/loader/Loader.component';
import { Switch } from '../../../components/molecule/switch/Switch.component';

import { WithReduxMyTeams } from '../../../components/hoc/WithReduxMyTeams.hoc';
import { WithReduxCountries } from '../../../components/hoc/WithReduxCountries.hoc';
import { WithReduxTeamTypes } from '../../../components/hoc/WithReduxTeamTypes.hoc';

const EditPopup = ({
	singleMyTeam,
	myTeamIsSingleFetching,
	countries,
	teamTypes,
	setMyTeamsIsEdit,
	clearSingleMyTeam,
	createSingleMyTeamStart,
	updateSingleMyTeamStart,
	setSingleMyTeamName,
	setSingleMyTeamIsActive,
	setSingleMyTeamStorageFolder,
	setSingleMyTeamDefaultMatchMinutes,
	setSingleMyTeamCountryId,
	setSingleMyTeamTypeId,
}) => {
	const isFormFilled =
		singleMyTeam.name !== '' &&
		singleMyTeam.storageFolder !== '' &&
		singleMyTeam.defaultMatchMinutes &&
		singleMyTeam.countryId !== '' &&
		singleMyTeam.teamTypeId !== '';

	return (
		<PopupOverlay>
			<EditBlock>
				{!myTeamIsSingleFetching ? (
					<InputBlock>
						<InputField
							required
							label='Name'
							value={singleMyTeam.name}
							action={setSingleMyTeamName}
						/>
						<DropdownField
							required
							label='Countries'
							nullLabel='- Select -'
							value={singleMyTeam.countryId}
							data={countries}
							dataProp='name'
							action={setSingleMyTeamCountryId}
						/>
						<DropdownField
							required
							label='Team Type'
							nullLabel='- Select -'
							value={singleMyTeam.teamTypeId}
							data={teamTypes}
							dataProp='label'
							action={setSingleMyTeamTypeId}
						/>
						<Seperator className='full-width' />
						<InputField
							required
							label='Storage Folder'
							value={singleMyTeam.storageFolder}
							action={setSingleMyTeamStorageFolder}
						/>
						<NumberField
							required
							label='Default Match Minutes'
							value={singleMyTeam.defaultMatchMinutes}
							action={setSingleMyTeamDefaultMatchMinutes}
						/>
						<Seperator className='full-width' />
						<Switch
							id='my-teams-is-active'
							label='Is Active'
							value={singleMyTeam.isActive}
							action={setSingleMyTeamIsActive}
						/>
					</InputBlock>
				) : (
					<LoadingList />
				)}
				<ButtonBlock2>
					<InverseButton
						onClick={() => {
							setMyTeamsIsEdit(false);
							clearSingleMyTeam();
						}}
					>
						Cancel
					</InverseButton>
					<Button
						className='save-button'
						disabled={!isFormFilled}
						onClick={() =>
							singleMyTeam.id
								? updateSingleMyTeamStart()
								: createSingleMyTeamStart()
						}
					>
						{singleMyTeam.id ? 'Update' : 'Create'}
					</Button>
				</ButtonBlock2>
			</EditBlock>
		</PopupOverlay>
	);
};

export default compose(
	WithReduxCountries,
	WithReduxMyTeams,
	WithReduxTeamTypes
)(EditPopup);
