import { takeLatest, put, all, call, select } from 'redux-saga/effects';

// Utils
import { validateEmail } from '../../../utils/utils';

// Firebase utils
import { auth } from '../../../firebase/firebase.utils';

// Types
import { UserTypes } from './types';

// Actions
import {
	emailSignInFailure,
	clearLoginCredentials,
	clearLoginErrorMessage,
	emailSignInSuccess,
	sendPasswordResetEmailSuccess,
	sendPasswordResetEmailFailure,
} from './actions';
import { clearCompetitionReducer } from '../../competition/redux/list-screen/actions';
import { clearMatchesReducer } from '../../matches/redux/actions';
import { clearMyTeamsReducer } from '../../my-teams/redux/list-screen/actions';
import { clearOppositionsReducer } from '../../oppositions/redux/list-screen/actions';
import { clearPlayersReducer } from '../../players/redux/list-screen/actions';
import { clearTeamTypesReducer } from '../../../redux/team-types/actions';
import { clearPositionTypesReducer } from '../../../redux/position-types/actions';
import { clearCountriesReducer } from '../../../redux/countries/actions';

// Selectors
import { selectEmail, selectPassword } from './selectors';

/* ================================================================ */
/*  Actions                                                         */
/* ================================================================ */

export function* emailSignInStart() {
	try {
		yield put(clearLoginErrorMessage());

		const email = yield select(selectEmail);
		const password = yield select(selectPassword);

		const { user } = yield auth.signInWithEmailAndPassword(email, password);
		const initial = yield user.email.charAt(0).toUpperCase();

		yield put(emailSignInSuccess({ uid: user.uid, initial }));
		yield put(clearLoginCredentials());
	} catch (error) {
		console.error(error);
		yield put(emailSignInFailure(error));
	}
}

export function* sendPasswordResetEmailStart() {
	try {
		yield put(clearLoginErrorMessage());

		const email = yield select(selectEmail);
		const isEmailValid = yield call(validateEmail, email);

		if (!isEmailValid) {
			console.error('ERROR: Invalid email format');
			return yield put(
				sendPasswordResetEmailFailure({ code: 'invalid-email' })
			);
		}

		yield auth.sendPasswordResetEmail(email);
		yield put(sendPasswordResetEmailSuccess());
	} catch (error) {
		console.error(error);
		yield put(sendPasswordResetEmailFailure(error));
	}
}

export function* logout() {
	try {
		yield put(clearCompetitionReducer());
		yield put(clearMatchesReducer());
		yield put(clearMyTeamsReducer());
		yield put(clearOppositionsReducer());
		yield put(clearPlayersReducer());
		yield put(clearTeamTypesReducer());
		yield put(clearPositionTypesReducer());
		yield put(clearCountriesReducer());
	} catch (error) {
		console.error(error);
	}
}

/* ================================================================ */
/*  Listeners                                                       */
/* ================================================================ */

export function* onEmailSignInStart() {
	yield takeLatest(UserTypes.EMAIL_SIGN_IN_START, emailSignInStart);
}

export function* onSendPasswordResetEmailStart() {
	yield takeLatest(
		UserTypes.SEND_PASSWORD_RESET_EMAIL_START,
		sendPasswordResetEmailStart
	);
}

export function* onLogout() {
	yield takeLatest(UserTypes.LOG_OUT, logout);
}

/* ================================================================ */
/*  Root Saga                                                       */
/* ================================================================ */

export default function* userSagas() {
	yield all([
		call(onEmailSignInStart),
		call(onSendPasswordResetEmailStart),
		call(onLogout),
	]);
}
