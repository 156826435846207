import { compose } from 'redux';

import { PopupOverlay } from '../../../components/atom/pop-up/PopUp.styles';
import {
	ButtonBlock2,
	InputBlock,
} from '../../../components/atom/inputs/Inputs.styles';
import { LoadingList } from '../../../components/atom/loader/Loader.component';
import { Box } from '../../../components/atom/containers/Box.styles';
import {
	Button,
	InverseButton,
} from '../../../components/atom/buttons/Button.styles';
import { InputField } from '../../../components/molecule/input-field/InputField.component';
import { DropdownField } from '../../../components/molecule/dropdown-field/DropdownField.component';

import { WithReduxCountries } from '../../../components/hoc/WithReduxCountries.hoc';
import { WithReduxCompetition } from '../../../components/hoc/WithReduxCompetition.hoc';
import { WithReduxTeamTypes } from '../../../components/hoc/WithReduxTeamTypes.hoc';

const EditPopup = ({
	setCompetitionIsEdit,
	competitionIsSingleFetching,
	singleCompetition,
	countries,
	teamTypes,
	setCompetitionName,
	setCompetitionCountryId,
	setCompetitionTeamTypeId,
	createSingleCompetitionStart,
	updateSingleCompetitionStart,
	clearSingleCompetition,
}) => {
	const inputSize = '260px';
	const isFormFilled =
		singleCompetition.name !== '' &&
		singleCompetition.countryId !== '' &&
		singleCompetition.teamTypeId !== '';

	return (
		<PopupOverlay>
			<Box padding='60px'>
				{!competitionIsSingleFetching ? (
					<InputBlock>
						<InputField
							required
							size={inputSize}
							label='Name'
							value={singleCompetition.name}
							action={setCompetitionName}
						/>
						<DropdownField
							required
							size={inputSize}
							label='Countries'
							nullLabel='- Select -'
							value={singleCompetition.countryId}
							data={countries}
							dataProp='name'
							action={setCompetitionCountryId}
						/>
						<DropdownField
							required
							size={inputSize}
							label='Team Types'
							nullLabel='- Select -'
							value={singleCompetition.teamTypeId}
							data={teamTypes}
							dataProp='label'
							action={setCompetitionTeamTypeId}
						/>
					</InputBlock>
				) : (
					<LoadingList />
				)}
				<ButtonBlock2>
					<InverseButton
						onClick={() => {
							setCompetitionIsEdit(false);
							clearSingleCompetition();
						}}
					>
						Cancel
					</InverseButton>
					<Button
						className='save-button'
						disabled={!isFormFilled}
						onClick={() =>
							singleCompetition.id
								? updateSingleCompetitionStart()
								: createSingleCompetitionStart()
						}
					>
						{singleCompetition.id ? 'Update' : 'Create'}
					</Button>
				</ButtonBlock2>
			</Box>
		</PopupOverlay>
	);
};

export default compose(
	WithReduxCompetition,
	WithReduxCountries,
	WithReduxTeamTypes
)(EditPopup);
