import { createSelector } from 'reselect';

const selectCompetitionStore = state => state.competition;

// Selects competition.competition from the state
export const selectCompetition = createSelector(
	[selectCompetitionStore],
	competition => competition.competition
);

// Selects competition.filteredCompetitions from the state
export const selectFilteredCompetitions = createSelector(
	[selectCompetitionStore],
	competition => competition.filteredCompetitions
);

// Selects competition.isFetching from the state
export const selectCompetitionIsFetching = createSelector(
	[selectCompetitionStore],
	competition => competition.isFetching
);

// Selects competition.isEdit from the state
export const selectCompetitionIsEdit = createSelector(
	[selectCompetitionStore],
	competition => competition.isEdit
);

// Selects competition.filterTeamTypeId from the state
export const selectCompetitionFilterTeamTypeId = createSelector(
	[selectCompetitionStore],
	competition => competition.filterTeamTypeId
);
