import { SingleMyTeamTypes } from './types';

/* ================================================================ */
/*  Process Start  	                                                */
/* ================================================================ */

export const fetchSingleMyTeamStart = id => ({
	type: SingleMyTeamTypes.FETCH_SINGLE_MY_TEAM_START,
	payload: id,
});

export const createSingleMyTeamStart = () => ({
	type: SingleMyTeamTypes.CREATE_SINGLE_MY_TEAM_START,
});

export const updateSingleMyTeamStart = () => ({
	type: SingleMyTeamTypes.UPDATE_SINGLE_MY_TEAM_START,
});

export const clearSingleMyTeam = () => ({
	type: SingleMyTeamTypes.CLEAR_SINGLE_MY_TEAM,
});

export const setSingleMyTeamName = name => ({
	type: SingleMyTeamTypes.SET_SINGLE_MY_TEAM_NAME,
	payload: name,
	inputName: 'name',
});

export const setSingleMyTeamIsActive = bool => ({
	type: SingleMyTeamTypes.SET_SINGLE_MY_TEAM_IS_ACTIVE,
	payload: bool,
	inputName: 'isActive',
});

export const setSingleMyTeamStorageFolder = name => ({
	type: SingleMyTeamTypes.SET_SINGLE_MY_TEAM_STORAGE_FOLDER,
	payload: name,
	inputName: 'storageFolder',
});

export const setSingleMyTeamDefaultMatchMinutes = minutes => ({
	type: SingleMyTeamTypes.SET_SINGLE_MY_TEAM_DEFAULT_MATCH_MINUTES,
	payload: minutes,
	inputName: 'defaultMatchMinutes',
});

export const setSingleMyTeamCountryId = id => ({
	type: SingleMyTeamTypes.SET_SINGLE_MY_TEAM_COUNTRY_ID,
	payload: id,
	inputName: 'countryId',
});

export const setSingleMyTeamTypeId = teamTypeId => ({
	type: SingleMyTeamTypes.SET_SINGLE_MY_TEAM_TYPE_ID,
	payload: teamTypeId,
	inputName: 'teamTypeId',
});

/* ================================================================ */
/*  Process Success	                                                */
/* ================================================================ */

export const fetchSingleMyTeamSuccess = myTeam => ({
	type: SingleMyTeamTypes.FETCH_SINGLE_MY_TEAM_SUCCESS,
	payload: myTeam,
});

export const newSingleMyTeamSuccess = myTeam => ({
	type: SingleMyTeamTypes.NEW_SINGLE_MY_TEAM_SUCCESS,
	payload: myTeam,
});

export const createSingleMyTeamSuccess = myTeam => ({
	type: SingleMyTeamTypes.CREATE_SINGLE_MY_TEAM_SUCCESS,
	payload: myTeam,
});

export const updateSingleMyTeamSuccess = myTeam => ({
	type: SingleMyTeamTypes.UPDATE_SINGLE_MY_TEAM_SUCCESS,
	payload: myTeam,
});

/* ================================================================ */
/*  Process Failure	       	                                        */
/* ================================================================ */

export const fetchSingleMyTeamFailure = errorMessage => ({
	type: SingleMyTeamTypes.FETCH_SINGLE_MY_TEAM_FAILURE,
	payload: errorMessage,
});

export const newSingleMyTeamFailure = errorMessage => ({
	type: SingleMyTeamTypes.NEW_SINGLE_MY_TEAM_FAILURE,
	payload: errorMessage,
});

export const createSingleMyTeamFailure = errorMessage => ({
	type: SingleMyTeamTypes.CREATE_SINGLE_MY_TEAM_FAILURE,
	payload: errorMessage,
});

export const updateSingleMyTeamFailure = errorMessage => ({
	type: SingleMyTeamTypes.UPDATE_SINGLE_MY_TEAM_FAILURE,
	payload: errorMessage,
});
