import { MatchesTypes } from './types';

/* ================================================================ */
/*  Process Start  	                                                */
/* ================================================================ */

export const fetchMatchesStart = () => ({
	type: MatchesTypes.FETCH_MATCHES_START,
});

export const setFilteredMatches = matches => ({
	type: MatchesTypes.SET_FILTERED_MATCHES,
	payload: matches,
});

export const setMatchesFilterTeamId = id => ({
	type: MatchesTypes.SET_MATCHES_FILTER_TEAM_ID,
	payload: id,
});

export const setMatchesIsEdit = bool => ({
	type: MatchesTypes.SET_MATCHES_IS_EDIT,
	payload: bool,
});

export const clearMatchesFilterTeamId = () => ({
	type: MatchesTypes.CLEAR_MATCHES_FILTER_TEAM_ID,
});

export const clearMatchesReducer = () => ({
	type: MatchesTypes.CLEAR_MATCHES_REDUCER,
});

/* ================================================================ */
/*  Process Success	                                                */
/* ================================================================ */

export const fetchMatchesSuccess = matches => ({
	type: MatchesTypes.FETCH_MATCHES_SUCCESS,
	payload: matches,
});

/* ================================================================ */
/*  Process Failure	       	                                        */
/* ================================================================ */

export const fetchMatchesFailure = errorMessage => ({
	type: MatchesTypes.FETCH_MATCHES_FAILURE,
	payload: errorMessage,
});
