import styled, { css } from 'styled-components';
import { shadowStyles } from '../../../styles/global.styles';

export const Box = styled.div`
	padding: 24px;
	border-radius: 8px;
	background-color: #fff;

	${shadowStyles.shadow1}

	${({ padding }) =>
		padding &&
		css`
			padding: ${padding};
		`}
`;
