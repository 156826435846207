import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// Redux: Selectors
import {
	selectCompetition,
	selectFilteredCompetitions,
	selectCompetitionIsFetching,
	selectCompetitionIsEdit,
	selectCompetitionFilterTeamTypeId,
} from '../../pages/competition/redux/list-screen/selectors';
import {
	selectSingleCompetitionIsSingleFetching,
	selectSingleCompetition,
} from '../../pages/competition/redux/edit-screen/selectors';

// Redux: Actions
import {
	fetchCompetitionStart,
	setCompetitionIsEdit,
	setCompetitionFilterTeamTypeId,
	clearCompetitionFilterTeamTypeId,
} from '../../pages/competition/redux/list-screen/actions';
import {
	fetchSingleCompetitionStart,
	createSingleCompetitionStart,
	updateSingleCompetitionStart,
	setCompetitionName,
	setCompetitionCountryId,
	setCompetitionTeamTypeId,
	clearSingleCompetition,
} from '../../pages/competition/redux/edit-screen/actions';

export const WithReduxCompetition = WrappedComponent => {
	// Add Redux actions and state to the WrappedComponent
	const WithData = props => {
		return <WrappedComponent {...props} />;
	};

	// Redux: Selectors
	const mapStateToProps = createStructuredSelector({
		competition: selectCompetition,
		filteredCompetitions: selectFilteredCompetitions,
		singleCompetition: selectSingleCompetition,
		competitionIsFetching: selectCompetitionIsFetching,
		competitionIsSingleFetching: selectSingleCompetitionIsSingleFetching,
		competitionIsEdit: selectCompetitionIsEdit,
		competitionFilterTeamTypeId: selectCompetitionFilterTeamTypeId,
	});

	// Redux: Actions
	const mapDispatchToProps = dispatch => ({
		fetchCompetitionStart: () => dispatch(fetchCompetitionStart()),
		fetchSingleCompetitionStart: id =>
			dispatch(fetchSingleCompetitionStart(id)),
		setCompetitionIsEdit: bool => dispatch(setCompetitionIsEdit(bool)),
		setCompetitionName: name => dispatch(setCompetitionName(name)),
		setCompetitionCountryId: id => dispatch(setCompetitionCountryId(id)),
		setCompetitionTeamTypeId: id => dispatch(setCompetitionTeamTypeId(id)),
		setCompetitionFilterTeamTypeId: id =>
			dispatch(setCompetitionFilterTeamTypeId(id)),
		createSingleCompetitionStart: () =>
			dispatch(createSingleCompetitionStart()),
		updateSingleCompetitionStart: () =>
			dispatch(updateSingleCompetitionStart()),
		clearCompetitionFilterTeamTypeId: () =>
			dispatch(clearCompetitionFilterTeamTypeId()),
		clearSingleCompetition: () => dispatch(clearSingleCompetition()),
	});

	return connect(mapStateToProps, mapDispatchToProps)(WithData);
};
