export const SingleOppositionTypes = {
	FETCH_SINGLE_OPPOSITION_START: 'FETCH_SINGLE_OPPOSITION_START',
	FETCH_SINGLE_OPPOSITION_SUCCESS: 'FETCH_SINGLE_OPPOSITION_SUCCESS',
	FETCH_SINGLE_OPPOSITION_FAILURE: 'FETCH_SINGLE_OPPOSITION_FAILURE',

	CREATE_SINGLE_OPPOSITION_START: 'CREATE_SINGLE_OPPOSITION_START',
	CREATE_SINGLE_OPPOSITION_SUCCESS: 'CREATE_SINGLE_OPPOSITION_SUCCESS',
	CREATE_SINGLE_OPPOSITION_FAILURE: 'CREATE_SINGLE_OPPOSITION_FAILURE',

	UPDATE_SINGLE_OPPOSITION_START: 'UPDATE_SINGLE_OPPOSITION_START',
	UPDATE_SINGLE_OPPOSITION_SUCCESS: 'UPDATE_SINGLE_OPPOSITION_SUCCESS',
	UPDATE_SINGLE_OPPOSITION_FAILURE: 'UPDATE_SINGLE_OPPOSITION_FAILURE',

	SET_SINGLE_OPPOSITION_NAME: 'SET_SINGLE_OPPOSITION_NAME',
	SET_SINGLE_OPPOSITION_CODE: 'SET_SINGLE_OPPOSITION_CODE',
	SET_SINGLE_OPPOSITION_COUNTRY_ID: 'SET_SINGLE_OPPOSITION_COUNTRY_ID',
	SET_SINGLE_OPPOSITION_TEAM_TYPE_ID: 'SET_SINGLE_OPPOSITION_TEAM_TYPE_ID',
	SET_SINGLE_OPPOSITION_IS_ACTIVE: 'SET_SINGLE_OPPOSITION_IS_ACTIVE',

	CLEAR_SINGLE_OPPOSITION: 'CLEAR_SINGLE_OPPOSITION',
};
