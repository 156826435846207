import { MyTeamsTypes } from './types';

const INITIAL_STATE = {
	myTeams: [],
	isEdit: false,
	isFetching: false,
	errorMessage: '',
};

const myTeamsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* ================================================================ */
		/*  Process Start                                                   */
		/* ================================================================ */
		case MyTeamsTypes.FETCH_MY_TEAMS_START:
		case MyTeamsTypes.SET_MY_TEAMS_IS_FETCHING:
			return {
				...state,
				isFetching: true,
			};
		case MyTeamsTypes.SET_MY_TEAMS_IS_EDIT:
			return {
				...state,
				isEdit: action.payload,
			};
		case MyTeamsTypes.CLEAR_MY_TEAM_REDUCER:
			return {
				...state,
				myTeams: [],
				isEdit: false,
				isFetching: false,
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Success                                                 */
		/* ================================================================ */
		case MyTeamsTypes.FETCH_MY_TEAMS_SUCCESS:
			return {
				...state,
				myTeams: action.payload,
				isFetching: false,
			};
		/* ================================================================ */
		/*  Process Failure                                                 */
		/* ================================================================ */
		case MyTeamsTypes.FETCH_MY_TEAMS_FAILURE:
			return {
				...state,
				errorMessage: action.payload,
				isFetching: false,
			};
		default:
			return state;
	}
};

export default myTeamsReducer;
