import { css } from 'styled-components';

// Global Color Styles
export const colorStyles = {
	primary: '#0054a6',
	primaryLight: '#5280d8',
	primaryDark: '#002c76',
	primaryBgTransparancy: value => css`
		background-color: rgba(0, 84, 166, ${value});
	`,

	// State Colors
	success: '#4caf50',
	warning: '#F5C22B',
	error: '#F44236',
	info: '#04A9F5',

	// Greyscale Colors
	greyScale1: '#202224',
	greyScale2: '#393C40',
	greyScale3: '#474B4F',
	greyScale4: '#6F7379',
	greyScale5: '#AAAEB3',
	greyScale6: '#C6CACF',
	greyScale7: '#EBEBEB',
	greyScale8: '#ECEDEF',
	greyScale9: '#FFFFFF',
};

// Gloabal Shadow Styles
export const shadowStyles = {
	shadow1: css`
		box-shadow: 0 10px 30px 0 rgb(0 0 0 / 5%);
	`,
};

// Gloabal Input Styles
export const inputStyles = {
	input: css`
		background-color: #f5f8fa;
		padding: 8px 12px;
		border-radius: 8px;
		color: ${colorStyles.greyScale3};
		font-size: 16px;
		transition: all 0.2s ease;

		:focus {
			background-color: #eef3f7;
		}
	`,
};
