export const PlayersActionTypes = {
	FETCH_PLAYERS_START: 'FETCH_PLAYERS_START',
	FETCH_PLAYERS_SUCCESS: 'FETCH_PLAYERS_SUCCESS',
	FETCH_PLAYERS_FAILURE: 'FETCH_PLAYERS_FAILURE',

	SET_PLAYERS_IS_FETCHING: 'SET_PLAYERS_IS_FETCHING',
	SET_PLAYERS_IS_EDIT: 'SET_PLAYERS_IS_EDIT',
	SET_PLAYERS_SEARCH_FILTER: 'SET_PLAYERS_SEARCH_FILTER',
	SET_PLAYERS_FILTER_TEAM_TYPE_ID: 'SET_PLAYERS_FILTER_TEAM_TYPE_ID',
	SET_FILTERED_PLAYERS: 'SET_FILTERED_PLAYERS',

	CLEAR_PLAYERS_REDUCER: 'CLEAR_PLAYERS_REDUCER',
	CLEAR_PLAYERS_SEARCH_FILTER: 'CLEAR_PLAYERS_SEARCH_FILTER',
	CLEAR_ALL_PLAYERS_FILTERS: 'CLEAR_ALL_PLAYERS_FILTERS',
};
