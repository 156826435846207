import styled, { css } from 'styled-components';
import { colorStyles } from '../../../styles/global.styles';

export const Button = styled.a`
	display: flex;
	padding: 8px 20px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	background-color: ${colorStyles.primary};
	color: #fff;
	text-transform: capitalize;
	cursor: pointer;
	transition: background-color 0.2s ease;

	:hover {
		background-color: ${colorStyles.primaryLight};
	}

	:active {
		background-color: ${colorStyles.primaryDark};
	}

	${props =>
		props.disabled &&
		css`
			background-color: ${colorStyles.greyScale7};
			color: ${colorStyles.greyScale4};
			cursor: not-allowed;

			:hover {
				background-color: ${colorStyles.greyScale7};
			}

			:active {
				background-color: ${colorStyles.greyScale7};
			}
		`}
`;

export const InverseButton = styled.a`
	display: flex;
	padding: 8px 20px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	${colorStyles.primaryBgTransparancy(0.1)}
	color: ${colorStyles.primary};
	text-transform: capitalize;
	cursor: pointer;
	transition: background-color 0.2s ease;

	:hover {
		${colorStyles.primaryBgTransparancy(0.15)}
	}

	:active {
		${colorStyles.primaryBgTransparancy(0.1)}
	}
`;

export const IconButton = styled.a`
	display: flex;
	padding: 8px 20px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	${colorStyles.primaryBgTransparancy(0.1)}
	color: ${colorStyles.primary};
	font-size: 16px;
	text-transform: capitalize;
	cursor: pointer;
	transition: background-color 0.2s ease;

	svg {
		width: 24px;
		fill: ${colorStyles.primary};
		margin-right: 12px;
	}

	:hover {
		${colorStyles.primaryBgTransparancy(0.15)}
	}

	:active {
		${colorStyles.primaryBgTransparancy(0.1)}
	}

	${({ round, color }) =>
		round &&
		css`
			padding: 4px;
			border-radius: 50%;
			background-color: unset;

			:hover {
				background-color: unset;
			}

			svg {
				fill: ${color};
				margin-right: 0;
			}
		`}

	${props =>
		props.disabled &&
		css`
			background-color: ${colorStyles.greyScale7};
			color: ${colorStyles.greyScale4};
			cursor: not-allowed;

			:hover {
				background-color: ${colorStyles.greyScale7};
			}

			:active {
				background-color: ${colorStyles.greyScale7};
			}
		`}
`;
