import { useEffect } from 'react';
import { Fragment } from 'react';
import { compose } from 'redux';

import ListFilter from './components/ListFilter.component';
import List from './components/List.component';
import { Box } from '../../components/atom/containers/Box.styles';
import { LoadingList } from '../../components/atom/loader/Loader.component';

import { WithReduxMatches } from '../../components/hoc/WithReduxMatches.hoc';

const MatchesList = ({ fetchMatchesStart, matchesIsFetching }) => {
	useEffect(() => {
		fetchMatchesStart();
	}, [fetchMatchesStart]);

	return (
		<Fragment>
			{!matchesIsFetching ? (
				<Box padding='48px'>
					<ListFilter />
					<List />
				</Box>
			) : (
				<LoadingList />
			)}
		</Fragment>
	);
};

export default compose(WithReduxMatches)(MatchesList);
