export const MyTeamsTypes = {
	FETCH_MY_TEAMS_START: 'FETCH_MY_TEAMS_START',
	FETCH_MY_TEAMS_SUCCESS: 'FETCH_MY_TEAMS_SUCCESS',
	FETCH_MY_TEAMS_FAILURE: 'FETCH_MY_TEAMS_FAILURE',

	SET_MY_TEAMS_IS_FETCHING: 'SET_MY_TEAMS_IS_FETCHING',
	SET_MY_TEAMS_IS_EDIT: 'SET_MY_TEAMS_IS_EDIT',

	CLEAR_MY_TEAM_REDUCER: 'CLEAR_MY_TEAM_REDUCER',
};
