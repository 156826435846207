import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import axios from 'axios';

import { BASEAPI } from '../../../../routes/routes';
import { getCurrentUserToken } from '../../../../firebase/firebase.utils';

// Types
import { SingleCompetitionTypes } from './types';

// Actions
import {
	fetchSingleCompetitionFailure,
	fetchSingleCompetitionSuccess,
	createSingleCompetitionFailure,
	createSingleCompetitionSuccess,
	updateSingleCompetitionSuccess,
	updateSingleCompetitionFailure,
} from './actions';
import {
	fetchCompetitionStart,
	setCompetitionIsEdit,
} from '../list-screen/actions';

// Selectors
import { selectSingleCompetition } from './selectors';

/* ================================================================ */
/*  Actions                                                         */
/* ================================================================ */

export function* fetchSingleCompetitionStart({ payload: id }) {
	try {
		yield put(setCompetitionIsEdit(true));

		const token = yield call(getCurrentUserToken);
		const { data: competition } = yield call(
			axios.get,
			`${BASEAPI}/competitions/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		yield put(fetchSingleCompetitionSuccess(competition));
	} catch (error) {
		console.error(error);
		yield put(fetchSingleCompetitionFailure(error));
		yield put(setCompetitionIsEdit(false));
	}
}

export function* createSingleCompetitionStart() {
	try {
		const token = yield call(getCurrentUserToken);

		const { id, ...singleCompetition } = yield select(
			selectSingleCompetition
		);

		yield call(
			axios.post,
			`${BASEAPI}/competitions`,
			{ ...singleCompetition },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		yield put(fetchCompetitionStart());
		yield put(setCompetitionIsEdit(false));
		yield put(createSingleCompetitionSuccess());
	} catch (error) {
		console.error(error);
		yield put(createSingleCompetitionFailure(error));
	}
}

export function* updateSingleCompetitionStart() {
	try {
		const token = yield call(getCurrentUserToken);

		const { id, ...singleCompetition } = yield select(
			selectSingleCompetition
		);

		yield call(
			axios.patch,
			`${BASEAPI}/competitions/${id}`,
			{ ...singleCompetition },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		yield put(fetchCompetitionStart());
		yield put(setCompetitionIsEdit(false));
		yield put(updateSingleCompetitionSuccess());
	} catch (error) {
		console.error(error);
		yield put(updateSingleCompetitionFailure(error));
	}
}

/* ================================================================ */
/*  Listeners                                                       */
/* ================================================================ */

export function* onFetchSingleCompetitionStart() {
	yield takeLatest(
		SingleCompetitionTypes.FETCH_SINGLE_COMPETITION_START,
		fetchSingleCompetitionStart
	);
}

export function* onCreateSingleCompetitionStart() {
	yield takeLatest(
		SingleCompetitionTypes.CREATE_SINGLE_COMPETITION_START,
		createSingleCompetitionStart
	);
}

export function* onUpdateSingleCompetitionStart() {
	yield takeLatest(
		SingleCompetitionTypes.UPDATE_SINGLE_COMPETITION_START,
		updateSingleCompetitionStart
	);
}

/* ================================================================ */
/*  Root Saga                                                       */
/* ================================================================ */

export default function* singleCompetitionSagas() {
	yield all([
		call(onFetchSingleCompetitionStart),
		call(onCreateSingleCompetitionStart),
		call(onUpdateSingleCompetitionStart),
	]);
}
