export const OppositionsTypes = {
	FETCH_OPPOSITIONS_START: 'FETCH_OPPOSITIONS_START',
	FETCH_OPPOSITIONS_SUCCESS: 'FETCH_OPPOSITIONS_SUCCESS',
	FETCH_OPPOSITIONS_FAILURE: 'FETCH_OPPOSITIONS_FAILURE',

	SET_OPPOSITIONS_IS_FETCHING: 'SET_OPPOSITIONS_IS_FETCHING',
	SET_OPPOSITIONS_IS_EDIT: 'SET_OPPOSITIONS_IS_EDIT',
	SET_OPPOSITIONS_FILTER_TEAM_TYPE_ID: 'SET_OPPOSITIONS_FILTER_TEAM_TYPE_ID',
	SET_FILTERED_OPPOSITIONS: 'SET_FILTERED_OPPOSITIONS',

	CLEAR_OPPOSITIONS_FILTER_TEAM_TYPE_ID:
		'CLEAR_OPPOSITIONS_FILTER_TEAM_TYPE_ID',
	CLEAR_OPPOSITIONS_REDUCER: 'CLEAR_OPPOSITIONS_REDUCER',
};
