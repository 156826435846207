import { Fragment } from 'react';
import { compose } from 'redux';

import {
	Table2,
	TableData2,
	TableHeader2,
} from '../../../components/molecule/table/Table.styles';

import { WithReduxCompetition } from '../../../components/hoc/WithReduxCompetition.hoc';

const List = ({ filteredCompetitions, fetchSingleCompetitionStart }) => {
	return (
		<Table2>
			<TableHeader2>Competition Name</TableHeader2>
			<TableHeader2>Competition Type</TableHeader2>
			{filteredCompetitions.map((competition, index) => (
				<Fragment key={index}>
					<TableData2
						onClick={() => {
							fetchSingleCompetitionStart(competition.id);
						}}
					>
						{competition.name}
					</TableData2>
					<TableData2
						onClick={() => {
							fetchSingleCompetitionStart(competition.id);
						}}
					>
						{competition.teamTypeLabel}
					</TableData2>
				</Fragment>
			))}
		</Table2>
	);
};

export default compose(WithReduxCompetition)(List);
