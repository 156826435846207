import styled from 'styled-components';
import stadiumImage from '../../../../assets/stadium-1.jpg';
import { colorStyles } from '../../../../styles/global.styles';

export const LoginInputBlock = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #fafafa;
	background-image: linear-gradient(
			180deg,
			hsla(0, 0%, 100%, 0.9),
			hsla(0, 0%, 100%, 0.9)
		),
		url(${stadiumImage});
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;

	.box {
		margin-top: 20vh;
		width: 80%;

		.input-grid {
			display: grid;
			grid-auto-columns: 1fr;
			grid-column-gap: 16px;
			grid-row-gap: 16px;
			grid-template-columns: 1fr;
			grid-template-rows: auto;

			.forgot-password-link {
				color: ${colorStyles.primary};
				text-decoration: underline;
				justify-self: start;
				cursor: pointer;
			}

			.reset-success-text {
				font-size: 20px;
				text-align: center;
				line-height: 32px;
				margin-bottom: 32px;
			}

			.reset-buttons {
				margin-top: unset;
			}
		}
	}
`;
