import { compose } from 'redux';

import {
	EditBlock,
	PopupOverlay,
} from '../../../components/atom/pop-up/PopUp.styles';
import {
	ButtonBlock2,
	InputBlock,
} from '../../../components/atom/inputs/Inputs.styles';
import {
	Seperator,
	SeperatorVertical,
} from '../../../components/atom/seperator/Seperator.styles';
import { DatePicker } from '../../../components/molecule/date-picker/DatePicker.component';
import { TeamTypeBlock, TypeItem } from '../PlayersList.styles';
import {
	InverseButton,
	Button,
} from '../../../components/atom/buttons/Button.styles';
import { InputField } from '../../../components/molecule/input-field/InputField.component';
import { DropdownField } from '../../../components/molecule/dropdown-field/DropdownField.component';
import { Switch } from '../../../components/molecule/switch/Switch.component';
import { Checkbox } from '../../../components/molecule/checkbox/Checkbox.component';
import { LoadingList } from '../../../components/atom/loader/Loader.component';

import { WithReduxPlayers } from '../../../components/hoc/WithReduxPlayers.hoc';
import { WithReduxPositionTypes } from '../../../components/hoc/WithReduxPositionTypes.hoc';

const EditPopup = ({
	positionTypes,
	singlePlayer,
	playerSingleIsFetching,
	setPlayersIsEdit,
	clearSinglePlayer,
	singlePlayerTeams,
	setSinglePlayerIsTeamsStart,
	setSinglePlayerFirstName,
	setSinglePlayerLastName,
	setSinglePlayerIsActive,
	setSinglePlayerDateOfBirth,
	setSinglePlayerTagName,
	setSinglePlayerAlternateTagName,
	setSinglePlayerMonitorName,
	setSinglePlayerPositionTypeId,
	createSinglePlayerStart,
	updateSinglePlayerStart,
}) => {
	const isFormFilled =
		singlePlayer.firstName !== '' &&
		singlePlayer.lastName !== '' &&
		singlePlayer.positionTypeId !== '' &&
		singlePlayer.dateOfBirth !== null;

	return (
		<PopupOverlay>
			<EditBlock>
				{!playerSingleIsFetching ? (
					<div className='flex-row'>
						<InputBlock gridColumns='240px 240px'>
							<InputField
								required
								label='First Name'
								value={singlePlayer.firstName}
								action={setSinglePlayerFirstName}
							/>
							<InputField
								required
								label='Last Name'
								value={singlePlayer.lastName}
								action={setSinglePlayerLastName}
							/>
							<DatePicker
								required
								label='Date of Birth'
								value={singlePlayer.dateOfBirth}
								action={setSinglePlayerDateOfBirth}
							/>
							<Seperator className='full-width' />
							<InputField
								label='Tag Player Name'
								value={singlePlayer.tagName}
								action={setSinglePlayerTagName}
							/>
							<InputField
								label='Alternate Tag Player Name'
								value={singlePlayer.alternateTagName}
								action={setSinglePlayerAlternateTagName}
							/>
							<InputField
								label='Monitor Player Name'
								value={singlePlayer.monitorName}
								action={setSinglePlayerMonitorName}
							/>
							<Seperator className='full-width' />
							<DropdownField
								required
								label='Position'
								nullLabel='- Select -'
								value={singlePlayer.positionTypeId}
								data={positionTypes}
								dataProp='label'
								action={setSinglePlayerPositionTypeId}
							/>
							<div />
							<Switch
								id='players-is-active'
								label='Is Active'
								value={singlePlayer.isActive}
								action={setSinglePlayerIsActive}
							/>
						</InputBlock>
						<SeperatorVertical margin='20px' />
						<TeamTypeBlock>
							{singlePlayerTeams.map((team, index) => (
								<TypeItem key={index}>
									<Checkbox
										label={team.name}
										index={index}
										value={team.isTeam}
										action={setSinglePlayerIsTeamsStart}
									/>
									<Seperator className='seperator' />
								</TypeItem>
							))}
						</TeamTypeBlock>
					</div>
				) : (
					<LoadingList />
				)}
				<ButtonBlock2>
					<InverseButton
						onClick={() => {
							setPlayersIsEdit(false);
							clearSinglePlayer();
						}}
					>
						Cancel
					</InverseButton>
					<Button
						className='save-button'
						disabled={!isFormFilled}
						onClick={() =>
							singlePlayer.id
								? updateSinglePlayerStart()
								: createSinglePlayerStart()
						}
					>
						{singlePlayer.id ? 'Update' : 'Create'}
					</Button>
				</ButtonBlock2>
			</EditBlock>
		</PopupOverlay>
	);
};

export default compose(WithReduxPositionTypes, WithReduxPlayers)(EditPopup);
