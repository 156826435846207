import { FilesTypes } from './types';

/* ================================================================ */
/*  Process Start  	                                                */
/* ================================================================ */

export const fetchFilesStart = () => ({
	type: FilesTypes.FETCH_FILES_START,
});

export const fetchFilesFromFolderStart = (fullPath, folder) => ({
	type: FilesTypes.FETCH_FILES_FROM_FOLDER_START,
	payload: { fullPath, folder },
});

export const fetchFilesFromBackStart = path => ({
	type: FilesTypes.FETCH_FILES_FROM_BACK_START,
	payload: path,
});

export const clearFilesReducer = () => ({
	type: FilesTypes.CLEAR_FILES_REDUCER,
});

/* ================================================================ */
/*  Process Success	                                                */
/* ================================================================ */

export const fetchFilesSuccess = files => ({
	type: FilesTypes.FETCH_FILES_SUCCESS,
	payload: files,
});

export const fetchFilesFromFolderSuccess = files => ({
	type: FilesTypes.FETCH_FILES_FROM_FOLDER_SUCCESS,
	payload: files,
});

export const fetchFilesFromBackSuccess = files => ({
	type: FilesTypes.FETCH_FILES_FROM_BACK_SUCCESS,
	payload: files,
});

/* ================================================================ */
/*  Process Failure	       	                                        */
/* ================================================================ */

export const fetchFilesFailure = errorMessage => ({
	type: FilesTypes.FETCH_FILES_FAILURE,
	payload: errorMessage,
});

export const fetchFilesFromFolderFailure = errorMessage => ({
	type: FilesTypes.FETCH_FILES_FROM_FOLDER_FAILURE,
	payload: errorMessage,
});

export const fetchFilesFromBackFailure = errorMessage => ({
	type: FilesTypes.FETCH_FILES_FROM_BACK_FAILURE,
	payload: errorMessage,
});
