import { createSelector } from 'reselect';

const selectUserStore = state => state.user;

// Selects user.currentUser from the state
export const selectCurrentUser = createSelector(
	[selectUserStore],
	user => user.currentUser
);

// Selects user.email from the state
export const selectEmail = createSelector(
	[selectUserStore],
	user => user.email
);

// Selects user.password from the state
export const selectPassword = createSelector(
	[selectUserStore],
	user => user.password
);

// Selects user.isResetPassword from the state
export const selectIsResetPassword = createSelector(
	[selectUserStore],
	user => user.isResetPassword
);

// Selects user.isResetPasswordSuccess from the state
export const selectIsResetPasswordSuccess = createSelector(
	[selectUserStore],
	user => user.isResetPasswordSuccess
);

// Selects user.isFetching from the state
export const selectIsFetching = createSelector(
	[selectUserStore],
	user => user.isFetching
);

// Selects user.errorMessage from the state
export const selectErrorMessage = createSelector(
	[selectUserStore],
	user => user.errorMessage
);
