import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// Redux: Selectors
import {
	selectCurrentUser,
	selectEmail,
	selectPassword,
	selectIsResetPassword,
	selectIsResetPasswordSuccess,
	selectErrorMessage,
	selectIsFetching,
} from '../../pages/login/redux/selectors';

// Redux: Actions
import {
	emailSignInStart,
	sendPasswordResetEmailStart,
	setLoginEmail,
	setLoginPassword,
	setResetPassword,
	clearLoginCredentials,
	clearAfterPasswordReset,
	logOut,
} from '../../pages/login/redux/actions';

export const WithReduxUser = WrappedComponent => {
	// Add Redux actions and state to the WrappedComponent
	const WithData = props => {
		return <WrappedComponent {...props} />;
	};

	// Redux: Selectors
	const mapStateToProps = createStructuredSelector({
		currentUser: selectCurrentUser,
		email: selectEmail,
		password: selectPassword,
		isResetPassword: selectIsResetPassword,
		isResetPasswordSuccess: selectIsResetPasswordSuccess,
		errorMessage: selectErrorMessage,
		isFetching: selectIsFetching,
	});

	// Redux: Actions
	const mapDispatchToProps = dispatch => ({
		emailSignInStart: () => dispatch(emailSignInStart()),
		sendPasswordResetEmailStart: () =>
			dispatch(sendPasswordResetEmailStart()),
		setLoginEmail: email => dispatch(setLoginEmail(email)),
		setLoginPassword: password => dispatch(setLoginPassword(password)),
		setResetPassword: bool => dispatch(setResetPassword(bool)),
		clearLoginCredentials: () => dispatch(clearLoginCredentials()),
		clearAfterPasswordReset: () => dispatch(clearAfterPasswordReset()),
		logOut: () => dispatch(logOut()),
	});

	return connect(mapStateToProps, mapDispatchToProps)(WithData);
};
