import { Fragment } from 'react';
import { compose } from 'redux';

import {
	Chip,
	Table2,
	TableData2,
	TableDataChip2,
	TableHeader2,
} from '../../../components/molecule/table/Table.styles';

import { WithReduxPlayers } from '../../../components/hoc/WithReduxPlayers.hoc';

const List = ({ filteredPlayers, fetchSinglePlayerStart }) => {
	return (
		<Table2 gridColumns='1fr 1fr'>
			<TableHeader2>Player Name</TableHeader2>
			<TableHeader2>Is Active</TableHeader2>
			{filteredPlayers.map((player, index) => (
				<Fragment key={index}>
					<TableData2
						onClick={() => {
							fetchSinglePlayerStart(player.id);
						}}
					>
						{`${player.firstName} ${player.lastName}`}
					</TableData2>
					<TableDataChip2
						onClick={() => {
							fetchSinglePlayerStart(player.id);
						}}
					>
						<Chip isActive={player.isActive}>
							{player.isActive ? 'Active' : 'Inactive'}
						</Chip>
					</TableDataChip2>
				</Fragment>
			))}
		</Table2>
	);
};

export default compose(WithReduxPlayers)(List);
