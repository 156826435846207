import { createSelector } from 'reselect';

const selectPlayersStore = state => state.players;

// Selects players.players from the state
export const selectPlayers = createSelector(
	[selectPlayersStore],
	players => players.players
);

// Selects players.isFetching from the state
export const selectPlayersIsFetching = createSelector(
	[selectPlayersStore],
	players => players.isFetching
);

// Selects players.isEdit from the state
export const selectPlayersIsEdit = createSelector(
	[selectPlayersStore],
	players => players.isEdit
);

// Selects players.filteredPlayers from the state
export const selectFilteredPlayers = createSelector(
	[selectPlayersStore],
	players => players.filteredPlayers
);

// Selects players.filterTeamTypeId from the state
export const selectPlayersFilterTeamTypeId = createSelector(
	[selectPlayersStore],
	players => players.filterTeamTypeId
);

// Selects players.filterSearch from the state
export const selectFilterSearch = createSelector(
	[selectPlayersStore],
	players => players.filterSearch
);
