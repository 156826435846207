import styled from 'styled-components';

export const LoaderBlock = styled.div`
	display: flex;
	justify-content: center;
	height: 34px;
	width: 100%;
`;

export const LoaderListBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
`;

// TODO: Remove this and check where it is being used.
export const LoadingBlock = styled.div`
	display: flex;
	justify-content: center;
`;
