import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import oppositionsReducer from '../pages/oppositions/redux/list-screen/reducer';
import singleOppositionReducer from '../pages/oppositions/redux/edit-screen/reducer';
import myTeamsReducer from '../pages/my-teams/redux/list-screen/reducer';
import singleMyTeamReducer from '../pages/my-teams/redux/edit-screen/reducer';
import playersReducer from '../pages/players/redux/list-screen/reducer';
import singlePlayerReducer from '../pages/players/redux/edit-screen/reducer';
import competitionReducer from '../pages/competition/redux/list-screen/reducer';
import singleCompetitionReducer from '../pages/competition/redux/edit-screen/reducer';
import matchesReducer from '../pages/matches/redux/reducer';
import singleMatchReducer from '../pages/matches/edit-page/redux/reducer';
import userReducer from '../pages/login/redux/reducer';
import filesReducer from '../pages/files/redux/list-screen/reducer';

// Global Reducers
import teamTypesReducer from './team-types/reducer';
import positionTypesReducer from './position-types/reducer';
import countriesReducer from './countries/reducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: [
		'oppositions',
		'singleOpposition',
		'myTeams',
		'singleMyTeam',
		'players',
		'singlePlayer',
		'competition',
		'singleCompetition',
		'matches',
		'singleMatch',
		'user',
		'files',
		'countries',
		'teamTypes',
		'positionTypes',
	],
};

const rootReducer = combineReducers({
	oppositions: oppositionsReducer,
	singleOpposition: singleOppositionReducer,
	matches: matchesReducer,
	singleMatch: singleMatchReducer,
	myTeams: myTeamsReducer,
	singleMyTeam: singleMyTeamReducer,
	players: playersReducer,
	singlePlayer: singlePlayerReducer,
	competition: competitionReducer,
	singleCompetition: singleCompetitionReducer,
	user: userReducer,
	files: filesReducer,
	teamTypes: teamTypesReducer,
	positionTypes: positionTypesReducer,
	countries: countriesReducer,
});

export default persistReducer(persistConfig, rootReducer);
