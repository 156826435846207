import { SinglePlayerTypes } from './types';

/* ================================================================ */
/*  Process Start  	                                                */
/* ================================================================ */

export const fetchSinglePlayerStart = id => ({
	type: SinglePlayerTypes.FETCH_SINGLE_PLAYER_START,
	payload: id,
});

export const newSinglePlayerStart = () => ({
	type: SinglePlayerTypes.NEW_SINGLE_PLAYER_START,
});

export const createSinglePlayerStart = () => ({
	type: SinglePlayerTypes.CREATE_SINGLE_PLAYER_START,
});

export const updateSinglePlayerStart = () => ({
	type: SinglePlayerTypes.UPDATE_SINGLE_PLAYER_START,
});

export const setSinglePlayerTeams = teams => ({
	type: SinglePlayerTypes.SET_SINGLE_PLAYER_TEAMS,
	payload: teams,
});

export const setSinglePlayerIsTeamsStart = (bool, index) => ({
	type: SinglePlayerTypes.SET_SINGLE_PLAYER_IS_TEAMS_START,
	payload: { bool, index },
});

export const setSinglePlayerFirstName = firstName => ({
	type: SinglePlayerTypes.SET_SINGLE_PLAYER_FIRSTNAME,
	payload: firstName,
	inputName: 'firstName',
});

export const setSinglePlayerLastName = lastName => ({
	type: SinglePlayerTypes.SET_SINGLE_PLAYER_LASTNAME,
	payload: lastName,
	inputName: 'lastName',
});

export const setSinglePlayerIsActive = bool => ({
	type: SinglePlayerTypes.SET_SINGLE_PLAYER_IS_ACTIVE,
	payload: bool,
	inputName: 'isActive',
});

export const setSinglePlayerDateOfBirth = dateOfBirth => ({
	type: SinglePlayerTypes.SET_SINGLE_PLAYER_DATE_OF_BIRTH,
	payload: dateOfBirth,
	inputName: 'dateOfBirth',
});

export const setSinglePlayerTagName = tagName => ({
	type: SinglePlayerTypes.SET_SINGLE_PLAYER_TAG_NAME,
	payload: tagName,
	inputName: 'tagName',
});

export const setSinglePlayerAlternateTagName = alternateTagName => ({
	type: SinglePlayerTypes.SET_SINGLE_PLAYER_ALTERNATE_TAG_NAME,
	payload: alternateTagName,
	inputName: 'alternateTagName',
});

export const setSinglePlayerMonitorName = monitorName => ({
	type: SinglePlayerTypes.SET_SINGLE_PLAYER_MONITOR_NAME,
	payload: monitorName,
	inputName: 'monitorName',
});

export const setSinglePlayerPositionTypeId = positionTypeId => ({
	type: SinglePlayerTypes.SET_SINGLE_PLAYER_POSITION_TYPE_ID,
	payload: positionTypeId,
	inputName: 'positionTypeId',
});

export const clearSinglePlayer = () => ({
	type: SinglePlayerTypes.CLEAR_SINGLE_PLAYER,
});

/* ================================================================ */
/*  Process Success	                                                */
/* ================================================================ */

export const fetchSinglePlayerSuccess = player => ({
	type: SinglePlayerTypes.FETCH_SINGLE_PLAYER_SUCCESS,
	payload: player,
});

export const newSinglePlayerSuccess = () => ({
	type: SinglePlayerTypes.NEW_SINGLE_PLAYER_SUCCESS,
});

export const createSinglePlayerSuccess = () => ({
	type: SinglePlayerTypes.CREATE_SINGLE_PLAYER_SUCCESS,
});

export const updateSinglePlayerSuccess = () => ({
	type: SinglePlayerTypes.UPDATE_SINGLE_PLAYER_SUCCESS,
});

export const setSinglePlayerIsTeamsSuccess = teams => ({
	type: SinglePlayerTypes.SET_SINGLE_PLAYER_IS_TEAMS_SUCCESS,
	payload: teams,
});

/* ================================================================ */
/*  Process Failure	       	                                        */
/* ================================================================ */

export const fetchSinglePlayerFailure = errorMessage => ({
	type: SinglePlayerTypes.FETCH_SINGLE_PLAYER_FAILURE,
	payload: errorMessage,
});

export const newSinglePlayerFailure = errorMessage => ({
	type: SinglePlayerTypes.NEW_SINGLE_PLAYER_FAILURE,
	payload: errorMessage,
});

export const createSinglePlayerFailure = errorMessage => ({
	type: SinglePlayerTypes.CREATE_SINGLE_PLAYER_FAILURE,
	payload: errorMessage,
});

export const updateSinglePlayerFailure = errorMessage => ({
	type: SinglePlayerTypes.UPDATE_SINGLE_PLAYER_FAILURE,
	payload: errorMessage,
});

export const setSinglePlayerIsTeamsFailure = errorMessage => ({
	type: SinglePlayerTypes.SET_SINGLE_PLAYER_IS_TEAMS_FAILURE,
	payload: errorMessage,
});
