import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import { getCurrentUserToken } from '../../../../firebase/firebase.utils';
import { BASEAPI } from '../../../../routes/routes';

// Types
import { SinglePlayerTypes } from './types';

// Actions
import {
	fetchSinglePlayerSuccess,
	fetchSinglePlayerFailure,
	newSinglePlayerSuccess,
	newSinglePlayerFailure,
	createSinglePlayerSuccess,
	createSinglePlayerFailure,
	updateSinglePlayerSuccess,
	updateSinglePlayerFailure,
	setSinglePlayerIsTeamsSuccess,
	setSinglePlayerIsTeamsFailure,
	setSinglePlayerTeams,
} from './actions';
import { setPlayersIsEdit, fetchPlayersStart } from '../list-screen/actions';

// Selectors
import { selectSinglePlayer, selectSinglePlayerTeams } from './selectors';
import { selectMyTeams } from '../../../my-teams/redux/list-screen/selectors';

/* ================================================================ */
/*  Actions                                                         */
/* ================================================================ */

export function* fetchSinglePlayerStart({ payload: id }) {
	try {
		yield put(setPlayersIsEdit(true));

		const token = yield call(getCurrentUserToken);

		const myTeams = yield select(selectMyTeams);
		const HEADERS = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const [{ data: player }, { data: teams }] = yield all([
			call(axios.get, `${BASEAPI}/players/${id}`, HEADERS),
			call(axios.get, `${BASEAPI}/players/${id}/teams`, HEADERS),
		]);

		const newMyTeams = yield myTeams.map(myTeam => {
			return {
				id: myTeam.id,
				isTeam: teams.some(team => team === myTeam.id),
				name: myTeam.name,
			};
		});

		const newPlayer = {
			...player,
			alternateTagName: _.isNull(player.alternateTagName)
				? ''
				: player.alternateTagName,
			monitorName: _.isNull(player.monitorName) ? '' : player.monitorName,
		};

		yield put(fetchSinglePlayerSuccess(newPlayer));
		yield put(setSinglePlayerTeams(newMyTeams));
	} catch (error) {
		console.error(error);
		yield put(fetchSinglePlayerFailure(error));
		yield put(setPlayersIsEdit(false));
	}
}

export function* newSinglePlayerStart() {
	try {
		yield put(setPlayersIsEdit(true));

		const myTeams = yield select(selectMyTeams);

		const newMyTeams = yield myTeams.map(myTeam => {
			return {
				id: myTeam.id,
				isTeam: false,
				name: myTeam.name,
			};
		});

		yield put(newSinglePlayerSuccess());
		yield put(setSinglePlayerTeams(newMyTeams));
	} catch (error) {
		console.error(error);
		yield put(newSinglePlayerFailure(error));
		yield put(setPlayersIsEdit(false));
	}
}

export function* createSinglePlayerStart() {
	try {
		const token = yield call(getCurrentUserToken);

		const { id, ...singlePlayer } = yield select(selectSinglePlayer);
		const teams = yield select(selectSinglePlayerTeams);

		const HEADERS = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		let teamsIds = [];
		teams.forEach(team => team.isTeam && teamsIds.push(team.id));

		const newSinglePlayer = {
			...singlePlayer,
			dateOfBirth: moment(singlePlayer.dateOfBirth).format('YYYY-MM-DD'),
		};

		const { data: response } = yield call(
			axios.post,
			`${BASEAPI}/players`,
			{ ...newSinglePlayer },
			HEADERS
		);

		yield call(
			axios.put,
			`${BASEAPI}/players/${response.playerId}/teams`,
			[...teamsIds],
			HEADERS
		);

		yield put(fetchPlayersStart());
		yield put(setPlayersIsEdit(false));
		yield put(createSinglePlayerSuccess());
	} catch (error) {
		console.error(error);
		yield put(createSinglePlayerFailure(error));
	}
}

export function* updateSinglePlayerStart() {
	try {
		const token = yield call(getCurrentUserToken);

		const { id, ...singlePlayer } = yield select(selectSinglePlayer);
		const teams = yield select(selectSinglePlayerTeams);

		const HEADERS = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		let teamsIds = [];
		teams.forEach(team => {
			if (team.isTeam) return teamsIds.push(team.id);
		});

		const newSinglePlayer = {
			...singlePlayer,
			dateOfBirth: moment(singlePlayer.dateOfBirth).format('YYYY-MM-DD'),
		};

		yield all([
			call(
				axios.patch,
				`${BASEAPI}/players/${id}`,
				{ ...newSinglePlayer },
				HEADERS
			),
			call(
				axios.put,
				`${BASEAPI}/players/${id}/teams`,
				[...teamsIds],
				HEADERS
			),
		]);

		yield put(fetchPlayersStart());
		yield put(setPlayersIsEdit(false));
		yield put(updateSinglePlayerSuccess());
	} catch (error) {
		console.error(error);
		yield put(updateSinglePlayerFailure(error));
	}
}

export function* setSinglePlayerIsTeamsStart({ payload: { bool, index } }) {
	try {
		const teams = yield select(selectSinglePlayerTeams);
		const newTeams = [...teams];

		newTeams[index].isTeam = bool;

		yield put(setSinglePlayerIsTeamsSuccess(newTeams));
	} catch (error) {
		console.error(error);
		yield put(setSinglePlayerIsTeamsFailure(error));
	}
}

/* ================================================================ */
/*  Listeners                                                       */
/* ================================================================ */

export function* onFetchSinglePlayerStart() {
	yield takeLatest(
		SinglePlayerTypes.FETCH_SINGLE_PLAYER_START,
		fetchSinglePlayerStart
	);
}

export function* onNewSinglePlayerStart() {
	yield takeLatest(
		SinglePlayerTypes.NEW_SINGLE_PLAYER_START,
		newSinglePlayerStart
	);
}

export function* onCreateSinglePlayerStart() {
	yield takeLatest(
		SinglePlayerTypes.CREATE_SINGLE_PLAYER_START,
		createSinglePlayerStart
	);
}

export function* onUpdateSinglePlayerStart() {
	yield takeLatest(
		SinglePlayerTypes.UPDATE_SINGLE_PLAYER_START,
		updateSinglePlayerStart
	);
}

export function* onSetPlayersIsTeamsStart() {
	yield takeLatest(
		SinglePlayerTypes.SET_SINGLE_PLAYER_IS_TEAMS_START,
		setSinglePlayerIsTeamsStart
	);
}

/* ================================================================ */
/*  Root Saga                                                       */
/* ================================================================ */

export default function* singlePlayerSagas() {
	yield all([
		call(onFetchSinglePlayerStart),
		call(onNewSinglePlayerStart),
		call(onCreateSinglePlayerStart),
		call(onUpdateSinglePlayerStart),
		call(onSetPlayersIsTeamsStart),
	]);
}
