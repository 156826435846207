import { useHistory, useLocation } from 'react-router-dom';
import * as ROUTES from '../../../routes/routes';

import {
	MenuBlock2,
	LogoBlock2,
	MenuLinkBlock,
	MenuLink2,
} from './MenuBlock.styles';
import logoImage from '../../../assets/logo-1.png';

export const MenuBlock = () => {
	const { push } = useHistory();
	const { pathname } = useLocation();
	const currentPath = pathname.split('/')[1];

	return (
		<MenuBlock2>
			<LogoBlock2>
				<img src={logoImage} alt='' />
				<div className='logo-block'>
					<p className='logo-top'>
						brighton <br />& hove albion
					</p>
					<div className='seperator'></div>
					<p className='logo-bottom'>back office</p>
				</div>
			</LogoBlock2>
			<MenuLinkBlock>
				<MenuLink2
					isCurrent={currentPath === ROUTES.MATCHES}
					onClick={() => push(`/${ROUTES.MATCHES}`)}
				>
					Matches
				</MenuLink2>
				<MenuLink2
					isCurrent={currentPath === ROUTES.PLAYERS}
					onClick={() => push(`/${ROUTES.PLAYERS}`)}
				>
					Players
				</MenuLink2>
				<MenuLink2
					isCurrent={currentPath === ROUTES.OPPOSITIONS}
					onClick={() => push(`/${ROUTES.OPPOSITIONS}`)}
				>
					Oppositions
				</MenuLink2>
				<MenuLink2
					isCurrent={currentPath === ROUTES.COMPETITION}
					onClick={() => push(`/${ROUTES.COMPETITION}`)}
				>
					Competition
				</MenuLink2>
				<MenuLink2
					isCurrent={currentPath === ROUTES.MY_TEAMS}
					onClick={() => push(`/${ROUTES.MY_TEAMS}`)}
				>
					My Teams
				</MenuLink2>
				<MenuLink2
					isCurrent={currentPath === ROUTES.FILES}
					onClick={() => push(`/${ROUTES.FILES}`)}
				>
					Files
				</MenuLink2>
			</MenuLinkBlock>
		</MenuBlock2>
	);
};
