import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import axios from 'axios';

import { getCurrentUserToken } from '../../../../firebase/firebase.utils';
import { BASEAPI } from '../../../../routes/routes';

// Types
import { PlayersActionTypes } from './types';

// Actions
import {
	clearAllPlayersFilter,
	fetchPlayersFailure,
	fetchPlayersSuccess,
	setFilteredPlayers,
} from './actions';
import { fetchMyTeamsSuccess } from '../../../my-teams/redux/list-screen/actions';

// Selectors
import {
	selectFilterSearch,
	selectPlayers,
	selectPlayersFilterTeamTypeId,
} from './selectors';

/* ================================================================ */
/*  Reusable Actions                                                */
/* ================================================================ */

const hasPlayerSearch = (player, search) =>
	player.firstName.toLowerCase().includes(search.toLowerCase()) ||
	player.lastName.toLowerCase().includes(search.toLowerCase());

const hasPlayerTeamId = (player, id) =>
	player.teamIds.some(teamId => teamId === id);

/* ================================================================ */
/*  Actions                                                         */
/* ================================================================ */

export function* fetchPlayersStart() {
	try {
		yield put(clearAllPlayersFilter());

		const token = yield call(getCurrentUserToken);

		const HEADERS = yield {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const [{ data: players }, { data: myTeams }] = yield all([
			call(axios.get, `${BASEAPI}/players`, HEADERS),
			call(axios.get, `${BASEAPI}/teams`, HEADERS),
		]);

		yield put(fetchMyTeamsSuccess(myTeams));
		yield put(setFilteredPlayers(players));
		yield put(fetchPlayersSuccess(players));
	} catch (error) {
		console.error(error);
		yield put(fetchPlayersFailure(error));
	}
}

export function* startPlayersFilters() {
	try {
		const players = yield select(selectPlayers);
		const filterSearch = yield select(selectFilterSearch);
		const filterTeamTypeId = yield select(selectPlayersFilterTeamTypeId);

		const hasFilters = filterSearch !== '' || filterTeamTypeId !== '';

		const filteredPlayers = hasFilters
			? players.filter(player => {
					if (filterSearch && !filterTeamTypeId) {
						return hasPlayerSearch(player, filterSearch);
					}
					if (!filterSearch && filterTeamTypeId) {
						return hasPlayerTeamId(player, filterTeamTypeId);
					}
					if (filterSearch && filterTeamTypeId) {
						return (
							hasPlayerSearch(player, filterSearch) &&
							hasPlayerTeamId(player, filterTeamTypeId)
						);
					}
					return false;
			  })
			: players;

		yield put(setFilteredPlayers(filteredPlayers));
	} catch (error) {
		console.error(error);
	}
}

/* ================================================================ */
/*  Listeners                                                       */
/* ================================================================ */

export function* onFetchPlayersStart() {
	yield takeLatest(PlayersActionTypes.FETCH_PLAYERS_START, fetchPlayersStart);
}

export function* onSetPlayersSearchFilter() {
	yield takeLatest(
		PlayersActionTypes.SET_PLAYERS_SEARCH_FILTER,
		startPlayersFilters
	);
}

export function* onSetPlayersFilterTeamTypeId() {
	yield takeLatest(
		PlayersActionTypes.SET_PLAYERS_FILTER_TEAM_TYPE_ID,
		startPlayersFilters
	);
}

export function* onClearPlayersSearchFilter() {
	yield takeLatest(
		PlayersActionTypes.CLEAR_PLAYERS_SEARCH_FILTER,
		startPlayersFilters
	);
}

/* ================================================================ */
/*  Root Saga                                                       */
/* ================================================================ */

export default function* playersSagas() {
	yield all([
		call(onFetchPlayersStart),
		call(onSetPlayersSearchFilter),
		call(onSetPlayersFilterTeamTypeId),
		call(onClearPlayersSearchFilter),
	]);
}
