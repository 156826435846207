import styled, { css } from 'styled-components';

export const MenuContainer = styled.div`
	grid-area: menu;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #094899;
`;

export const LogoBlock = styled.div`
	display: flex;
	margin-bottom: 12px;
	padding-top: 12px;
	padding-bottom: 12px;
	flex-direction: column;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
`;

export const ImageBlock = styled.div`
	display: flex;
	justify-content: center;

	img {
		width: 70px;
		max-width: 100%;
		vertical-align: middle;
		display: inline-block;
	}
`;

export const UserName = styled.div`
	margin-top: 24px;
	margin-left: 12px;
	margin-right: 12px;
	color: #fff;
	font-size: 20px;
	font-weight: 500;
`;

export const MenuLink = styled.div`
	padding: 12px;
	color: #fff;
	font-size: 16px;
	cursor: pointer;

	${props =>
		props.isCurrent &&
		css`
			background-color: #5173ca;
		`}
`;

export const LogoutContainer = styled.div`
	padding-top: 13px;
	padding-bottom: 12px;
	padding-left: 12px;
	color: #fff;
	font-size: 16px;
	cursor: pointer;
	border-top: 1px solid hsla(0, 0%, 100%, 0.25);
`;

/*======================================================*/

export const MenuBlock2 = styled.div`
	grid-area: menu;
	display: grid;
	grid-auto-columns: 1fr;
	grid-template-columns: 1fr;
	grid-template-rows: 74px auto;
	background-color: #1e1e2d;
`;

export const LogoBlock2 = styled.div`
	display: flex;
	padding: 0 12px;
	align-items: center;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);

	img {
		width: 48px;
	}

	.logo-block {
		width: 100%;
		margin-left: 12px;

		.logo-top {
			color: #fff;
			font-size: 12px;
			line-height: 12px;
			font-weight: 900;
			letter-spacing: 3px;
			text-transform: uppercase;
		}

		.seperator {
			width: 100%;
			height: 1px;
			margin: 8px 0;
			background-color: #fff;
		}

		.logo-bottom {
			color: #fff;
			font-size: 14px;
			line-height: 14px;
			font-weight: 200;
			letter-spacing: 8px;
			text-transform: uppercase;
		}
	}
`;

export const MenuLinkBlock = styled.div`
	padding-top: 40px;
	padding-right: 20px;
	padding-left: 20px;
`;

export const MenuLink2 = styled.div`
	padding: 20px 32px;
	border-radius: 8px;
	color: hsla(0, 0%, 100%, 0.6);
	font-size: 18px;
	font-weight: 400;
	text-transform: capitalize;
	cursor: pointer;
	transition: all 0.2s ease;

	:hover {
		color: #fff;
	}

	${props =>
		props.isCurrent &&
		css`
			background-color: #0054a6;
			color: #fff;
		`}
`;
