import { Fragment, useEffect } from 'react';
import { compose } from 'redux';

import List from './components/List.component';
import ListFilter from './components/ListFilter.component';
import EditPopup from './components/EditPopup.component';
import { Box } from '../../components/atom/containers/Box.styles';
import { LoadingList } from '../../components/atom/loader/Loader.component';

import { WithReduxPositionTypes } from '../../components/hoc/WithReduxPositionTypes.hoc';
import { WithReduxMyTeams } from '../../components/hoc/WithReduxMyTeams.hoc';
import { WithReduxPlayers } from '../../components/hoc/WithReduxPlayers.hoc';

const PlayersList = ({
	playersIsEdit,
	playersIsFetching,
	fetchPlayersStart,
	fetchPositionTypesStart,
	fetchMyTeamsStart,
}) => {
	useEffect(() => {
		fetchPlayersStart();
		fetchPositionTypesStart();
		fetchMyTeamsStart();
	}, [fetchPlayersStart, fetchPositionTypesStart, fetchMyTeamsStart]);

	return (
		<Fragment>
			{!playersIsFetching ? (
				<Box padding='48px'>
					<ListFilter />
					<List />
				</Box>
			) : (
				<LoadingList />
			)}
			{playersIsEdit && <EditPopup />}
		</Fragment>
	);
};

export default compose(
	WithReduxPlayers,
	WithReduxPositionTypes,
	WithReduxMyTeams
)(PlayersList);
