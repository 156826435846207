import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

/* ================================================================ */
/*  Initializing Firebase Process                                   */
/* ================================================================ */

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
};

firebase.initializeApp(config);

/* ================================================================ */
/*  Firebase Actions                                                */
/* ================================================================ */

export const getCurrentUserToken = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			const token = user.getIdToken(true);
			unsubscribe();
			resolve(token);
		}, reject);
	});
};
/* ================================================================ */
/*  Firebase Auth                                                   */
/* ================================================================ */

export const auth = firebase.auth();

/* ================================================================ */
/*  Firebase Storage                                                */
/* ================================================================ */

export const storage = firebase.storage();

/* ================================================================ */
/*  Firebase Auth Providers                                         */
/* ================================================================ */

export const signOutFromAuth = () => auth.signOut();

/* ================================================================ */
/*  Export Default                                         			*/
/* ================================================================ */

export default firebase;
