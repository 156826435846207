import logoImage from '../../../../assets/logo-1.png';
import { LoginLogoBlock } from './LoginLogoSection.styles';

export const LoginLogoSection = () => {
	return (
		<LoginLogoBlock>
			<div className='logo-info'>
				<img src={logoImage} alt='' />
				<div className='logo-label-top'>
					brighton
					<br />& hove albion
				</div>
				<div className='seperator'></div>
				<div className='logo-label-bottom'>back office</div>
			</div>
		</LoginLogoBlock>
	);
};
