import { LoginMain2 } from './Login.styles';
import { LoginLogoSection } from './components/login-logo-section/LoginLogoSection.component';
import LoginInputSection from './components/login-input-section/LoginInputSection.component';

export const Login = () => {
	return (
		<LoginMain2>
			<LoginLogoSection />
			<LoginInputSection />
		</LoginMain2>
	);
};
