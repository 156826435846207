import styled, { css } from 'styled-components';
import { colorStyles } from '../../../styles/global.styles';

export const SwitchBlock = styled.div`
	display: flex;
	align-items: center;

	.toggle-label {
		margin-right: 12px;
		text-transform: capitalize;
		color: ${colorStyles.greyScale4};

		${({ hasId }) =>
			hasId &&
			css`
				cursor: pointer;
			`}
	}

	.switch {
		position: relative;
		display: inline-block;
		width: 48px;
		height: 24px;

		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 1000px;
			background-color: #f5f8fa;
			transition: 0.2s ease;
		}

		.slider:before {
			position: absolute;
			content: '';
			height: 18px;
			width: 18px;
			left: 3px;
			bottom: 3px;
			border-radius: 50%;
			background-color: ${colorStyles.primary};
			transition: 0.2s ease;
		}

		input {
			opacity: 0;
			width: 0;
			height: 0;

			:checked + .slider {
				background-color: ${colorStyles.primary};
			}

			:checked + .slider:before {
				transform: translateX(24px);
				background-color: white;
			}
		}
	}
`;
