import { Fragment } from 'react';
import { compose } from 'redux';

import { inputValidator } from '../../../../utils/utils';

import { LoginInputBlock } from './LoginInputSection.styles';
import { Box } from '../../../../components/atom/containers/Box.styles';
import { InputField } from '../../../../components/molecule/input-field/InputField.component';

import { WithReduxUser } from '../../../../components/hoc/WithReduxUser.hoc';
import { ButtonSection } from '../button-section/ButtonSection.component';

const LoginInputSection = ({
	email,
	password,
	isResetPassword,
	isResetPasswordSuccess,
	errorMessage,
	setLoginEmail,
	setLoginPassword,
	setResetPassword,
	emailSignInStart,
	sendPasswordResetEmailStart,
	clearAfterPasswordReset,
	isFetching,
}) => {
	const isEmailValid = inputValidator(
		errorMessage !== '' &&
			(errorMessage.code.includes('invalid-email') ||
				errorMessage.code.includes('user-not-found')),
		'Incorrect email'
	);
	const isPasswordValid = inputValidator(
		errorMessage !== '' && errorMessage.code.includes('wrong-password'),
		'Incorrect password'
	);

	return (
		<LoginInputBlock>
			<Box className='box'>
				<div className='input-grid'>
					{!isResetPasswordSuccess && (
						<InputField
							isInvalid={isEmailValid.isError}
							errorMessage={isEmailValid.errorText}
							value={email}
							action={setLoginEmail}
							required
							label='Email'
						/>
					)}
					{!isResetPassword && (
						<Fragment>
							<InputField
								isInvalid={isPasswordValid.isError}
								errorMessage={isPasswordValid.errorText}
								value={password}
								action={setLoginPassword}
								required
								label='Password'
								type='password'
							/>
							<p
								className='forgot-password-link'
								onClick={() => setResetPassword(true)}
							>
								Forgot Password
							</p>
						</Fragment>
					)}
					{isResetPasswordSuccess && (
						<p className='reset-success-text'>
							Reset link send successfully.
							<br />
							Please check your email
						</p>
					)}
					<ButtonSection
						className='reset-buttons'
						isFetching={isFetching}
						isResetPassword={isResetPassword}
						isResetPasswordSuccess={isResetPasswordSuccess}
						setResetPassword={setResetPassword}
						clearAfterPasswordReset={clearAfterPasswordReset}
						emailSignInStart={emailSignInStart}
						sendPasswordResetEmailStart={
							sendPasswordResetEmailStart
						}
					/>
				</div>
			</Box>
		</LoginInputBlock>
	);
};

// {
// 	isFetching ? (
// 		<LoadingSmall />
// 	) : (
// 		<Button onClick={emailSignInStart}>login</Button>
// 	);
// }

export default compose(WithReduxUser)(LoginInputSection);
