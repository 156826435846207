import { Dropdown } from '../../atom/dropdown/Dropdown.styles';
import { InputBlock } from '../input-field/InputField.styles';

export const DropdownField = ({
	label,
	nullLabel,
	value,
	data,
	dataProp,
	action = () => {},
	errorMessage,
	required,
	className,
	isInvalid,
	disabled,
	size,
}) => {
	const handelDropdown = event => {
		const value = event.target.value;

		value ? action(parseInt(value, 10)) : action('');
	};

	return (
		<InputBlock size={size} className={className}>
			<label htmlFor='text-input'>
				{`${label}: `}
				<span>{required ? '*' : ''}</span>
			</label>
			<Dropdown
				disabled={disabled}
				value={value}
				onChange={handelDropdown}
			>
				<option value=''>{nullLabel}</option>
				{data.map((item, index) => (
					<option key={index} value={item.id}>
						{item[dataProp]}
					</option>
				))}
			</Dropdown>
			<div className='error-label'>
				<p>{isInvalid ? errorMessage : ''}</p>
			</div>
		</InputBlock>
	);
};
