import { SinglePlayerTypes } from './types';

const INITIAL_STATE = {
	singlePlayer: {
		id: 0,
		firstName: '',
		lastName: '',
		isActive: true,
		dateOfBirth: null,
		tagName: '',
		alternateTagName: '',
		monitorName: '',
		positionTypeId: '',
	},
	teams: [],
	isSingleFetching: false,
	errorMessage: '',
};

const singlePlayerReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* ================================================================ */
		/*  Process Start                                                   */
		/* ================================================================ */
		case SinglePlayerTypes.FETCH_SINGLE_PLAYER_START:
			return {
				...state,
				isSingleFetching: true,
			};
		case SinglePlayerTypes.SET_SINGLE_PLAYER_TEAMS:
			return {
				...state,
				teams: action.payload,
			};
		case SinglePlayerTypes.SET_SINGLE_PLAYER_FIRSTNAME:
		case SinglePlayerTypes.SET_SINGLE_PLAYER_LASTNAME:
		case SinglePlayerTypes.SET_SINGLE_PLAYER_IS_ACTIVE:
		case SinglePlayerTypes.SET_SINGLE_PLAYER_DATE_OF_BIRTH:
		case SinglePlayerTypes.SET_SINGLE_PLAYER_TAG_NAME:
		case SinglePlayerTypes.SET_SINGLE_PLAYER_ALTERNATE_TAG_NAME:
		case SinglePlayerTypes.SET_SINGLE_PLAYER_MONITOR_NAME:
		case SinglePlayerTypes.SET_SINGLE_PLAYER_POSITION_TYPE_ID:
			return {
				...state,
				singlePlayer: {
					...state.singlePlayer,
					[action.inputName]: action.payload,
				},
			};
		/* ================================================================ */
		/*  Process Success                                                 */
		/* ================================================================ */
		case SinglePlayerTypes.CLEAR_SINGLE_PLAYER:
		case SinglePlayerTypes.NEW_SINGLE_PLAYER_SUCCESS:
		case SinglePlayerTypes.CREATE_SINGLE_PLAYER_SUCCESS:
		case SinglePlayerTypes.UPDATE_SINGLE_PLAYER_SUCCESS:
			return {
				...state,
				singlePlayer: {
					id: 0,
					firstName: '',
					lastName: '',
					isActive: true,
					dateOfBirth: null,
					tagName: '',
					alternateTagName: '',
					monitorName: '',
					positionTypeId: '',
				},
				teams: [],
				isSingleFetching: false,
				errorMessage: '',
			};
		case SinglePlayerTypes.SET_SINGLE_PLAYER_IS_TEAMS_SUCCESS:
			return {
				...state,
				teams: action.payload,
			};
		case SinglePlayerTypes.FETCH_SINGLE_PLAYER_SUCCESS:
			return {
				...state,
				singlePlayer: action.payload,
				isSingleFetching: false,
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Failure                                                 */
		/* ================================================================ */
		case SinglePlayerTypes.FETCH_SINGLE_PLAYER_FAILURE:
		case SinglePlayerTypes.NEW_SINGLE_PLAYER_FAILURE:
		case SinglePlayerTypes.CREATE_SINGLE_PLAYER_FAILURE:
		case SinglePlayerTypes.UPDATE_SINGLE_PLAYER_FAILURE:
		case SinglePlayerTypes.SET_SINGLE_PLAYER_IS_TEAMS_FAILURE:
			return {
				...state,
				errorMessage: action.payload,
				isSingleFetching: false,
			};
		default:
			return state;
	}
};

export default singlePlayerReducer;
