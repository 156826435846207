import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// Redux: Selectors
import {
	selectTeamTypes,
	selectTeamTypesIsFetching,
} from '../../redux/team-types/selectors';

// Redux: Actions
import { fetchTeamTypesStart } from '../../redux/team-types/actions';

export const WithReduxTeamTypes = WrappedComponent => {
	// Add Redux actions and state to the WrappedComponent
	const WithData = props => {
		return <WrappedComponent {...props} />;
	};

	// Redux: Selectors
	const mapStateToProps = createStructuredSelector({
		teamTypes: selectTeamTypes,
		teamTypesIsFetching: selectTeamTypesIsFetching,
	});

	// Redux: Actions
	const mapDispatchToProps = dispatch => ({
		fetchTeamTypesStart: () => dispatch(fetchTeamTypesStart()),
	});

	return connect(mapStateToProps, mapDispatchToProps)(WithData);
};
