import { createSelector } from 'reselect';

const selectOppositionsStore = state => state.oppositions;

// Selects oppositions.oppositions from the state
export const selectOppositions = createSelector(
	[selectOppositionsStore],
	oppositions => oppositions.oppositions
);

// Selects oppositions.filteredOppositions from the state
export const selectFilteredOppositions = createSelector(
	[selectOppositionsStore],
	oppositions => oppositions.filteredOppositions
);

// Selects oppositions.isFetching from the state
export const selectOppositionsIsFetching = createSelector(
	[selectOppositionsStore],
	oppositions => oppositions.isFetching
);

// Selects oppositions.isEdit from the state
export const selectOppositionsIsEdit = createSelector(
	[selectOppositionsStore],
	oppositions => oppositions.isEdit
);

// Selects oppositions.filterTeamTypeId from the state
export const selectOppositionsFilterTeamTypeId = createSelector(
	[selectOppositionsStore],
	oppositions => oppositions.filterTeamTypeId
);
