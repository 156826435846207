import PositionTypesActionTypes from './types';

/* ================================================================ */
/*  Process Start  	                                                */
/* ================================================================ */

export const fetchPositionTypesStart = () => ({
	type: PositionTypesActionTypes.FETCH_POSITION_TYPES_START,
});

export const setPositionTypesIsFetching = () => ({
	type: PositionTypesActionTypes.SET_POSITION_TYPES_IS_FETCHING,
});

export const clearPositionTypesReducer = () => ({
	type: PositionTypesActionTypes.CLEAR_POSITION_TYPES_REDUCER,
});

/* ================================================================ */
/*  Process Success	                                                */
/* ================================================================ */

export const fetchPositionTypesSuccess = positionTypes => ({
	type: PositionTypesActionTypes.FETCH_POSITION_TYPES_SUCCESS,
	payload: positionTypes,
});

/* ================================================================ */
/*  Process Failure	       	                                        */
/* ================================================================ */

export const fetchPositionTypesFailure = errorMessage => ({
	type: PositionTypesActionTypes.FETCH_POSITION_TYPES_FAILURE,
	payload: errorMessage,
});
