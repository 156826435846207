import styled, { css } from 'styled-components';
import { colorStyles } from '../../../styles/global.styles';

export const CheckboxBlock = styled.label`
	display: flex;
	align-items: center;
	height: 22px;
	padding-left: 36px;
	position: relative;
	cursor: pointer;

	input {
		position: absolute;
		opacity: 0;
		height: 0;
		width: 0;
		cursor: pointer;
	}

	span {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		background: none;
		border: 1px solid #c6cacf;
		height: 22px;
		width: 22px;
		position: absolute;
		top: 0;
		left: 0;
		transition: all 0.2 ease;

		${props =>
			props.isChecked &&
			css`
				background-color: ${colorStyles.primary};
				border: none;
				height: 22px;
				width: 22px;
			`}

		svg {
			height: 14px;
			width: 14px;
			display: ${props => (props.isChecked ? 'block' : 'none')};

			path {
				stroke: ${colorStyles.greyScale9};
			}
		}
	}
`;
