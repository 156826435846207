import { compose } from 'redux';
import { useHistory, useRouteMatch } from 'react-router';

import { FilterBlock2 } from '../../../components/molecule/table/Table.styles';
import { Dropdown } from '../../../components/atom/dropdown/Dropdown.styles';
import { IconButton } from '../../../components/atom/buttons/Button.styles';
import { ReactComponent as PlusIcon } from '../../../assets/plus.svg';

import { WithReduxMatches } from '../../../components/hoc/WithReduxMatches.hoc';
import { WithReduxMyTeams } from '../../../components/hoc/WithReduxMyTeams.hoc';

const ListFilter = ({
	matchesFilterTeamId,
	setMatchesFilterTeamId,
	myTeams,
	clearMatchesFilterTeamId,
	newSingleMatchStart,
}) => {
	const history = useHistory();
	const { url } = useRouteMatch();

	const handlDropdown = event => {
		const value = event.target.value;
		value
			? setMatchesFilterTeamId(parseInt(value, 10))
			: clearMatchesFilterTeamId();
	};

	return (
		<FilterBlock2>
			<Dropdown
				size='260px'
				value={matchesFilterTeamId}
				onChange={handlDropdown}
			>
				<option value=''>- Filter by Teams -</option>
				{myTeams.map((team, index) => (
					<option key={index} value={team.id}>
						{team.name}
					</option>
				))}
			</Dropdown>
			<IconButton
				onClick={() => {
					history.push(`${url}/new-match`);
					newSingleMatchStart();
				}}
			>
				<PlusIcon />
				New Match
			</IconButton>
		</FilterBlock2>
	);
};

export default compose(WithReduxMatches, WithReduxMyTeams)(ListFilter);
