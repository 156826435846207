import { MatchesTypes } from './types';

const INITIAL_STATE = {
	matches: [],
	filteredMatches: [],
	filterTeamId: '',
	isEdit: false,
	isFetching: false,
	errorMessage: '',
};

const matchesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* ================================================================ */
		/*  Process Start                                                   */
		/* ================================================================ */
		case MatchesTypes.FETCH_MATCHES_START:
		case MatchesTypes.FETCH_FILTERED_PLAYERS_START:
			return {
				...state,
				isFetching: true,
			};
		case MatchesTypes.SET_FILTERED_MATCHES:
			return {
				...state,
				filteredMatches: action.payload,
			};
		case MatchesTypes.SET_MATCHES_FILTER_TEAM_ID:
			return {
				...state,
				filterTeamId: action.payload,
			};
		case MatchesTypes.SET_MATCHES_IS_EDIT:
			return {
				...state,
				isEdit: action.payload,
			};
		case MatchesTypes.CLEAR_MATCHES_FILTER_TEAM_ID:
			return {
				...state,
				filterTeamId: '',
				filteredMatches: state.matches,
			};
		case MatchesTypes.CLEAR_MATCHES_REDUCER:
			return {
				...state,
				matches: [],
				filteredMatches: [],
				filterTeamId: '',
				isEdit: false,
				isFetching: false,
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Success                                                 */
		/* ================================================================ */
		case MatchesTypes.FETCH_MATCHES_SUCCESS:
			return {
				...state,
				matches: action.payload,
				isFetching: false,
			};
		/* ================================================================ */
		/*  Process Failure                                                 */
		/* ================================================================ */
		case MatchesTypes.FETCH_MATCHES_FAILURE:
			return {
				...state,
				errorMessage: action.payload,
				isFetching: false,
			};
		default:
			return state;
	}
};

export default matchesReducer;
