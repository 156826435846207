export const HOME = '/';
export const MATCHES = 'matches';
export const MY_TEAMS = 'my-teams';
export const PLAYERS = 'players';
export const OPPOSITIONS = 'oppositions';
export const COMPETITION = 'competition';
export const FILES = 'files';

export const BASEAPI = 'https://api-kjz34l7r4a-nw.a.run.app/v1';
export const HEADERS = {
	headers: {
		Authorization: `Bearer <TOKEN>`,
	},
};
