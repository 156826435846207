import { compose } from 'redux';

import {
	AddPlayerBlock,
	PlayerBlock,
	PlayerItem,
	PlayersListGrid,
} from '../MatchEdit.styles';
import { IconButton } from '../../../../components/atom/buttons/Button.styles';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';
import { colorStyles } from '../../../../styles/global.styles';

import { WithReduxMatches } from '../../../../components/hoc/WithReduxMatches.hoc';

const PlayersSection = ({
	filterMatchPlayersStart,
	playersData,
	fetchSinglePlayerDataStart,
	removeSingleMatchPlayerDataStart,
}) => {
	return (
		<PlayerBlock>
			<AddPlayerBlock>
				<div className='label'>Players:</div>
				<IconButton onClick={filterMatchPlayersStart}>
					<PlusIcon />
					Add Player
				</IconButton>
			</AddPlayerBlock>
			<PlayersListGrid>
				{playersData.map((player, index) => (
					<PlayerItem key={index}>
						<div
							className='player-name'
							onClick={() => fetchSinglePlayerDataStart(index)}
						>
							{player.playerFullname}
						</div>
						<IconButton
							round
							color={colorStyles.error}
							onClick={() =>
								removeSingleMatchPlayerDataStart(
									player.playerId
								)
							}
						>
							<CloseIcon />
						</IconButton>
					</PlayerItem>
				))}
			</PlayersListGrid>
		</PlayerBlock>
	);
};

export default compose(WithReduxMatches)(PlayersSection);
