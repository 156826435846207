export const SinglePlayerTypes = {
	FETCH_SINGLE_PLAYER_START: 'FETCH_SINGLE_PLAYER_START',
	FETCH_SINGLE_PLAYER_SUCCESS: 'FETCH_SINGLE_PLAYER_SUCCESS',
	FETCH_SINGLE_PLAYER_FAILURE: 'FETCH_SINGLE_PLAYER_FAILURE',

	NEW_SINGLE_PLAYER_START: 'NEW_SINGLE_PLAYER_START',
	NEW_SINGLE_PLAYER_SUCCESS: 'NEW_SINGLE_PLAYER_SUCCESS',
	NEW_SINGLE_PLAYER_FAILURE: 'NEW_SINGLE_PLAYER_FAILURE',

	CREATE_SINGLE_PLAYER_START: 'CREATE_SINGLE_PLAYER_START',
	CREATE_SINGLE_PLAYER_SUCCESS: 'CREATE_SINGLE_PLAYER_SUCCESS',
	CREATE_SINGLE_PLAYER_FAILURE: 'CREATE_SINGLE_PLAYER_FAILURE',

	UPDATE_SINGLE_PLAYER_START: 'UPDATE_SINGLE_PLAYER_START',
	UPDATE_SINGLE_PLAYER_SUCCESS: 'UPDATE_SINGLE_PLAYER_SUCCESS',
	UPDATE_SINGLE_PLAYER_FAILURE: 'UPDATE_SINGLE_PLAYER_FAILURE',

	SET_SINGLE_PLAYER_IS_TEAMS_START: 'SET_SINGLE_PLAYER_IS_TEAMS_START',
	SET_SINGLE_PLAYER_IS_TEAMS_SUCCESS: 'SET_SINGLE_PLAYER_IS_TEAMS_SUCCESS',
	SET_SINGLE_PLAYER_IS_TEAMS_FAILURE: 'SET_SINGLE_PLAYER_IS_TEAMS_FAILURE',

	SET_SINGLE_PLAYER_FIRSTNAME: 'SET_SINGLE_PLAYER_FIRSTNAME',
	SET_SINGLE_PLAYER_LASTNAME: 'SET_SINGLE_PLAYER_LASTNAME',
	SET_SINGLE_PLAYER_IS_ACTIVE: 'SET_SINGLE_PLAYER_IS_ACTIVE',
	SET_SINGLE_PLAYER_DATE_OF_BIRTH: 'SET_SINGLE_PLAYER_DATE_OF_BIRTH',
	SET_SINGLE_PLAYER_TAG_NAME: 'SET_SINGLE_PLAYER_TAG_NAME',
	SET_SINGLE_PLAYER_ALTERNATE_TAG_NAME:
		'SET_SINGLE_PLAYER_ALTERNATE_TAG_NAME',
	SET_SINGLE_PLAYER_MONITOR_NAME: 'SET_SINGLE_PLAYER_MONITOR_NAME',
	SET_SINGLE_PLAYER_POSITION_TYPE_ID: 'SET_SINGLE_PLAYER_POSITION_TYPE_ID',

	SET_SINGLE_PLAYER_TEAMS: 'SET_SINGLE_PLAYER_TEAMS',

	CLEAR_SINGLE_PLAYER: 'CLEAR_SINGLE_PLAYER',
};
