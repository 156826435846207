import { SingleOppositionTypes } from './types';

/* ================================================================ */
/*  Process Start  	                                                */
/* ================================================================ */

export const fetchSingleOppositionStart = id => ({
	type: SingleOppositionTypes.FETCH_SINGLE_OPPOSITION_START,
	payload: id,
});

export const setOppositionName = name => ({
	type: SingleOppositionTypes.SET_SINGLE_OPPOSITION_NAME,
	payload: name,
	inputName: 'name',
});

export const setOppositionCode = code => ({
	type: SingleOppositionTypes.SET_SINGLE_OPPOSITION_CODE,
	payload: code,
	inputName: 'code',
});

export const setOppositionCountryId = id => ({
	type: SingleOppositionTypes.SET_SINGLE_OPPOSITION_COUNTRY_ID,
	payload: id,
	inputName: 'countryId',
});

export const setOppositionTeamTypeId = id => ({
	type: SingleOppositionTypes.SET_SINGLE_OPPOSITION_TEAM_TYPE_ID,
	payload: id,
	inputName: 'teamTypeId',
});

export const setOppositionIsActive = bool => ({
	type: SingleOppositionTypes.SET_SINGLE_OPPOSITION_IS_ACTIVE,
	payload: bool,
	inputName: 'isActive',
});

export const createSingleOppositionStart = () => ({
	type: SingleOppositionTypes.CREATE_SINGLE_OPPOSITION_START,
});

export const updateSingleOppositionStart = () => ({
	type: SingleOppositionTypes.UPDATE_SINGLE_OPPOSITION_START,
});

export const clearSingleOpposition = () => ({
	type: SingleOppositionTypes.CLEAR_SINGLE_OPPOSITION,
});

/* ================================================================ */
/*  Process Success	                                                */
/* ================================================================ */

export const fetchSingleOppositionSuccess = opposition => ({
	type: SingleOppositionTypes.FETCH_SINGLE_OPPOSITION_SUCCESS,
	payload: opposition,
});

export const createSingleOppositionSuccess = opposition => ({
	type: SingleOppositionTypes.CREATE_SINGLE_OPPOSITION_SUCCESS,
	payload: opposition,
});

export const updateSingleOppositionSuccess = opposition => ({
	type: SingleOppositionTypes.UPDATE_SINGLE_OPPOSITION_SUCCESS,
	payload: opposition,
});

/* ================================================================ */
/*  Process Failure	       	                                        */
/* ================================================================ */

export const fetchSingleOppositionFailure = errorMessage => ({
	type: SingleOppositionTypes.FETCH_SINGLE_OPPOSITION_FAILURE,
	payload: errorMessage,
});

export const createSingleOppositionFailure = errorMessage => ({
	type: SingleOppositionTypes.CREATE_SINGLE_OPPOSITION_FAILURE,
	payload: errorMessage,
});

export const updateSingleOppositionFailure = errorMessage => ({
	type: SingleOppositionTypes.UPDATE_SINGLE_OPPOSITION_FAILURE,
	payload: errorMessage,
});
