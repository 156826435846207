import { Fragment, useEffect } from 'react';
import { compose } from 'redux';

import ListFilter from './components/ListFilter.component';
import List from './components/List.component';
import EditPopup from './components/EditPopup.component';
import { Box } from '../../components/atom/containers/Box.styles';
import { LoadingList } from '../../components/atom/loader/Loader.component';

import { WithReduxOppositions } from '../../components/hoc/WithReduxOppositions.hoc';

const OppositionsList = ({
	fetchOppositionsStart,
	oppositionsIsEdit,
	oppositionsIsFetching,
}) => {
	useEffect(() => {
		fetchOppositionsStart();
	}, [fetchOppositionsStart]);

	return (
		<Fragment>
			{!oppositionsIsFetching ? (
				<Box padding='48px'>
					<ListFilter />
					<List />
				</Box>
			) : (
				<LoadingList />
			)}
			{oppositionsIsEdit && <EditPopup />}
		</Fragment>
	);
};

export default compose(WithReduxOppositions)(OppositionsList);
