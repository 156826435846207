import TeamTypesActionTypes from './types';

const INITIAL_STATE = {
	teamTypes: [],
	isFetching: false,
	errorMessage: '',
};

const teamTypesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* ================================================================ */
		/*  Process Start                                                   */
		/* ================================================================ */
		case TeamTypesActionTypes.FETCH_TEAM_TYPES_START:
			return {
				...state,
				isFetching: true,
			};
		case TeamTypesActionTypes.CLEAR_TEAM_TYPES_REDUCER:
			return {
				...state,
				teamTypes: [],
				isFetching: false,
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Success                                                 */
		/* ================================================================ */
		case TeamTypesActionTypes.FETCH_TEAM_TYPES_SUCCESS:
			return {
				...state,
				teamTypes: action.payload,
				isFetching: false,
			};
		/* ================================================================ */
		/*  Process Failure                                                 */
		/* ================================================================ */
		case TeamTypesActionTypes.FETCH_TEAM_TYPES_FAILURE:
			return {
				...state,
				errorMessage: action.payload,
				isFetching: false,
			};
		default:
			return state;
	}
};

export default teamTypesReducer;
