import { CompetitionTypes } from './types';

const INITIAL_STATE = {
	competition: [],
	filteredCompetitions: [],
	filterTeamTypeId: '',
	isEdit: false,
	isFetching: false,
	errorMessage: '',
};

const competitionReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* ================================================================ */
		/*  Process Start                                                   */
		/* ================================================================ */
		case CompetitionTypes.FETCH_COMPETITION_START:
		case CompetitionTypes.SET_COMPETITION_IS_FETCHING:
			return {
				...state,
				isFetching: true,
			};
		case CompetitionTypes.SET_COMPETITION_IS_EDIT:
			return {
				...state,
				isEdit: action.payload,
			};
		case CompetitionTypes.SET_FILTERED_COMPETITIONS:
			return {
				...state,
				filteredCompetitions: action.payload,
			};
		case CompetitionTypes.SET_COMPETITION_FILTER_TEAM_TYPE_ID:
			return {
				...state,
				filterTeamTypeId: action.payload,
			};
		case CompetitionTypes.CLEAR_COMPETITION_FILTER_TEAM_TYPE_ID:
			return {
				...state,
				filterTeamTypeId: '',
				filteredCompetitions: state.competition,
			};
		case CompetitionTypes.CLEAR_COMPETITION_REDUCER:
			return {
				...state,
				competition: [],
				filteredCompetitions: [],
				filterTeamTypeId: '',
				isEdit: false,
				isFetching: false,
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Success                                                 */
		/* ================================================================ */
		case CompetitionTypes.FETCH_COMPETITION_SUCCESS:
			return {
				...state,
				competition: action.payload,
				isFetching: false,
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Failure                                                 */
		/* ================================================================ */
		case CompetitionTypes.FETCH_COMPETITION_FAILURE:
			return {
				...state,
				errorMessage: action.payload,
				isFetching: false,
			};
		default:
			return state;
	}
};

export default competitionReducer;
