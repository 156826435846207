import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// Redux: Selectors
import {
	selectMatches,
	selectMatchesIsFetching,
	selectMatchesIsEdit,
	selectFilteredMatches,
	selectMatchesFilterTeamId,
} from '../../pages/matches/redux/selectors';
import {
	selectSingleMatch,
	selectSingleMatchIsSingleFetching,
	selectFilteredPlayers,
	selectNewFilteredPlayers,
	selectPlayersData,
	selectSinglePlayerData,
	selectMatchesXmlFile,
} from '../../pages/matches/edit-page/redux/selectors';

// Redux: Actions
import {
	fetchMatchesStart,
	setMatchesIsEdit,
	setMatchesFilterTeamId,
	clearMatchesFilterTeamId,
} from '../../pages/matches/redux/actions';
import {
	fetchSingleMatchStart,
	newSingleMatchStart,
	createSingleMatchStart,
	updateSingleMatchStart,
	fetchSinglePlayerDataStart,
	updateSinglePlayerDataStart,
	addSinglePlayerDataStart,
	removeSingleMatchPlayerDataStart,
	filterMatchPlayersStart,
	setSingleMatchDate,
	setSingleMatchTeamScore,
	setSingleMatchOppositionScore,
	setSingleMatchIsActive,
	setSingleMatchTeamId,
	setSingleMatchOpppositionId,
	setSingleMatchCompetitionId,
	setSingleMatchXmlFile,
	setSinglePlayerDataPlayerId,
	setSinglePlayerDataPositionTypeId,
	setSinglePlayerDataIsCaptain,
	setSinglePlayerDataWasSubstituted,
	setSinglePlayerDataIsSubstituted,
	setSinglePlayerDataStartingMinutes,
	setSinglePlayerDataEndingMinutes,
	clearSingleMatchXmlFile,
	clearSingleMatchReducer,
} from '../../pages/matches/edit-page/redux/actions';

export const WithReduxMatches = WrappedComponent => {
	// Add Redux actions and state to the WrappedComponent
	const WithData = props => {
		return <WrappedComponent {...props} />;
	};

	// Redux: Selectors
	const mapStateToProps = createStructuredSelector({
		matches: selectMatches,
		filteredMatches: selectFilteredMatches,
		newFilteredMatches: selectNewFilteredPlayers,
		matchesFilterTeamId: selectMatchesFilterTeamId,
		singleMatch: selectSingleMatch,
		filteredPlayers: selectFilteredPlayers,
		playersData: selectPlayersData,
		singlePlayerData: selectSinglePlayerData,
		matchesIsFetching: selectMatchesIsFetching,
		matchesIsSingleFetching: selectSingleMatchIsSingleFetching,
		matchesIsEdit: selectMatchesIsEdit,
		xmlFile: selectMatchesXmlFile,
	});

	// Redux: Actions
	const mapDispatchToProps = dispatch => ({
		// Start Actions
		fetchMatchesStart: () => dispatch(fetchMatchesStart()),
		fetchSingleMatchStart: id => dispatch(fetchSingleMatchStart(id)),
		fetchSinglePlayerDataStart: index =>
			dispatch(fetchSinglePlayerDataStart(index)),
		newSingleMatchStart: () => dispatch(newSingleMatchStart()),
		createSingleMatchStart: () => dispatch(createSingleMatchStart()),
		updateSingleMatchStart: () => dispatch(updateSingleMatchStart()),
		updateSinglePlayerDataStart: () =>
			dispatch(updateSinglePlayerDataStart()),
		filterMatchPlayersStart: () => dispatch(filterMatchPlayersStart()),
		addSinglePlayerDataStart: () => dispatch(addSinglePlayerDataStart()),

		// Set Actions
		setMatchesIsEdit: bool => dispatch(setMatchesIsEdit(bool)),
		setMatchesFilterTeamId: id => dispatch(setMatchesFilterTeamId(id)),
		setSinglePlayerDataPlayerId: id =>
			dispatch(setSinglePlayerDataPlayerId(id)),
		setSinglePlayerDataPositionTypeId: id =>
			dispatch(setSinglePlayerDataPositionTypeId(id)),
		setSinglePlayerDataIsCaptain: bool =>
			dispatch(setSinglePlayerDataIsCaptain(bool)),
		setSinglePlayerDataWasSubstituted: bool =>
			dispatch(setSinglePlayerDataWasSubstituted(bool)),
		setSinglePlayerDataIsSubstituted: bool =>
			dispatch(setSinglePlayerDataIsSubstituted(bool)),
		setSinglePlayerDataStartingMinutes: minutes =>
			dispatch(setSinglePlayerDataStartingMinutes(minutes)),
		setSinglePlayerDataEndingMinutes: minutes =>
			dispatch(setSinglePlayerDataEndingMinutes(minutes)),
		setSingleMatchDate: date => dispatch(setSingleMatchDate(date)),
		setSingleMatchTeamScore: score =>
			dispatch(setSingleMatchTeamScore(score)),
		setSingleMatchOppositionScore: score =>
			dispatch(setSingleMatchOppositionScore(score)),
		setSingleMatchIsActive: bool => dispatch(setSingleMatchIsActive(bool)),
		setSingleMatchTeamId: id => dispatch(setSingleMatchTeamId(id)),
		setSingleMatchOpppositionId: id =>
			dispatch(setSingleMatchOpppositionId(id)),
		setSingleMatchCompetitionId: id =>
			dispatch(setSingleMatchCompetitionId(id)),
		setSingleMatchXmlFile: file => dispatch(setSingleMatchXmlFile(file)),

		// Remove Actions
		clearMatchesFilterTeamId: () => dispatch(clearMatchesFilterTeamId()),
		clearSingleMatchXmlFile: () => dispatch(clearSingleMatchXmlFile()),
		clearSingleMatchReducer: () => dispatch(clearSingleMatchReducer()),
		removeSingleMatchPlayerDataStart: id =>
			dispatch(removeSingleMatchPlayerDataStart(id)),
	});

	return connect(mapStateToProps, mapDispatchToProps)(WithData);
};
