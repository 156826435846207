import { compose } from 'redux';

import { FilterBlock2 } from '../../../components/molecule/table/Table.styles';
import { Dropdown } from '../../../components/atom/dropdown/Dropdown.styles';
import { IconButton } from '../../../components/atom/buttons/Button.styles';
import { ReactComponent as PlusIcon } from '../../../assets/plus.svg';

import { WithReduxCompetition } from '../../../components/hoc/WithReduxCompetition.hoc';
import { WithReduxTeamTypes } from '../../../components/hoc/WithReduxTeamTypes.hoc';

const ListFilter = ({
	competitionFilterTeamTypeId,
	setCompetitionFilterTeamTypeId,
	teamTypes,
	clearCompetitionFilterTeamTypeId,
	clearSingleCompetition,
	setCompetitionIsEdit,
}) => {
	const handlDropdown = event => {
		const value = event.target.value;
		value
			? setCompetitionFilterTeamTypeId(parseInt(value, 10))
			: clearCompetitionFilterTeamTypeId();
	};

	return (
		<FilterBlock2>
			<Dropdown
				size='260px'
				value={competitionFilterTeamTypeId}
				onChange={handlDropdown}
			>
				<option value=''>- Filter by Types -</option>
				{teamTypes.map((teamtype, index) => (
					<option key={index} value={teamtype.id}>
						{teamtype.label}
					</option>
				))}
			</Dropdown>
			<IconButton
				onClick={() => {
					clearSingleCompetition();
					setCompetitionIsEdit(true);
				}}
			>
				<PlusIcon />
				new competition
			</IconButton>
		</FilterBlock2>
	);
};

export default compose(WithReduxCompetition, WithReduxTeamTypes)(ListFilter);
