import { createSelector } from 'reselect';

const selectSinglePlayerStore = state => state.singlePlayer;

// Selects singlePlayer.singlePlayer from the state
export const selectSinglePlayer = createSelector(
	[selectSinglePlayerStore],
	singlePlayer => singlePlayer.singlePlayer
);

// Selects singlePlayer.teams from the state
export const selectSinglePlayerTeams = createSelector(
	[selectSinglePlayerStore],
	singlePlayer => singlePlayer.teams
);

// Selects singlePlayer.isSingleFetching from the state
export const selectPlayerSingleIsFetching = createSelector(
	[selectSinglePlayerStore],
	singlePlayer => singlePlayer.isSingleFetching
);
