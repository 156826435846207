export const FilesTypes = {
	FETCH_FILES_START: 'FETCH_FILES_START',
	FETCH_FILES_SUCCESS: 'FETCH_FILES_SUCCESS',
	FETCH_FILES_FAILURE: 'FETCH_FILES_FAILURE',

	FETCH_FILES_FROM_FOLDER_START: 'FETCH_FILES_FROM_FOLDER_START',
	FETCH_FILES_FROM_FOLDER_SUCCESS: 'FETCH_FILES_FROM_FOLDER_SUCCESS',
	FETCH_FILES_FROM_FOLDER_FAILURE: 'FETCH_FILES_FROM_FOLDER_FAILURE',

	FETCH_FILES_FROM_BACK_START: 'FETCH_FILES_FROM_BACK_START',
	FETCH_FILES_FROM_BACK_SUCCESS: 'FETCH_FILES_FROM_BACK_SUCCESS',
	FETCH_FILES_FROM_BACK_FAILURE: 'FETCH_FILES_FROM_BACK_FAILURE',

	CLEAR_FILES_REDUCER: 'CLEAR_FILES_REDUCER',
};
