import { createSelector } from 'reselect';

const selectSingleCompetitionStore = state => state.singleCompetition;

// Selects singleCompetition.singleCompetition from the state
export const selectSingleCompetition = createSelector(
	[selectSingleCompetitionStore],
	singleCompetition => singleCompetition.singleCompetition
);

// Selects singleCompetition.isSingleFetching from the state
export const selectSingleCompetitionIsSingleFetching = createSelector(
	[selectSingleCompetitionStore],
	singleCompetition => singleCompetition.isSingleFetching
);
