import { CompetitionTypes } from './types';

/* ================================================================ */
/*  Process Start  	                                                */
/* ================================================================ */

export const fetchCompetitionStart = () => ({
	type: CompetitionTypes.FETCH_COMPETITION_START,
});

export const setCompetitionIsFetching = () => ({
	type: CompetitionTypes.SET_COMPETITION_IS_FETCHING,
});

export const setFilteredCompetitions = competitions => ({
	type: CompetitionTypes.SET_FILTERED_COMPETITIONS,
	payload: competitions,
});

export const setCompetitionIsEdit = bool => ({
	type: CompetitionTypes.SET_COMPETITION_IS_EDIT,
	payload: bool,
});

export const setCompetitionFilterTeamTypeId = id => ({
	type: CompetitionTypes.SET_COMPETITION_FILTER_TEAM_TYPE_ID,
	payload: id,
});

export const clearCompetitionFilterTeamTypeId = () => ({
	type: CompetitionTypes.CLEAR_COMPETITION_FILTER_TEAM_TYPE_ID,
});

export const clearCompetitionReducer = () => ({
	type: CompetitionTypes.CLEAR_COMPETITION_REDUCER,
});

/* ================================================================ */
/*  Process Success	                                                */
/* ================================================================ */

export const fetchCompetitionSuccess = competition => ({
	type: CompetitionTypes.FETCH_COMPETITION_SUCCESS,
	payload: competition,
});

/* ================================================================ */
/*  Process Failure	       	                                        */
/* ================================================================ */

export const fetchCompetitionFailure = errorMessage => ({
	type: CompetitionTypes.FETCH_COMPETITION_FAILURE,
	payload: errorMessage,
});
