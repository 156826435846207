import { Fragment } from 'react';
import { compose } from 'redux';

import {
	Table2,
	TableData2,
	TableDataChip2,
	TableHeader2,
	Chip,
} from '../../../components/molecule/table/Table.styles';

import { WithReduxMyTeams } from '../../../components/hoc/WithReduxMyTeams.hoc';

const List = ({ myTeams, fetchSingleMyTeamStart }) => {
	return (
		<Table2 gridColumns='1fr 1fr 0.6fr'>
			<TableHeader2>My Teams Name</TableHeader2>
			<TableHeader2>My Teams Type</TableHeader2>
			<TableHeader2>Is Active</TableHeader2>
			{myTeams.map((myTeam, index) => (
				<Fragment key={index}>
					<TableData2
						onClick={() => fetchSingleMyTeamStart(myTeam.id)}
					>
						{myTeam.name}
					</TableData2>
					<TableData2
						onClick={() => fetchSingleMyTeamStart(myTeam.id)}
					>
						{myTeam.teamTypeLabel}
					</TableData2>
					<TableDataChip2
						onClick={() => fetchSingleMyTeamStart(myTeam.id)}
					>
						<Chip isActive={myTeam.isActive}>
							{myTeam.isActive ? 'Active' : 'Inactive'}
						</Chip>
					</TableDataChip2>
				</Fragment>
			))}
		</Table2>
	);
};

export default compose(WithReduxMyTeams)(List);
