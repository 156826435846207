import styled from 'styled-components';
import { colorStyles } from '../../../styles/global.styles';

export const AvatarInitial = styled.div`
	display: flex;
	width: 40px;
	height: 40px;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	${colorStyles.primaryBgTransparancy(0.1)}
	color: #0054a6;
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
`;
