import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import { getCurrentUserToken } from '../../firebase/firebase.utils';
import { BASEAPI } from '../../routes/routes';

// Types
import TeamTypesActionTypes from './types';

// Actions
import { fetchTeamTypesSuccess, fetchTeamTypesFailure } from './actions';

/* ================================================================ */
/*  Actions                                                         */
/* ================================================================ */

export function* fetchTeamTypesStart() {
	try {
		const token = yield call(getCurrentUserToken);

		const { data: teamTypes } = yield call(
			axios.get,
			`${BASEAPI}/team-types`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		yield put(fetchTeamTypesSuccess(teamTypes));
	} catch (error) {
		console.error(error);
		yield put(fetchTeamTypesFailure(error));
	}
}

/* ================================================================ */
/*  Listeners                                                       */
/* ================================================================ */

export function* onFetchTeamTypesStart() {
	yield takeLatest(
		TeamTypesActionTypes.FETCH_TEAM_TYPES_START,
		fetchTeamTypesStart
	);
}

/* ================================================================ */
/*  Root Saga                                                       */
/* ================================================================ */

export default function* teamTypesSagas() {
	yield all([call(onFetchTeamTypesStart)]);
}
