import { SingleMatchTypes } from './types';

/* ================================================================ */
/*  Process Start  	                                                */
/* ================================================================ */

export const fetchSingleMatchStart = id => ({
	type: SingleMatchTypes.FETCH_SINGLE_MATCH_START,
	payload: id,
});

export const newSingleMatchStart = () => ({
	type: SingleMatchTypes.NEW_SINGLE_MATCH_START,
});

export const createSingleMatchStart = () => ({
	type: SingleMatchTypes.CREATE_SINGLE_MATCH_START,
});

export const updateSingleMatchStart = () => ({
	type: SingleMatchTypes.UPDATE_SINGLE_MATCH_START,
});

export const fetchSinglePlayerDataStart = index => ({
	type: SingleMatchTypes.FETCH_SINGLE_PLAYER_DATA_START,
	payload: index,
});

export const updateSinglePlayerDataStart = () => ({
	type: SingleMatchTypes.UPDATE_SINGLE_PLAYER_DATA_START,
});

export const addSinglePlayerDataStart = () => ({
	type: SingleMatchTypes.ADD_SINGLE_PLAYER_DATA_START,
});

export const removeSingleMatchPlayerDataStart = id => ({
	type: SingleMatchTypes.REMOVE_SINGLE_MATCH_PLAYER_DATA_START,
	payload: id,
});

export const filterMatchPlayersStart = () => ({
	type: SingleMatchTypes.FILTER_MATCH_PLAYERS_START,
});

export const setMatchFilteredPlayers = filteredPlayers => ({
	type: SingleMatchTypes.SET_MATCH_FILTERED_PLAYERS,
	payload: filteredPlayers,
});

export const setMatchPlayersData = playersData => ({
	type: SingleMatchTypes.SET_MATCH_PLAYERS_DATA,
	payload: playersData,
});

export const setMatchSinglePlayerData = singlePlayerData => ({
	type: SingleMatchTypes.SET_MATCH_SINGLE_PLAYER_DATA,
	payload: singlePlayerData,
});

export const setSingleMatchDate = date => ({
	type: SingleMatchTypes.SET_SINGLE_MATCH_DATE,
	payload: date,
	inputName: 'date',
});

export const setSingleMatchTeamScore = score => ({
	type: SingleMatchTypes.SET_SINGLE_MATCH_TEAM_SCORE,
	payload: score,
	inputName: 'teamScore',
});

export const setSingleMatchOppositionScore = score => ({
	type: SingleMatchTypes.SET_SINGLE_MATCH_OPPOSITION_SCORE,
	payload: score,
	inputName: 'oppositionScore',
});

export const setSingleMatchIsActive = bool => ({
	type: SingleMatchTypes.SET_SINGLE_MATCH_IS_ACTIVE,
	payload: bool,
	inputName: 'isActive',
});

export const setSingleMatchTeamId = id => ({
	type: SingleMatchTypes.SET_SINGLE_MATCH_TEAM_ID,
	payload: id,
	inputName: 'teamId',
});

export const setSingleMatchOpppositionId = id => ({
	type: SingleMatchTypes.SET_SINGLE_MATCH_OPPOSITION_ID,
	payload: id,
	inputName: 'oppositionId',
});

export const setSingleMatchCompetitionId = id => ({
	type: SingleMatchTypes.SET_SINGLE_MATCH_COMPETITION_ID,
	payload: id,
	inputName: 'competitionId',
});

export const setSinglePlayerDataPlayerId = id => ({
	type: SingleMatchTypes.SET_SINGLE_PLAYER_DATA_PLAYER_ID,
	payload: id,
	inputName: 'playerId',
});

export const setSinglePlayerDataPositionTypeId = id => ({
	type: SingleMatchTypes.SET_SINGLE_PLAYER_DATA_POSITION_TYPE_ID,
	payload: id,
	inputName: 'positionTypeId',
});

export const setSinglePlayerDataIsCaptain = bool => ({
	type: SingleMatchTypes.SET_SINGLE_PLAYER_DATA_IS_CAPTAIN,
	payload: bool,
	inputName: 'isCaptain',
});

export const setSinglePlayerDataWasSubstituted = bool => ({
	type: SingleMatchTypes.SET_SINGLE_PLAYER_DATA_WAS_SUBSTITUTED,
	payload: bool,
	inputName: 'wasSubstituted',
});

export const setSinglePlayerDataIsSubstituted = bool => ({
	type: SingleMatchTypes.SET_SINGLE_PLAYER_DATA_IS_SUBSTITUTED,
	payload: bool,
	inputName: 'isSubstituted',
});

export const setSinglePlayerDataStartingMinutes = minutes => ({
	type: SingleMatchTypes.SET_SINGLE_PLAYER_DATA_STARTING_MINUTES,
	payload: minutes,
	inputName: 'startingMinutes',
});

export const setSinglePlayerDataEndingMinutes = minutes => ({
	type: SingleMatchTypes.SET_SINGLE_PLAYER_DATA_ENDING_MINUTES,
	payload: minutes,
	inputName: 'endingMinutes',
});

export const setSingleMatchXmlFile = file => ({
	type: SingleMatchTypes.SET_SINGLE_MATCH_XML_FILE,
	payload: file,
});

export const setSingleMatchIsFetching = () => ({
	type: SingleMatchTypes.SET_SINGLE_MATCH_IS_FETCHING,
});

export const clearSinglePlayerData = () => ({
	type: SingleMatchTypes.CLEAR_SINGLE_PLAYER_DATA,
});

export const clearSingleMatchXmlFile = () => ({
	type: SingleMatchTypes.CLEAR_SINGLE_MATCH_XML_FILE,
});

export const clearSingleMatchReducer = () => ({
	type: SingleMatchTypes.CLEAR_SINGLE_MATCH_REDUCER,
});

/* ================================================================ */
/*  Process Success	                                                */
/* ================================================================ */

export const fetchSingleMatchSuccess = match => ({
	type: SingleMatchTypes.FETCH_SINGLE_MATCH_SUCCESS,
	payload: match,
});

export const newSingleMatchSuccess = match => ({
	type: SingleMatchTypes.NEW_SINGLE_MATCH_SUCCESS,
	payload: match,
});

export const createSingleMatchSuccess = match => ({
	type: SingleMatchTypes.CREATE_SINGLE_MATCH_SUCCESS,
	payload: match,
});

export const updateSingleMatchSuccess = match => ({
	type: SingleMatchTypes.UPDATE_SINGLE_MATCH_SUCCESS,
	payload: match,
});

export const fetchSinglePlayerDataSuccess = singlePlayerData => ({
	type: SingleMatchTypes.FETCH_SINGLE_PLAYER_DATA_SUCCESS,
	payload: singlePlayerData,
});

export const updateSinglePlayerDataSuccess = playersData => ({
	type: SingleMatchTypes.UPDATE_SINGLE_PLAYER_DATA_SUCCESS,
	payload: playersData,
});

export const addSinglePlayerDataSuccess = playersData => ({
	type: SingleMatchTypes.ADD_SINGLE_PLAYER_DATA_SUCCESS,
	payload: playersData,
});

export const removeSingleMatchPlayerDataSuccess = playersData => ({
	type: SingleMatchTypes.REMOVE_SINGLE_MATCH_PLAYER_DATA_SUCCESS,
	payload: playersData,
});

export const filterMatchPlayersSuccess = filteredPlayers => ({
	type: SingleMatchTypes.FILTER_MATCH_PLAYERS_SUCCESS,
	payload: filteredPlayers,
});

/* ================================================================ */
/*  Process Failure	       	                                        */
/* ================================================================ */

export const fetchSingleMatchFailure = errorMessage => ({
	type: SingleMatchTypes.FETCH_SINGLE_MATCH_FAILURE,
	payload: errorMessage,
});

export const newSingleMatchFailure = errorMessage => ({
	type: SingleMatchTypes.NEW_SINGLE_MATCH_FAILURE,
	payload: errorMessage,
});

export const createSingleMatchFailure = errorMessage => ({
	type: SingleMatchTypes.CREATE_SINGLE_MATCH_FAILURE,
	payload: errorMessage,
});

export const updateSingleMatchFailure = errorMessage => ({
	type: SingleMatchTypes.UPDATE_SINGLE_MATCH_FAILURE,
	payload: errorMessage,
});

export const fetchSinglePlayerDataFailure = errorMessage => ({
	type: SingleMatchTypes.FETCH_SINGLE_PLAYER_DATA_FAILURE,
	payload: errorMessage,
});

export const updateSinglePlayerDataFailure = errorMessage => ({
	type: SingleMatchTypes.UPDATE_SINGLE_PLAYER_DATA_FAILURE,
	payload: errorMessage,
});

export const addSinglePlayerDataFailure = errorMessage => ({
	type: SingleMatchTypes.ADD_SINGLE_PLAYER_DATA_FAILURE,
	payload: errorMessage,
});

export const removeSingleMatchPlayerDataFailure = errorMessage => ({
	type: SingleMatchTypes.REMOVE_SINGLE_MATCH_PLAYER_DATA_FAILURE,
	payload: errorMessage,
});

export const filterMatchPlayersFailure = errorMessage => ({
	type: SingleMatchTypes.FILTER_MATCH_PLAYERS_FAILURE,
	payload: errorMessage,
});

export const fetchMatchDropdownByTeamTypeFailure = errorMessage => ({
	type: SingleMatchTypes.FETCH_SINGLE_MATCH_DROPDOWN_BY_TEAM_TYPE_FAILURE,
	payload: errorMessage,
});
