import { createSelector } from 'reselect';

const selectTeamTypesStore = state => state.teamTypes;

// Selects teamTypes.teamTypes from the state
export const selectTeamTypes = createSelector(
	[selectTeamTypesStore],
	teamTypes => teamTypes.teamTypes
);

// Selects teamTypes.isFetching from the state
export const selectTeamTypesIsFetching = createSelector(
	[selectTeamTypesStore],
	teamTypes => teamTypes.isFetching
);
