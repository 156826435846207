import styled, { css } from 'styled-components';

import { colorStyles, inputStyles } from '../../../styles/global.styles';

import arrowDown from '../../../assets/chevron-down.svg';

export const Dropdown = styled.select`
	border-color: #f5f8fa;
	background-image: url(${arrowDown});
	background-repeat: no-repeat;
	background-position: right 12px center;
	background-size: 20px;
	appearance: none;
	width: 100%;
	cursor: pointer;

	:disabled {
		background-color: ${colorStyles.greyScale6};
		cursor: not-allowed;
	}

	${props =>
		props.size &&
		css`
			width: ${props.size};
		`}

	${inputStyles.input}
`;
