import styled from 'styled-components';

const positionValue = 28;

export const TeamTypeBlock = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: scroll;
	max-height: 550px;
`;

export const TypeItem = styled.div`
	align-items: center;
	width: 200px;
	margin-right: 8px;

	.seperator {
		margin: 8px 0;
	}
`;

export const Filters = styled.div`
	display: flex;
	align-items: center;

	.input {
		padding-right: ${positionValue}px;
		height: 40px;
	}

	.filter-team {
		height: 40px;
		margin-left: 12px;
	}

	svg {
		width: 24px;
		margin-left: -${positionValue}px;
		cursor: pointer;
	}
`;

export const FilterButtons = styled.div`
	display: flex;
	margin-top: 12px;

	.filter-button {
		margin-right: 12px;
	}
`;
