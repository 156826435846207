import { Fragment } from 'react';
import { compose } from 'redux';
import { useHistory, useRouteMatch } from 'react-router';
import moment from 'moment';

import {
	Chip,
	Table,
	TableData2,
	TableDataChip2,
	TableHeader2,
} from '../../../components/molecule/table/Table.styles';

import { WithReduxMatches } from '../../../components/hoc/WithReduxMatches.hoc';

const List = ({ fetchSingleMatchStart, filteredMatches }) => {
	const history = useHistory();
	const { url } = useRouteMatch();

	return (
		<Table gridColumns='1fr 1.2fr 1.3fr 0.7fr 1fr '>
			<TableHeader2>Date</TableHeader2>
			<TableHeader2>My Team Name</TableHeader2>
			<TableHeader2>Opposition Team Name</TableHeader2>
			<TableHeader2>Score</TableHeader2>
			<TableHeader2>Is Active</TableHeader2>
			{
				// FIXME: Remove the slice function
				filteredMatches.slice(0, 700).map((match, index) => (
					<Fragment key={index}>
						<TableData2
							onClick={() => {
								history.push(`${url}/${match.id}`);
								fetchSingleMatchStart(match.id);
							}}
						>
							{moment(match.date).format('yyyy-MM-DD')}
						</TableData2>
						<TableData2
							onClick={() => {
								history.push(`${url}/${match.id}`);
								fetchSingleMatchStart(match.id);
							}}
						>
							{match.teamName}
						</TableData2>
						<TableData2
							onClick={() => {
								history.push(`${url}/${match.id}`);
								fetchSingleMatchStart(match.id);
							}}
						>
							{match.oppositionName}
						</TableData2>
						<TableData2
							onClick={() => {
								history.push(`${url}/${match.id}`);
								fetchSingleMatchStart(match.id);
							}}
						>{`${match.teamScore} - ${match.oppositionScore}`}</TableData2>
						<TableDataChip2
							onClick={() => {
								history.push(`${url}/${match.id}`);
								fetchSingleMatchStart(match.id);
							}}
						>
							<Chip isActive={match.isActive}>
								{match.isActive ? 'Active' : 'Inactive'}
							</Chip>
						</TableDataChip2>
					</Fragment>
				))
			}
		</Table>
	);
};

export default compose(WithReduxMatches)(List);
