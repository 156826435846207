import styled, { css } from 'styled-components';
import { colorStyles, inputStyles } from '../../../styles/global.styles';

export const InputBlock = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	${props =>
		props.size &&
		css`
			width: ${props.size};
		`}

	label {
		margin-bottom: 4px;
		text-transform: capitalize;
		color: ${colorStyles.greyScale4};

		span {
			color: ${colorStyles.error};
		}
	}

	.input {
		${inputStyles.input}
	}

	.error-label {
		height: 16px;

		p {
			font-size: 14px;
			color: red;
		}
	}
`;
