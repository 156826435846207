import _ from 'lodash';

import { CheckboxBlock } from './Checkbox.styles';

import { ReactComponent as CheckMarkIcon } from '../../../assets/check-mark.svg';

export const Checkbox = ({ label, value, index, action = () => {} }) => {
	const handleOnChange = event => {
		const checked = event.target.checked;

		_.isNumber(index) ? action(checked, index) : action(checked);
	};

	return (
		<CheckboxBlock isChecked={value}>
			{label}
			<input type='checkbox' checked={value} onChange={handleOnChange} />
			<span>
				<CheckMarkIcon />
			</span>
		</CheckboxBlock>
	);
};
