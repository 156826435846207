import { createSelector } from 'reselect';

const selectPositionTypesStore = state => state.positionTypes;

// Selects positionTypes.positionTypes from the state
export const selectPositionTypes = createSelector(
	[selectPositionTypesStore],
	positionTypes => positionTypes.positionTypes
);

// Selects positionTypes.isFetching from the state
export const selectPositionTypesIsFetching = createSelector(
	[selectPositionTypesStore],
	positionTypes => positionTypes.isFetching
);
