import { compose } from 'redux';

import { Seperator } from '../../../../components/atom/seperator/Seperator.styles';
import { InputGrid, ScoreBlock } from '../MatchEdit.styles';
import { DropdownField } from '../../../../components/molecule/dropdown-field/DropdownField.component';
import { NumberField } from '../../../../components/molecule/number-field/NumberField.component';
import { DatePicker } from '../../../../components/molecule/date-picker/DatePicker.component';
import { Switch } from '../../../../components/molecule/switch/Switch.component';

import { WithReduxMatches } from '../../../../components/hoc/WithReduxMatches.hoc';
import { WithReduxCompetition } from '../../../../components/hoc/WithReduxCompetition.hoc';
import { WithReduxOppositions } from '../../../../components/hoc/WithReduxOppositions.hoc';
import { WithReduxMyTeams } from '../../../../components/hoc/WithReduxMyTeams.hoc';

const InputSection = ({
	singleMatch,
	myTeams,
	oppositions,
	competition,
	setSingleMatchTeamId,
	setSingleMatchTeamScore,
	setSingleMatchOppositionScore,
	setSingleMatchOpppositionId,
	setSingleMatchDate,
	setSingleMatchCompetitionId,
	setSingleMatchIsActive,
}) => {
	const isTeamSelected = singleMatch.teamId === '';

	return (
		<InputGrid>
			<DropdownField
				required
				label='My Team'
				nullLabel='- Select -'
				value={singleMatch.teamId}
				data={myTeams}
				dataProp='name'
				action={setSingleMatchTeamId}
			/>
			<ScoreBlock>
				<NumberField
					required
					className='home'
					label='My Team Score'
					value={singleMatch.teamScore}
					action={setSingleMatchTeamScore}
				/>
				<Seperator />
				<NumberField
					required
					label='Opp Score'
					className='away'
					value={singleMatch.oppositionScore}
					action={setSingleMatchOppositionScore}
				/>
			</ScoreBlock>
			<DropdownField
				required
				disabled={isTeamSelected}
				label='Opp Team'
				nullLabel='- Select -'
				value={singleMatch.oppositionId}
				data={oppositions}
				dataProp='name'
				action={setSingleMatchOpppositionId}
			/>
			<DropdownField
				required
				disabled={isTeamSelected}
				label='Competition'
				nullLabel='- Select -'
				value={singleMatch.competitionId}
				data={competition}
				dataProp='name'
				action={setSingleMatchCompetitionId}
			/>
			<DatePicker
				required
				label='Match Date'
				value={singleMatch.date}
				action={setSingleMatchDate}
			/>
			<div />
			<Switch
				id='match-is-active'
				label='Is Active'
				value={singleMatch.isActive}
				action={setSingleMatchIsActive}
			/>
			<Seperator className='full-width' />
		</InputGrid>
	);
};

export default compose(
	WithReduxMatches,
	WithReduxCompetition,
	WithReduxOppositions,
	WithReduxMyTeams
)(InputSection);
