import { compose } from 'redux';

import {
	Seperator,
	SeperatorVertical,
} from '../../../../components/atom/seperator/Seperator.styles';
import { ButtonBlock2 } from '../../../../components/atom/inputs/Inputs.styles';
import {
	EditBlock,
	PopupOverlay,
} from '../../../../components/atom/pop-up/PopUp.styles';
import { PlayerInputBlock } from '../MatchEdit.styles';
import { DropdownField } from '../../../../components/molecule/dropdown-field/DropdownField.component';
import { Switch } from '../../../../components/molecule/switch/Switch.component';
import { NumberField } from '../../../../components/molecule/number-field/NumberField.component';
import {
	Button,
	InverseButton,
} from '../../../../components/atom/buttons/Button.styles';

import { WithReduxMatches } from '../../../../components/hoc/WithReduxMatches.hoc';
import { WithReduxPositionTypes } from '../../../../components/hoc/WithReduxPositionTypes.hoc';

const AddPlayerPopup = ({
	positionTypes,
	setMatchesIsEdit,
	singlePlayerData,
	newFilteredMatches,
	updateSinglePlayerDataStart,
	setSinglePlayerDataPlayerId,
	setSinglePlayerDataPositionTypeId,
	setSinglePlayerDataIsCaptain,
	setSinglePlayerDataWasSubstituted,
	setSinglePlayerDataIsSubstituted,
	setSinglePlayerDataStartingMinutes,
	setSinglePlayerDataEndingMinutes,
	addSinglePlayerDataStart,
}) => {
	return (
		<PopupOverlay>
			<EditBlock>
				<PlayerInputBlock>
					<DropdownField
						required
						disabled={singlePlayerData.playerFullname !== ''}
						label='Player'
						nullLabel='- Select -'
						value={singlePlayerData.playerId}
						data={newFilteredMatches}
						dataProp='name'
						action={setSinglePlayerDataPlayerId}
						className='first-input input'
					/>
					<DropdownField
						label='Position'
						nullLabel='- Select -'
						value={singlePlayerData.positionTypeId}
						data={positionTypes}
						dataProp='label'
						action={setSinglePlayerDataPositionTypeId}
						className='input'
					/>
				</PlayerInputBlock>
				<Seperator className='seperator' />
				<PlayerInputBlock>
					<Switch
						id='match-player-is-captain'
						label='Is Captain'
						value={singlePlayerData.isCaptain}
						action={setSinglePlayerDataIsCaptain}
					/>
					<SeperatorVertical className='vertical' />
					<Switch
						id='match-player-was-substituted'
						label='Was Substituted'
						value={singlePlayerData.wasSubstituted}
						action={setSinglePlayerDataWasSubstituted}
					/>
					<SeperatorVertical className='vertical' />
					<Switch
						id='match-player-is-substituted'
						label='Is Substitution'
						value={singlePlayerData.isSubstituted}
						action={setSinglePlayerDataIsSubstituted}
					/>
				</PlayerInputBlock>
				<Seperator className='seperator' />
				<PlayerInputBlock>
					<NumberField
						label='Starting Minutes'
						className='first-input input'
						value={singlePlayerData.startingMinutes}
						action={setSinglePlayerDataStartingMinutes}
					/>
					<NumberField
						label='Ending Minutes'
						className='first-input input'
						value={singlePlayerData.endingMinutes}
						action={setSinglePlayerDataEndingMinutes}
					/>
				</PlayerInputBlock>
				<ButtonBlock2>
					<InverseButton
						onClick={() => {
							setMatchesIsEdit(false);
						}}
					>
						Cancel
					</InverseButton>
					<Button
						disabled={singlePlayerData.playerId ? false : true}
						className='save-button'
						onClick={() =>
							singlePlayerData.playerFullname
								? updateSinglePlayerDataStart()
								: addSinglePlayerDataStart()
						}
					>
						{singlePlayerData.playerFullname ? 'Update' : 'Add'}
					</Button>
				</ButtonBlock2>
			</EditBlock>
		</PopupOverlay>
	);
};

export default compose(
	WithReduxMatches,
	WithReduxPositionTypes
)(AddPlayerPopup);
