import { createSelector } from 'reselect';

const selectFilesStore = state => state.files;

// Selects files.files from the state
export const selectFiles = createSelector([selectFilesStore], files => files);

// Selects files.files from the state
export const selectFilesFiles = createSelector(
	[selectFilesStore],
	files => files.files
);

// Selects files.folders from the state
export const selectFilesFolders = createSelector(
	[selectFilesStore],
	files => files.folders
);

// Selects files.fullPath from the state
export const selectFilesFullPath = createSelector(
	[selectFilesStore],
	files => files.fullPath
);

// Selects files.back from the state
export const selectFilesBack = createSelector(
	[selectFilesStore],
	files => files.back
);

// Selects files.isFetching from the state
export const selectFilesIsFetching = createSelector(
	[selectFilesStore],
	files => files.isFetching
);

// Selects files.errorMessage from the state
export const selectFilesErrorMessage = createSelector(
	[selectFilesStore],
	files => files.errorMessage
);
