import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// Redux: Selectors
import {
	selectOppositions,
	selectOppositionsIsFetching,
	selectOppositionsIsEdit,
	selectFilteredOppositions,
	selectOppositionsFilterTeamTypeId,
} from '../../pages/oppositions/redux/list-screen/selectors';
import {
	selectSingleOpposition,
	selectSingleOppositionIsSingleFetching,
} from '../../pages/oppositions/redux/edit-screen/selectors';

// Redux: Actions
import {
	fetchOppositionsStart,
	setOppositionsIsEdit,
	setOppositionFilterTeamTypeId,
	clearOppositionsFilterTeamTypeId,
} from '../../pages/oppositions/redux/list-screen/actions';
import {
	clearSingleOpposition,
	createSingleOppositionStart,
	fetchSingleOppositionStart,
	setOppositionCode,
	setOppositionCountryId,
	setOppositionIsActive,
	setOppositionName,
	setOppositionTeamTypeId,
	updateSingleOppositionStart,
} from '../../pages/oppositions/redux/edit-screen/actions';

export const WithReduxOppositions = WrappedComponent => {
	// Add Redux actions and state to the WrappedComponent
	const WithData = props => {
		return <WrappedComponent {...props} />;
	};

	// Redux: Selectors
	const mapStateToProps = createStructuredSelector({
		oppositions: selectOppositions,
		filteredOppositions: selectFilteredOppositions,
		singleOpposition: selectSingleOpposition,
		oppositionsIsFetching: selectOppositionsIsFetching,
		oppositionIsSingleFetching: selectSingleOppositionIsSingleFetching,
		oppositionsIsEdit: selectOppositionsIsEdit,
		oppositionsFilterTeamTypeId: selectOppositionsFilterTeamTypeId,
	});

	// Redux: Actions
	const mapDispatchToProps = dispatch => ({
		fetchOppositionsStart: () => dispatch(fetchOppositionsStart()),
		fetchSingleOppositionStart: id =>
			dispatch(fetchSingleOppositionStart(id)),
		setOppositionsIsEdit: bool => dispatch(setOppositionsIsEdit(bool)),
		setOppositionName: name => dispatch(setOppositionName(name)),
		setOppositionCode: code => dispatch(setOppositionCode(code)),
		setOppositionCountryId: id => dispatch(setOppositionCountryId(id)),
		setOppositionTeamTypeId: id => dispatch(setOppositionTeamTypeId(id)),
		setOppositionFilterTeamTypeId: id =>
			dispatch(setOppositionFilterTeamTypeId(id)),
		setOppositionIsActive: bool => dispatch(setOppositionIsActive(bool)),
		clearSingleOpposition: () => dispatch(clearSingleOpposition()),
		clearOppositionsFilterTeamTypeId: () =>
			dispatch(clearOppositionsFilterTeamTypeId()),
		createSingleOppositionStart: () =>
			dispatch(createSingleOppositionStart()),
		updateSingleOppositionStart: () =>
			dispatch(updateSingleOppositionStart()),
	});

	return connect(mapStateToProps, mapDispatchToProps)(WithData);
};
