import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import axios from 'axios';
import _ from 'lodash';

import { BASEAPI } from '../../../../routes/routes';
import { getCurrentUserToken } from '../../../../firebase/firebase.utils';

// Types
import { CompetitionTypes } from './types';

// Actions
import {
	fetchCompetitionFailure,
	fetchCompetitionSuccess,
	setFilteredCompetitions,
	clearCompetitionFilterTeamTypeId,
} from './actions';

// Selectors
import { selectCompetition } from './selectors';

/* ================================================================ */
/*  Actions                                                         */
/* ================================================================ */

export function* fetchCompetitionStart() {
	try {
		yield put(clearCompetitionFilterTeamTypeId());

		const token = yield call(getCurrentUserToken);
		const { data: competitions } = yield call(
			axios.get,
			`${BASEAPI}/competitions`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		yield put(fetchCompetitionSuccess(competitions));
		yield put(setFilteredCompetitions(competitions));
	} catch (error) {
		console.error(error);
		yield put(fetchCompetitionFailure(error));
	}
}

export function* setFilteredCompetitionsStart({ payload: id }) {
	try {
		const competition = yield select(selectCompetition);

		const filteredCompetitions = yield _.filter(competition, [
			'teamTypeId',
			id !== null ? id : '',
		]);

		yield put(setFilteredCompetitions(filteredCompetitions));
	} catch (error) {
		console.error(error);
	}
}

/* ================================================================ */
/*  Listeners                                                       */
/* ================================================================ */

export function* onFetchCompetitionStart() {
	yield takeLatest(
		CompetitionTypes.FETCH_COMPETITION_START,
		fetchCompetitionStart
	);
}

export function* onSetFilteredCompetitionsStart() {
	yield takeLatest(
		CompetitionTypes.SET_COMPETITION_FILTER_TEAM_TYPE_ID,
		setFilteredCompetitionsStart
	);
}

/* ================================================================ */
/*  Root Saga                                                       */
/* ================================================================ */

export default function* competitionSagas() {
	yield all([
		call(onFetchCompetitionStart),
		call(onSetFilteredCompetitionsStart),
	]);
}
