import styled, { css } from 'styled-components';
import { colorStyles } from '../../../styles/global.styles';

export const Table = styled.div`
	display: grid;
	min-width: 700px;
	grid-auto-columns: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	border-radius: 4px;
	background-color: #fff;
	${props =>
		props.gridColumns &&
		css`
			grid-template-columns: ${props.gridColumns};
		`}
`;

export const TopLabel = styled.div`
	padding: 12px 24px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.4);
	font-size: 16px;
	font-weight: 700;
`;

export const TableItem = styled.div`
	display: flex;
	align-items: center;
	padding: 12px 24px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.18);
	font-size: 16px;
	font-weight: 400;
	cursor: pointer;
`;

export const TableItemChip = styled.div`
	display: flex;
	align-items: center;
	padding: 12px 24px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.18);
	cursor: pointer;

	.MuiChip-root {
		color: #000;
		cursor: pointer;
		${props =>
			props.isActive
				? css`
						background-color: #4caf50;
				  `
				: css`
						background-color: red;
				  `}
	}
`;

export const FilterBlock2 = styled.div`
	display: flex;
	margin-bottom: 60px;
	justify-content: space-between;

	.new-button {
		align-self: start;
	}
`;

export const TableHeader2 = styled.div`
	padding-top: 12px;
	padding-bottom: 12px;
	color: rgba(0, 0, 0, 0.5);
	font-size: 16px;
	line-height: 16px;
	font-weight: 500;
	text-transform: capitalize;
`;

export const TableData2 = styled.div`
	padding: 20px 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	color: #000;
	font-size: 16px;
	line-height: 16px;
	font-weight: 500;
	text-transform: capitalize;
	cursor: pointer;
`;

export const TableDataChip2 = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	cursor: pointer;
`;

export const Chip = styled.div`
	display: flex;
	padding: 4px 12px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	font-size: 12px;
	text-transform: capitalize;

	${props =>
		props.isActive
			? css`
					background-color: rgba(76, 175, 79, 0.1);
					color: ${colorStyles.success};
			  `
			: css`
					background-color: rgba(244, 67, 54, 0.1);
					color: ${colorStyles.error};
			  `}
`;

export const Table2 = styled.div`
	display: grid;
	grid-auto-columns: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	${props =>
		props.gridColumns &&
		css`
			grid-template-columns: ${props.gridColumns};
		`}
`;
