import styled, { css } from 'styled-components';

export const Seperator = styled.div`
	width: 100%;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.25);
`;

export const SeperatorVertical = styled.div`
	width: 1px;
	background-color: rgba(0, 0, 0, 0.25);

	${props =>
		props.margin &&
		css`
			margin: 0 ${props.margin};
		`}
`;
