import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// Redux: Selectors
import {
	selectPositionTypes,
	selectPositionTypesIsFetching,
} from '../../redux/position-types/selectors';

// Redux: Actions
import { fetchPositionTypesStart } from '../../redux/position-types/actions';

export const WithReduxPositionTypes = WrappedComponent => {
	// Add Redux actions and state to the WrappedComponent
	const WithData = props => {
		return <WrappedComponent {...props} />;
	};

	// Redux: Selectors
	const mapStateToProps = createStructuredSelector({
		positionTypes: selectPositionTypes,
		positionTypesIsFetching: selectPositionTypesIsFetching,
	});

	// Redux: Actions
	const mapDispatchToProps = dispatch => ({
		fetchPositionTypesStart: () => dispatch(fetchPositionTypesStart()),
	});

	return connect(mapStateToProps, mapDispatchToProps)(WithData);
};
