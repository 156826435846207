import styled from 'styled-components';

export const PopupOverlay = styled.div`
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: auto;
	padding: 40px;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1;
`;

export const EditBlock = styled.div`
	overflow: auto;
	padding: 40px;
	border-radius: 8px;
	background-color: #fff;

	.flex-row {
		display: flex;
		flex-direction: row;
	}

	.seperator {
		margin: 32px 0;
	}
`;
