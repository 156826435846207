import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import axios from 'axios';
import _ from 'lodash';

import { BASEAPI } from '../../../../routes/routes';
import { getCurrentUserToken } from '../../../../firebase/firebase.utils';

// Types
import { SingleMatchTypes } from './types';

// Actions
import {
	addSinglePlayerDataFailure,
	addSinglePlayerDataSuccess,
	clearSingleMatchXmlFile,
	createSingleMatchFailure,
	createSingleMatchSuccess,
	fetchSingleMatchFailure,
	fetchSingleMatchSuccess,
	fetchSinglePlayerDataFailure,
	fetchSinglePlayerDataSuccess,
	filterMatchPlayersFailure,
	filterMatchPlayersSuccess,
	newSingleMatchFailure,
	newSingleMatchSuccess,
	removeSingleMatchPlayerDataFailure,
	removeSingleMatchPlayerDataSuccess,
	updateSingleMatchFailure,
	updateSingleMatchSuccess,
	updateSinglePlayerDataFailure,
	updateSinglePlayerDataSuccess,
	fetchMatchDropdownByTeamTypeFailure,
	setMatchFilteredPlayers,
	setMatchPlayersData,
	setSingleMatchOpppositionId,
	setSingleMatchCompetitionId,
	setSingleMatchIsFetching,
	clearSingleMatchReducer,
} from './actions';
import {
	fetchOppositionsSuccess,
	setOppositionsIsFetching,
} from '../../../oppositions/redux/list-screen/actions';
import {
	fetchMyTeamsSuccess,
	setMyTeamsIsFetching,
} from '../../../my-teams/redux/list-screen/actions';
import {
	fetchPlayersSuccess,
	setPlayersIsFetching,
} from '../../../players/redux/list-screen/actions';
import {
	fetchCompetitionSuccess,
	setCompetitionIsFetching,
} from '../../../competition/redux/list-screen/actions';
import {
	fetchPositionTypesSuccess,
	setPositionTypesIsFetching,
} from '../../../../redux/position-types/actions';
import { fetchMatchesStart, setMatchesIsEdit } from '../../redux/actions';

// Selectors
import {
	selectSingleMatch,
	selectPlayersData,
	selectSinglePlayerData,
	selectFilteredPlayers,
	selectMatchesXmlFile,
} from './selectors';
import { selectPlayers } from '../../../players/redux/list-screen/selectors';
import { selectMyTeams } from '../../../my-teams/redux/list-screen/selectors';

/* ================================================================ */
/*  Actions                                                         */
/* ================================================================ */

export function* fetchSingleMatchStart({ payload: id }) {
	try {
		yield put(clearSingleMatchReducer());
		yield put(setSingleMatchIsFetching());
		yield put(setOppositionsIsFetching());
		yield put(setMyTeamsIsFetching());
		yield put(setPlayersIsFetching());
		yield put(setCompetitionIsFetching());
		yield put(setPositionTypesIsFetching());

		const token = yield call(getCurrentUserToken);

		const HEADERS = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const { data: match } = yield call(
			axios.get,
			`${BASEAPI}/matches/${id}`,
			HEADERS
		);

		const [
			{ data: filteredPlayers },
			{ data: playersData },
			{ data: oppositions },
			{ data: teams },
			{ data: players },
			{ data: competitions },
			{ data: positionTypes },
		] = yield all([
			call(
				axios.get,
				`${BASEAPI}/teams/${match.teamId}/players`,
				HEADERS
			),
			call(axios.get, `${BASEAPI}/matches/${id}/player-matches`, HEADERS),
			call(
				axios.get,
				`${BASEAPI}/team-types/${match.teamTypeId}/oppositions`,
				HEADERS
			),
			call(axios.get, `${BASEAPI}/teams?is_dropdown=true`, HEADERS),
			call(axios.get, `${BASEAPI}/players`, HEADERS),
			call(
				axios.get,
				`${BASEAPI}/team-types/${match.teamTypeId}/competitions`,
				HEADERS
			),
			call(axios.get, `${BASEAPI}/position-types`, HEADERS),
		]);

		const orderedFilteredPlayers = yield _.orderBy(
			filteredPlayers,
			['name'],
			['asc']
		);

		yield put(fetchOppositionsSuccess(oppositions));
		yield put(fetchMyTeamsSuccess(teams));
		yield put(fetchPlayersSuccess(players));
		yield put(fetchCompetitionSuccess(competitions));
		yield put(fetchPositionTypesSuccess(positionTypes));
		yield put(setMatchFilteredPlayers(orderedFilteredPlayers));
		yield put(setMatchPlayersData(playersData));
		yield put(fetchSingleMatchSuccess(match));
	} catch (error) {
		console.error(error);
		yield put(fetchSingleMatchFailure(error));
	}
}

export function* newSingleMatchStart() {
	try {
		yield put(clearSingleMatchReducer());
		yield put(setSingleMatchIsFetching());
		yield put(setOppositionsIsFetching());
		yield put(setMyTeamsIsFetching());
		yield put(setCompetitionIsFetching());
		yield put(setPositionTypesIsFetching());
		yield put(setPlayersIsFetching());

		const token = yield call(getCurrentUserToken);

		const HEADERS = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const [
			{ data: oppositions },
			{ data: teams },
			{ data: competitions },
			{ data: positionTypes },
			{ data: players },
		] = yield all([
			call(axios.get, `${BASEAPI}/oppositions`, HEADERS),
			call(axios.get, `${BASEAPI}/teams?is_dropdown=true`, HEADERS),
			call(axios.get, `${BASEAPI}/competitions`, HEADERS),
			call(axios.get, `${BASEAPI}/position-types`, HEADERS),
			call(axios.get, `${BASEAPI}/players`, HEADERS),
		]);

		yield put(fetchOppositionsSuccess(oppositions));
		yield put(fetchMyTeamsSuccess(teams));
		yield put(fetchCompetitionSuccess(competitions));
		yield put(fetchPositionTypesSuccess(positionTypes));
		yield put(fetchPlayersSuccess(players));
		yield put(newSingleMatchSuccess());
	} catch (error) {
		console.error(error);
		yield put(newSingleMatchFailure(error));
	}
}

export function* createSingleMatchStart() {
	try {
		const token = yield call(getCurrentUserToken);

		const { id, ...singleMatch } = yield select(selectSingleMatch);
		const xmlFile = yield select(selectMatchesXmlFile);

		const HEADERS = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const formData = new FormData();
		formData.append('file', xmlFile);

		const { data: response } = yield call(
			axios.post,
			`${BASEAPI}/matches`,
			{ ...singleMatch },
			HEADERS
		);

		if (xmlFile !== null)
			yield call(
				axios.post,
				`${BASEAPI}/matches/${response.matchId}/xml-file`,
				formData,
				HEADERS
			);

		const [{ data: filteredPlayers }, { data: newSingleMatch }] = yield all(
			[
				call(
					axios.get,
					`${BASEAPI}/teams/${singleMatch.teamId}/players`,
					HEADERS
				),
				call(
					axios.get,
					`${BASEAPI}/matches/${response.matchId}`,
					HEADERS
				),
			]
		);

		yield put(createSingleMatchSuccess(newSingleMatch));
		yield put(setMatchFilteredPlayers(filteredPlayers));
		yield put(clearSingleMatchXmlFile());
	} catch (error) {
		console.error(error);
		yield put(createSingleMatchFailure(error));
	}
}

export function* updateSingleMatchStart() {
	try {
		const token = yield call(getCurrentUserToken);

		const { id, ...singleMatch } = yield select(selectSingleMatch);
		const playersData = yield select(selectPlayersData);
		const xmlFile = yield select(selectMatchesXmlFile);

		const HEADERS = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		if (xmlFile !== null) {
			const formData = new FormData();
			formData.append('file', xmlFile);

			yield call(
				axios.post,
				`${BASEAPI}/matches/${id}/xml-file`,
				formData,
				HEADERS
			);
		}

		yield call(
			axios.patch,
			`${BASEAPI}/matches/${id}`,
			{ ...singleMatch },
			HEADERS
		);

		yield call(
			axios.put,
			`${BASEAPI}/matches/${id}/teams/${singleMatch.teamId}/player-matches`,
			playersData,
			HEADERS
		);

		yield put(updateSingleMatchSuccess());
		yield put(fetchMatchesStart());
	} catch (error) {
		console.error(error);
		yield put(updateSingleMatchFailure(error));
	}
}

export function* fetchSinglePlayerDataStart({ payload: index }) {
	try {
		yield put(setMatchesIsEdit(true));

		const playersData = yield select(selectPlayersData);
		const filteredPlayers = yield select(selectFilteredPlayers);

		const playerData = yield playersData[index];
		const addPlayer = yield {
			id: playerData.playerId,
			name: playerData.playerFullname,
		};

		yield filteredPlayers.push(addPlayer);

		yield put(filterMatchPlayersSuccess(filteredPlayers));
		yield put(fetchSinglePlayerDataSuccess(playerData));
	} catch (error) {
		console.error(error);
		yield put(fetchSinglePlayerDataFailure(error));
		yield put(setMatchesIsEdit(false));
	}
}

export function* updateSinglePlayerDataStart() {
	try {
		const playersData = yield select(selectPlayersData);
		const singlePlayerData = yield select(selectSinglePlayerData);

		const updatedPlayersData = playersData.map(player =>
			player.playerId === singlePlayerData.playerId
				? { ...singlePlayerData }
				: player
		);

		yield put(updateSinglePlayerDataSuccess(updatedPlayersData));
		yield put(setMatchesIsEdit(false));
	} catch (error) {
		console.error(error);
		yield put(updateSinglePlayerDataFailure(error));
	}
}

export function* addSinglePlayerDataStart() {
	try {
		const playersData = yield select(selectPlayersData);
		const singlePlayerData = yield select(selectSinglePlayerData);
		const players = yield select(selectPlayers);

		const player = yield players.find(
			player => player.id === singlePlayerData.playerId
		);
		const newSinglePlayerData = yield {
			...singlePlayerData,
			playerFullname: `${player.firstName} ${player.lastName}`,
			playerId: singlePlayerData.playerId ? singlePlayerData.playerId : 0,
			positionTypeId: singlePlayerData.positionTypeId
				? singlePlayerData.positionTypeId
				: 0,
		};

		yield playersData.push(newSinglePlayerData);

		yield put(addSinglePlayerDataSuccess(playersData));
		yield put(setMatchesIsEdit(false));
	} catch (error) {
		console.error(error);
		yield put(addSinglePlayerDataFailure(error));
	}
}

export function* removeSingleMatchPlayerDataStart({ payload: id }) {
	try {
		const playersData = yield select(selectPlayersData);
		const newPlayersData = yield playersData.filter(
			player => player.playerId !== id
		);

		yield put(removeSingleMatchPlayerDataSuccess(newPlayersData));
	} catch (error) {
		console.error(error);
		yield put(removeSingleMatchPlayerDataFailure(error));
	}
}

export function* filterMatchPlayersStart() {
	try {
		const filteredPlayers = yield select(selectFilteredPlayers);
		const playersData = yield select(selectPlayersData);
		const newFilteredPlayers = yield filteredPlayers.filter(player => {
			return !playersData.some(item => item.playerId === player.id);
		});
		const emptySinglePlayerData = {
			playerId: '',
			positionTypeId: '',
			isCaptain: false,
			wasSubstituted: false,
			isSubstituted: false,
			startingMinutes: 0,
			endingMinutes: 90,
			playerFullname: '',
		};

		yield put(filterMatchPlayersSuccess(newFilteredPlayers));
		yield put(fetchSinglePlayerDataSuccess(emptySinglePlayerData));
		yield put(setMatchesIsEdit(true));
	} catch (error) {
		console.error(error);
		yield put(filterMatchPlayersFailure(error));
		yield put(setMatchesIsEdit(false));
	}
}

export function* fetchMatchDropdownByTeamTypeStart({ payload: teamId }) {
	try {
		if (teamId) {
			yield put(setOppositionsIsFetching());
			yield put(setCompetitionIsFetching());

			const myTeams = yield select(selectMyTeams);
			const { oppositionId, competitionId } = yield select(
				selectSingleMatch
			);
			const token = yield call(getCurrentUserToken);

			const HEADERS = {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			};

			const teamTypeId = yield myTeams.find(team => team.id === teamId)
				.teamTypeId;

			const [{ data: oppositions }, { data: competitions }] = yield all([
				call(
					axios.get,
					`${BASEAPI}/team-types/${teamTypeId}/oppositions`,
					HEADERS
				),
				call(
					axios.get,
					`${BASEAPI}/team-types/${teamTypeId}/competitions`,
					HEADERS
				),
			]);

			const hasOppositionId = oppositions.some(
				opp => opp.id === oppositionId
			);
			const hasCompetitionId = competitions.some(
				comp => comp.id === competitionId
			);

			if (!hasOppositionId) yield put(setSingleMatchOpppositionId(''));
			if (!hasCompetitionId) yield put(setSingleMatchCompetitionId(''));

			yield put(fetchOppositionsSuccess(oppositions));
			yield put(fetchCompetitionSuccess(competitions));
		}
	} catch (error) {
		console.error(error);
		yield put(fetchMatchDropdownByTeamTypeFailure(error));
	}
}

/* ================================================================ */
/*  Listeners                                                       */
/* ================================================================ */

export function* onFetchSingleMatchStart() {
	yield takeLatest(
		SingleMatchTypes.FETCH_SINGLE_MATCH_START,
		fetchSingleMatchStart
	);
}

export function* onNewSingleMatchStart() {
	yield takeLatest(
		SingleMatchTypes.NEW_SINGLE_MATCH_START,
		newSingleMatchStart
	);
}

export function* onCreateSingleMatchStart() {
	yield takeLatest(
		SingleMatchTypes.CREATE_SINGLE_MATCH_START,
		createSingleMatchStart
	);
}

export function* onUpdateSingleMatchStart() {
	yield takeLatest(
		SingleMatchTypes.UPDATE_SINGLE_MATCH_START,
		updateSingleMatchStart
	);
}

export function* onFetchSinglePlayerDataStart() {
	yield takeLatest(
		SingleMatchTypes.FETCH_SINGLE_PLAYER_DATA_START,
		fetchSinglePlayerDataStart
	);
}

export function* onUpdateSinglePlayerDataStart() {
	yield takeLatest(
		SingleMatchTypes.UPDATE_SINGLE_PLAYER_DATA_START,
		updateSinglePlayerDataStart
	);
}

export function* onAddSinglePlayerDataStart() {
	yield takeLatest(
		SingleMatchTypes.ADD_SINGLE_PLAYER_DATA_START,
		addSinglePlayerDataStart
	);
}

export function* onRemoveSingleMatchPlayerDataStart() {
	yield takeLatest(
		SingleMatchTypes.REMOVE_SINGLE_MATCH_PLAYER_DATA_START,
		removeSingleMatchPlayerDataStart
	);
}

export function* onFilterMatchPlayersStart() {
	yield takeLatest(
		SingleMatchTypes.FILTER_MATCH_PLAYERS_START,
		filterMatchPlayersStart
	);
}

export function* onFetchMatchDropdownByTeamTypeStart() {
	yield takeLatest(
		SingleMatchTypes.SET_SINGLE_MATCH_TEAM_ID,
		fetchMatchDropdownByTeamTypeStart
	);
}

/* ================================================================ */
/*  Root Saga                                                       */
/* ================================================================ */

export default function* singleMatchSagas() {
	yield all([
		call(onFetchSingleMatchStart),
		call(onNewSingleMatchStart),
		call(onCreateSingleMatchStart),
		call(onUpdateSingleMatchStart),
		call(onFetchSinglePlayerDataStart),
		call(onUpdateSinglePlayerDataStart),
		call(onAddSinglePlayerDataStart),
		call(onRemoveSingleMatchPlayerDataStart),
		call(onFilterMatchPlayersStart),
		call(onFetchMatchDropdownByTeamTypeStart),
	]);
}
