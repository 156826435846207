export const SingleMatchTypes = {
	FETCH_SINGLE_MATCH_START: 'FETCH_SINGLE_MATCH_START',
	FETCH_SINGLE_MATCH_SUCCESS: 'FETCH_SINGLE_MATCH_SUCCESS',
	FETCH_SINGLE_MATCH_FAILURE: 'FETCH_SINGLE_MATCH_FAILURE',

	NEW_SINGLE_MATCH_START: 'NEW_SINGLE_MATCH_START',
	NEW_SINGLE_MATCH_SUCCESS: 'NEW_SINGLE_MATCH_SUCCESS',
	NEW_SINGLE_MATCH_FAILURE: 'NEW_SINGLE_MATCH_FAILURE',

	CREATE_SINGLE_MATCH_START: 'CREATE_SINGLE_MATCH_START',
	CREATE_SINGLE_MATCH_SUCCESS: 'CREATE_SINGLE_MATCH_SUCCESS',
	CREATE_SINGLE_MATCH_FAILURE: 'CREATE_SINGLE_MATCH_FAILURE',

	UPDATE_SINGLE_MATCH_START: 'UPDATE_SINGLE_MATCH_START',
	UPDATE_SINGLE_MATCH_SUCCESS: 'UPDATE_SINGLE_MATCH_SUCCESS',
	UPDATE_SINGLE_MATCH_FAILURE: 'UPDATE_SINGLE_MATCH_FAILURE',

	FETCH_SINGLE_PLAYER_DATA_START: 'FETCH_SINGLE_PLAYER_DATA_START',
	FETCH_SINGLE_PLAYER_DATA_SUCCESS: 'FETCH_SINGLE_PLAYER_DATA_SUCCESS',
	FETCH_SINGLE_PLAYER_DATA_FAILURE: 'FETCH_SINGLE_PLAYER_DATA_FAILURE',

	UPDATE_SINGLE_PLAYER_DATA_START: 'UPDATE_SINGLE_PLAYER_DATA_START',
	UPDATE_SINGLE_PLAYER_DATA_SUCCESS: 'UPDATE_SINGLE_PLAYER_DATA_SUCCESS',
	UPDATE_SINGLE_PLAYER_DATA_FAILURE: 'UPDATE_SINGLE_PLAYER_DATA_FAILURE',

	ADD_SINGLE_PLAYER_DATA_START: 'ADD_SINGLE_PLAYER_DATA_START',
	ADD_SINGLE_PLAYER_DATA_SUCCESS: 'ADD_SINGLE_PLAYER_DATA_SUCCESS',
	ADD_SINGLE_PLAYER_DATA_FAILURE: 'ADD_SINGLE_PLAYER_DATA_FAILURE',

	REMOVE_SINGLE_MATCH_PLAYER_DATA_START:
		'REMOVE_SINGLE_MATCH_PLAYER_DATA_START',
	REMOVE_SINGLE_MATCH_PLAYER_DATA_SUCCESS:
		'REMOVE_SINGLE_MATCH_PLAYER_DATA_SUCCESS',
	REMOVE_SINGLE_MATCH_PLAYER_DATA_FAILURE:
		'REMOVE_SINGLE_MATCH_PLAYER_DATA_FAILURE',

	FILTER_MATCH_PLAYERS_START: 'FILTER_MATCH_PLAYERS_START',
	FILTER_MATCH_PLAYERS_SUCCESS: 'FILTER_MATCH_PLAYERS_SUCCESS',
	FILTER_MATCH_PLAYERS_FAILURE: 'FILTER_MATCH_PLAYERS_FAILURE',

	SET_MATCH_FILTERED_PLAYERS: 'SET_MATCH_FILTERED_PLAYERS',
	SET_MATCH_PLAYERS_DATA: 'SET_MATCH_PLAYERS_DATA',
	SET_MATCH_SINGLE_PLAYER_DATA: 'SET_MATCH_SINGLE_PLAYER_DATA',

	SET_SINGLE_MATCH_DATE: 'SET_SINGLE_MATCH_DATE',
	SET_SINGLE_MATCH_TEAM_ID: 'SET_SINGLE_MATCH_TEAM_ID',
	SET_SINGLE_MATCH_TEAM_SCORE: 'SET_SINGLE_MATCH_TEAM_SCORE',
	SET_SINGLE_MATCH_OPPOSITION_SCORE: 'SET_SINGLE_MATCH_OPPOSITION_SCORE',
	SET_SINGLE_MATCH_OPPOSITION_ID: 'SET_SINGLE_MATCH_OPPOSITION_ID',
	SET_SINGLE_MATCH_COMPETITION_ID: 'SET_SINGLE_MATCH_COMPETITION_ID',
	SET_SINGLE_MATCH_IS_ACTIVE: 'SET_SINGLE_MATCH_IS_ACTIVE',
	SET_SINGLE_MATCH_XML_FILE: 'SET_SINGLE_MATCH_XML_FILE',
	SET_SINGLE_MATCH_IS_FETCHING: 'SET_SINGLE_MATCH_IS_FETCHING',

	SET_SINGLE_PLAYER_DATA_PLAYER_ID: 'SET_SINGLE_PLAYER_DATA_PLAYER_ID',
	SET_SINGLE_PLAYER_DATA_POSITION_TYPE_ID:
		'SET_SINGLE_PLAYER_DATA_POSITION_TYPE_ID',
	SET_SINGLE_PLAYER_DATA_IS_CAPTAIN: 'SET_SINGLE_PLAYER_DATA_IS_CAPTAIN',
	SET_SINGLE_PLAYER_DATA_WAS_SUBSTITUTED:
		'SET_SINGLE_PLAYER_DATA_WAS_SUBSTITUTED',
	SET_SINGLE_PLAYER_DATA_IS_SUBSTITUTED:
		'SET_SINGLE_PLAYER_DATA_IS_SUBSTITUTED',
	SET_SINGLE_PLAYER_DATA_STARTING_MINUTES:
		'SET_SINGLE_PLAYER_DATA_STARTING_MINUTES',
	SET_SINGLE_PLAYER_DATA_ENDING_MINUTES:
		'SET_SINGLE_PLAYER_DATA_ENDING_MINUTES',

	FETCH_SINGLE_MATCH_DROPDOWN_BY_TEAM_TYPE_FAILURE:
		'FETCH_SINGLE_MATCH_DROPDOWN_BY_TEAM_TYPE_FAILURE',

	CLEAR_SINGLE_MATCH_XML_FILE: 'CLEAR_SINGLE_MATCH_XML_FILE',
	CLEAR_SINGLE_PLAYER_DATA: 'CLEAR_SINGLE_PLAYER_DATA',
	CLEAR_SINGLE_MATCH_REDUCER: 'CLEAR_SINGLE_MATCH_REDUCER',
};
