import { createSelector } from 'reselect';

const selectSingleMatchStore = state => state.singleMatch;

// Selects singleMatch.singleMatch from the state
export const selectSingleMatch = createSelector(
	[selectSingleMatchStore],
	singleMatch => singleMatch.singleMatch
);

// Selects singleMatch.filteredPlayers from the state
export const selectFilteredPlayers = createSelector(
	[selectSingleMatchStore],
	singleMatch => singleMatch.filteredPlayers
);

// Selects singleMatch.newFilteredPlayers from the state
export const selectNewFilteredPlayers = createSelector(
	[selectSingleMatchStore],
	singleMatch => singleMatch.newFilteredPlayers
);

// Selects singleMatch.playersData from the state
export const selectPlayersData = createSelector(
	[selectSingleMatchStore],
	singleMatch => singleMatch.playersData
);

// Selects singleMatch.singlePlayerData from the state
export const selectSinglePlayerData = createSelector(
	[selectSingleMatchStore],
	singleMatch => singleMatch.singlePlayerData
);

// Selects singleMatch.xmlFile from the state
export const selectMatchesXmlFile = createSelector(
	[selectSingleMatchStore],
	singleMatch => singleMatch.xmlFile
);

// Selects singleMatch.isSingleFetching from the state
export const selectSingleMatchIsSingleFetching = createSelector(
	[selectSingleMatchStore],
	singleMatch => singleMatch.isSingleFetching
);
