import { SingleMatchTypes } from './types';

const INITIAL_STATE = {
	singleMatch: {
		date: null,
		teamScore: '',
		oppositionScore: '',
		isActive: true,
		teamId: '',
		oppositionId: '',
		competitionId: '',
		id: 0,
		hasXmlBucketFilename: false,
		teamTypeId: '',
	},
	xmlFile: null,
	filteredPlayers: [],
	newFilteredPlayers: [],
	playersData: [],
	singlePlayerData: {
		playerId: '',
		positionTypeId: '',
		isCaptain: false,
		wasSubstituted: false,
		isSubstituted: false,
		startingMinutes: 0,
		endingMinutes: 90,
		playerFullname: '',
	},
	isSingleFetching: false,
	errorMessage: '',
};

const singleMatchReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* ================================================================ */
		/*  Process Start                                                   */
		/* ================================================================ */
		case SingleMatchTypes.FETCH_SINGLE_MATCH_START:
		case SingleMatchTypes.NEW_SINGLE_MATCH_START:
		case SingleMatchTypes.SET_SINGLE_MATCH_IS_FETCHING:
			return {
				...state,
				isSingleFetching: true,
			};
		case SingleMatchTypes.SET_SINGLE_MATCH_DATE:
		case SingleMatchTypes.SET_SINGLE_MATCH_TEAM_ID:
		case SingleMatchTypes.SET_SINGLE_MATCH_TEAM_SCORE:
		case SingleMatchTypes.SET_SINGLE_MATCH_OPPOSITION_SCORE:
		case SingleMatchTypes.SET_SINGLE_MATCH_OPPOSITION_ID:
		case SingleMatchTypes.SET_SINGLE_MATCH_COMPETITION_ID:
		case SingleMatchTypes.SET_SINGLE_MATCH_IS_ACTIVE:
			return {
				...state,
				singleMatch: {
					...state.singleMatch,
					[action.inputName]: action.payload,
				},
			};
		case SingleMatchTypes.SET_SINGLE_PLAYER_DATA_PLAYER_ID:
		case SingleMatchTypes.SET_SINGLE_PLAYER_DATA_POSITION_TYPE_ID:
		case SingleMatchTypes.SET_SINGLE_PLAYER_DATA_IS_CAPTAIN:
		case SingleMatchTypes.SET_SINGLE_PLAYER_DATA_WAS_SUBSTITUTED:
		case SingleMatchTypes.SET_SINGLE_PLAYER_DATA_IS_SUBSTITUTED:
		case SingleMatchTypes.SET_SINGLE_PLAYER_DATA_STARTING_MINUTES:
		case SingleMatchTypes.SET_SINGLE_PLAYER_DATA_ENDING_MINUTES:
			return {
				...state,
				singlePlayerData: {
					...state.singlePlayerData,
					[action.inputName]: action.payload,
				},
			};
		case SingleMatchTypes.SET_MATCH_FILTERED_PLAYERS:
			return {
				...state,
				filteredPlayers: action.payload,
				isSingleFetching: false,
			};
		case SingleMatchTypes.SET_SINGLE_MATCH_XML_FILE:
			return {
				...state,
				xmlFile: action.payload,
			};
		/* ================================================================ */
		/*  Process Success                                                 */
		/* ================================================================ */
		case SingleMatchTypes.FETCH_SINGLE_MATCH_SUCCESS:
		case SingleMatchTypes.CREATE_SINGLE_MATCH_SUCCESS:
			return {
				...state,
				singleMatch: action.payload,
				isSingleFetching: false,
				errorMessage: '',
			};
		case SingleMatchTypes.NEW_SINGLE_MATCH_SUCCESS:
		case SingleMatchTypes.UPDATE_SINGLE_MATCH_SUCCESS:
			return {
				...state,
				isSingleFetching: false,
				errorMessage: '',
			};
		case SingleMatchTypes.UPDATE_SINGLE_PLAYER_DATA_SUCCESS:
		case SingleMatchTypes.ADD_SINGLE_PLAYER_DATA_SUCCESS:
		case SingleMatchTypes.REMOVE_SINGLE_MATCH_PLAYER_DATA_SUCCESS:
		case SingleMatchTypes.SET_MATCH_PLAYERS_DATA:
			return {
				...state,
				playersData: action.payload,
			};
		case SingleMatchTypes.FETCH_SINGLE_PLAYER_DATA_SUCCESS:
		case SingleMatchTypes.SET_MATCH_SINGLE_PLAYER_DATA:
			return {
				...state,
				singlePlayerData: action.payload,
			};
		case SingleMatchTypes.FILTER_MATCH_PLAYERS_SUCCESS:
			return {
				...state,
				newFilteredPlayers: action.payload,
			};
		case SingleMatchTypes.CLEAR_SINGLE_MATCH_XML_FILE:
			return {
				...state,
				xmlFile: null,
			};
		case SingleMatchTypes.CLEAR_SINGLE_MATCH_REDUCER:
			return {
				...state,
				singleMatch: {
					date: null,
					teamScore: '',
					oppositionScore: '',
					isActive: true,
					teamId: '',
					oppositionId: '',
					competitionId: '',
					id: 0,
					hasXmlBucketFilename: false,
					teamTypeId: '',
				},
				xmlFile: null,
				filteredPlayers: [],
				newFilteredPlayers: [],
				playersData: [],
				singlePlayerData: {
					playerId: '',
					positionTypeId: '',
					isCaptain: false,
					wasSubstituted: false,
					isSubstituted: false,
					startingMinutes: 0,
					endingMinutes: 90,
					playerFullname: '',
				},
				isSingleFetching: false,
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Failure                                                 */
		/* ================================================================ */
		case SingleMatchTypes.FETCH_SINGLE_MATCH_FAILURE:
		case SingleMatchTypes.NEW_SINGLE_MATCH_FAILURE:
		case SingleMatchTypes.CREATE_SINGLE_MATCH_FAILURE:
		case SingleMatchTypes.UPDATE_SINGLE_MATCH_FAILURE:
		case SingleMatchTypes.FETCH_SINGLE_PLAYER_DATA_FAILURE:
		case SingleMatchTypes.UPDATE_SINGLE_PLAYER_DATA_FAILURE:
		case SingleMatchTypes.ADD_SINGLE_PLAYER_DATA_FAILURE:
		case SingleMatchTypes.REMOVE_SINGLE_MATCH_PLAYER_DATA_FAILURE:
		case SingleMatchTypes.FILTER_MATCH_PLAYERS_FAILURE:
			return {
				...state,
				errorMessage: action.payload,
				isSingleFetching: false,
			};
		default:
			return state;
	}
};

export default singleMatchReducer;
