import { PlayersActionTypes } from './types';

const INITIAL_STATE = {
	players: [],
	filteredPlayers: [],
	filterTeamTypeId: '',
	filterSearch: '',
	isEdit: false,
	isFetching: false,
	errorMessage: '',
};

const playersReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* ================================================================ */
		/*  Process Start                                                   */
		/* ================================================================ */
		case PlayersActionTypes.FETCH_PLAYERS_START:
		case PlayersActionTypes.SET_PLAYERS_IS_FETCHING:
			return {
				...state,
				isFetching: true,
			};
		case PlayersActionTypes.SET_PLAYERS_IS_EDIT:
			return {
				...state,
				isEdit: action.payload,
			};
		case PlayersActionTypes.SET_FILTERED_PLAYERS:
			return {
				...state,
				filteredPlayers: action.payload,
				isFetching: false,
			};
		case PlayersActionTypes.SET_PLAYERS_FILTER_TEAM_TYPE_ID:
			return {
				...state,
				filterTeamTypeId: action.payload,
			};
		case PlayersActionTypes.SET_PLAYERS_SEARCH_FILTER:
			return {
				...state,
				filterSearch: action.payload,
			};
		case PlayersActionTypes.CLEAR_PLAYERS_FILTER_TEAM_TYPE_ID:
			return {
				...state,
				filterTeamTypeId: '',
			};
		case PlayersActionTypes.CLEAR_PLAYERS_SEARCH_FILTER:
			return {
				...state,
				filterSearch: '',
			};
		case PlayersActionTypes.CLEAR_ALL_PLAYERS_FILTERS:
			return {
				...state,
				filteredPlayers: state.players,
				filterTeamTypeId: '',
				filterSearch: '',
			};
		case PlayersActionTypes.CLEAR_PLAYERS_REDUCER:
			return {
				...state,
				players: [],
				isEdit: false,
				isFetching: false,
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Success                                                 */
		/* ================================================================ */
		case PlayersActionTypes.FETCH_PLAYERS_SUCCESS:
			return {
				...state,
				players: action.payload,
				isFetching: false,
			};
		/* ================================================================ */
		/*  Process Failure                                                 */
		/* ================================================================ */
		case PlayersActionTypes.FETCH_PLAYERS_FAILURE:
			return {
				...state,
				errorMessage: action.payload,
				isFetching: false,
			};
		default:
			return state;
	}
};

export default playersReducer;
