import { SwitchBlock } from '../../molecule/switch/Switch.styles';

export const Switch = ({ label, value, id, action = () => {} }) => {
	return (
		<SwitchBlock hasId={id}>
			<label className='toggle-label' htmlFor={id}>
				{label}:
			</label>
			<label className='switch'>
				<input
					type='checkbox'
					id={id}
					checked={value}
					onChange={() => action(!value)}
				/>
				<span className='slider'></span>
			</label>
		</SwitchBlock>
	);
};
