import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import axios from 'axios';

import { BASEAPI } from '../../../../routes/routes';
import { getCurrentUserToken } from '../../../../firebase/firebase.utils';

// Types
import { SingleMyTeamTypes } from './types';

// Actions
import {
	fetchSingleMyTeamSuccess,
	fetchSingleMyTeamFailure,
	createSingleMyTeamFailure,
	createSingleMyTeamSuccess,
	updateSingleMyTeamSuccess,
	updateSingleMyTeamFailure,
} from './actions';
import { fetchMyTeamsStart, setMyTeamsIsEdit } from '../list-screen/actions';

// Selectors
import { selectSingleMyTeam } from './selectors';

/* ================================================================ */
/*  Actions                                                         */
/* ================================================================ */

export function* fetchSingleMyTeamStart({ payload: id }) {
	try {
		yield put(setMyTeamsIsEdit(true));

		const token = yield call(getCurrentUserToken);

		const { data: myTeam } = yield call(
			axios.get,
			`${BASEAPI}/teams/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		yield put(fetchSingleMyTeamSuccess(myTeam));
	} catch (error) {
		console.error(error);
		yield put(fetchSingleMyTeamFailure(error));
		yield put(setMyTeamsIsEdit(false));
	}
}

export function* createSingleMyTeamStart() {
	try {
		const token = yield call(getCurrentUserToken);

		const { id, ...singleMyTeam } = yield select(selectSingleMyTeam);

		yield call(
			axios.post,
			`${BASEAPI}/teams`,
			{ ...singleMyTeam },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		yield put(fetchMyTeamsStart());
		yield put(setMyTeamsIsEdit(false));
		yield put(createSingleMyTeamSuccess(null));
	} catch (error) {
		console.error(error);
		yield put(createSingleMyTeamFailure(error));
	}
}

export function* updateSingleMyTeamStart() {
	try {
		const token = yield call(getCurrentUserToken);

		const { id, ...singleMyTeam } = yield select(selectSingleMyTeam);

		yield call(
			axios.patch,
			`${BASEAPI}/teams/${id}`,
			{ ...singleMyTeam },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		yield put(fetchMyTeamsStart());
		yield put(setMyTeamsIsEdit(false));
		yield put(updateSingleMyTeamSuccess(null));
	} catch (error) {
		console.error(error);
		yield put(updateSingleMyTeamFailure(error));
	}
}

/* ================================================================ */
/*  Listeners                                                       */
/* ================================================================ */

export function* onFetchSingleMyTeamStart() {
	yield takeLatest(
		SingleMyTeamTypes.FETCH_SINGLE_MY_TEAM_START,
		fetchSingleMyTeamStart
	);
}

export function* onCreateSingleMyTeamStart() {
	yield takeLatest(
		SingleMyTeamTypes.CREATE_SINGLE_MY_TEAM_START,
		createSingleMyTeamStart
	);
}

export function* onUpdateSingleMyTeamStart() {
	yield takeLatest(
		SingleMyTeamTypes.UPDATE_SINGLE_MY_TEAM_START,
		updateSingleMyTeamStart
	);
}

/* ================================================================ */
/*  Root Saga                                                       */
/* ================================================================ */

export default function* singleMyTeamSagas() {
	yield all([
		call(onFetchSingleMyTeamStart),
		call(onCreateSingleMyTeamStart),
		call(onUpdateSingleMyTeamStart),
	]);
}
