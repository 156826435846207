import styled from 'styled-components';
import stadiumImage from '../../assets/stadium-1.jpg';

export const LoginMain = styled.main`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100vh;
	flex-direction: column;
	align-items: center;
	background-image: linear-gradient(
			180deg,
			rgba(9, 72, 153, 0.82),
			rgba(9, 72, 153, 0.82)
		),
		url(${stadiumImage});
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
`;

export const LoginBlock = styled.div`
	display: flex;
	margin-top: 12vh;
	flex-direction: column;
	align-items: center;

	img {
		width: 160px;
	}
`;

export const LoginInputBlock = styled.div`
	display: grid;
	margin-top: 40px;
	padding: 40px;
	grid-auto-columns: 1fr;
	grid-row-gap: 40px;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	justify-items: center;
	border-radius: 4px;
	background-color: #fff;

	.MuiTextField-root {
		width: 320px;
	}
`;

export const LoginMain2 = styled.main`
	display: grid;
	width: 100%;
	height: 100vh;
	grid-auto-columns: 1fr;
	grid-template-columns: 1fr 0.5fr;
	grid-template-rows: auto;
`;
