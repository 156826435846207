import CountriesActionTypes from './types';

/* ================================================================ */
/*  Process Start  	                                                */
/* ================================================================ */

export const fetchCountriesStart = () => ({
	type: CountriesActionTypes.FETCH_COUNTRIES_START,
});

export const clearCountriesReducer = () => ({
	type: CountriesActionTypes.CLEAR_COUNTRIES_REDUCER,
});

/* ================================================================ */
/*  Process Success	                                                */
/* ================================================================ */

export const fetchCountriesSuccess = countries => ({
	type: CountriesActionTypes.FETCH_COUNTRIES_SUCCESS,
	payload: countries,
});

/* ================================================================ */
/*  Process Failure	       	                                        */
/* ================================================================ */

export const fetchCountriesFailure = errorMessage => ({
	type: CountriesActionTypes.FETCH_COUNTRIES_FAILURE,
	payload: errorMessage,
});
