import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// Redux: Selectors
import {
	selectMyTeams,
	selectMyTeamsIsFetching,
	selectMyTeamsIsEdit,
} from '../../pages/my-teams/redux/list-screen/selectors';
import {
	selectSingleMyTeam,
	selectSingleMyTeamIsSingleFetching,
} from '../../pages/my-teams/redux/edit-screen/selectors';

// Redux: Actions
import {
	fetchMyTeamsStart,
	setMyTeamsIsEdit,
} from '../../pages/my-teams/redux/list-screen/actions';
import {
	fetchSingleMyTeamStart,
	clearSingleMyTeam,
	createSingleMyTeamStart,
	updateSingleMyTeamStart,
	setSingleMyTeamName,
	setSingleMyTeamIsActive,
	setSingleMyTeamStorageFolder,
	setSingleMyTeamDefaultMatchMinutes,
	setSingleMyTeamCountryId,
	setSingleMyTeamTypeId,
} from '../../pages/my-teams/redux/edit-screen/actions';

export const WithReduxMyTeams = WrappedComponent => {
	// Add Redux actions and state to the WrappedComponent
	const WithData = props => {
		return <WrappedComponent {...props} />;
	};

	// Redux: Selectors
	const mapStateToProps = createStructuredSelector({
		myTeams: selectMyTeams,
		singleMyTeam: selectSingleMyTeam,
		myTeamsIsFetching: selectMyTeamsIsFetching,
		myTeamIsSingleFetching: selectSingleMyTeamIsSingleFetching,
		myTeamsIsEdit: selectMyTeamsIsEdit,
	});

	// Redux: Actions
	const mapDispatchToProps = dispatch => ({
		fetchMyTeamsStart: () => dispatch(fetchMyTeamsStart()),
		fetchSingleMyTeamStart: id => dispatch(fetchSingleMyTeamStart(id)),
		setMyTeamsIsEdit: bool => dispatch(setMyTeamsIsEdit(bool)),
		clearSingleMyTeam: () => dispatch(clearSingleMyTeam()),
		createSingleMyTeamStart: () => dispatch(createSingleMyTeamStart()),
		updateSingleMyTeamStart: () => dispatch(updateSingleMyTeamStart()),
		setSingleMyTeamName: name => dispatch(setSingleMyTeamName(name)),
		setSingleMyTeamIsActive: bool =>
			dispatch(setSingleMyTeamIsActive(bool)),
		setSingleMyTeamStorageFolder: name =>
			dispatch(setSingleMyTeamStorageFolder(name)),
		setSingleMyTeamDefaultMatchMinutes: minutes =>
			dispatch(setSingleMyTeamDefaultMatchMinutes(minutes)),
		setSingleMyTeamCountryId: id => dispatch(setSingleMyTeamCountryId(id)),
		setSingleMyTeamTypeId: id => dispatch(setSingleMyTeamTypeId(id)),
	});

	return connect(mapStateToProps, mapDispatchToProps)(WithData);
};
