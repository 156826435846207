import { InputBlock } from './InputField.styles';

export const InputField = ({
	label,
	placeholder = '',
	type,
	value,
	action = () => {},
	errorMessage,
	required,
	isInvalid,
	disableError,
	size,
	className,
}) => {
	return (
		<InputBlock className={className} size={size}>
			{placeholder === '' && (
				<label htmlFor='text-input'>
					{`${label}: `}
					<span>{required ? '*' : ''}</span>
				</label>
			)}
			<input
				placeholder={placeholder}
				className='input'
				id='text-input'
				type={type || 'text'}
				value={value}
				onChange={event => action(event.target.value)}
			/>
			{!disableError && (
				<div className='error-label'>
					<p>{isInvalid ? errorMessage : ''}</p>
				</div>
			)}
		</InputBlock>
	);
};
