import Loader from 'react-loader-spinner';

import { colorStyles } from '../../../styles/global.styles';

import { LoaderBlock, LoaderListBlock } from './Loader.styles';

export const LoadingSmall = () => {
	return (
		<LoaderBlock>
			<Loader
				type='TailSpin'
				color={colorStyles.primary}
				height={30}
				width={30}
			/>
		</LoaderBlock>
	);
};

export const LoadingList = () => {
	return (
		<LoaderListBlock>
			<Loader
				type='TailSpin'
				color={colorStyles.primary}
				height={80}
				width={80}
			/>
		</LoaderListBlock>
	);
};
