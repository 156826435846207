import { Fragment, useEffect } from 'react';
import { compose } from 'redux';

import List from './components/List.component';
import EditPopup from './components/EditPopup.component';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { Box } from '../../components/atom/containers/Box.styles';
import { FilterBlock2 } from '../../components/molecule/table/Table.styles';
import { IconButton } from '../../components/atom/buttons/Button.styles';
import { LoadingList } from '../../components/atom/loader/Loader.component';

import { WithReduxMyTeams } from '../../components/hoc/WithReduxMyTeams.hoc';

const MyTeamsList = ({
	myTeamsIsEdit,
	myTeamsIsFetching,
	fetchMyTeamsStart,
	clearSingleMyTeam,
	setMyTeamsIsEdit,
}) => {
	useEffect(() => {
		fetchMyTeamsStart();
	}, [fetchMyTeamsStart]);

	return (
		<Fragment>
			{!myTeamsIsFetching ? (
				<Box padding='48px'>
					<FilterBlock2>
						<div />
						<IconButton
							onClick={() => {
								clearSingleMyTeam();
								setMyTeamsIsEdit(true);
							}}
						>
							<PlusIcon />
							New Team
						</IconButton>
					</FilterBlock2>
					<List />
				</Box>
			) : (
				<LoadingList />
			)}
			{myTeamsIsEdit && <EditPopup />}
		</Fragment>
	);
};

export default compose(WithReduxMyTeams)(MyTeamsList);
