import { UserTypes } from './types';

const INITIAL_STATE = {
	currentUser: null,
	email: '',
	password: '',
	isResetPassword: false,
	isResetPasswordSuccess: false,
	isFetching: false,
	errorMessage: '',
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* ================================================================ */
		/*  Process Start                                                   */
		/* ================================================================ */
		case UserTypes.EMAIL_SIGN_IN_START:
			return {
				...state,
				isFetching: true,
			};
		case UserTypes.SET_LOGIN_EMAIL:
			return {
				...state,
				email: action.payload,
			};
		case UserTypes.SET_LOGIN_PASSWORD:
			return {
				...state,
				password: action.payload,
			};
		case UserTypes.SET_RESET_PASSWORD:
			return {
				...state,
				isResetPassword: action.payload,
			};
		case UserTypes.LOG_OUT:
			return {
				...state,
				currentUser: null,
				email: '',
				password: '',
				isResetPassword: false,
				isFetching: false,
				errorMessage: '',
			};
		case UserTypes.CLEAR_LOGIN_ERROR_MESSAGE:
			return {
				...state,
				errorMessage: '',
			};
		case UserTypes.CLEAR_LOGIN_CREDENTIALS:
			return {
				...state,
				email: '',
				password: '',
				isResetPassword: false,
				isResetPasswordSuccess: false,
				isFetching: false,
				errorMessage: '',
			};
		case UserTypes.CLEAR_AFTER_PASSWORD_RESET:
			return {
				...state,
				password: '',
				isResetPassword: false,
				isResetPasswordSuccess: false,
				isFetching: false,
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Success                                                 */
		/* ================================================================ */
		case UserTypes.EMAIL_SIGN_IN_SUCCESS:
			return {
				...state,
				currentUser: action.payload,
				isFetching: false,
			};
		case UserTypes.SEND_PASSWORD_RESET_EMAIL_SUCCESS:
			return {
				...state,
				isResetPasswordSuccess: true,
			};
		/* ================================================================ */
		/*  Process Failure                                                 */
		/* ================================================================ */
		case UserTypes.EMAIL_SIGN_IN_FAILURE:
		case UserTypes.SEND_PASSWORD_RESET_EMAIL_FAILURE:
			return {
				...state,
				errorMessage: action.payload,
				isFetching: false,
			};
		default:
			return state;
	}
};

export default userReducer;
