import moment from 'moment';
import { DatePickerBlock, StyledFlatpickr } from './DatePicker.styles';

export const DatePicker = ({
	label,
	format = 'Y-m-d',
	required,
	errorMessage,
	isInvalid,
	value,
	action = () => {},
}) => {
	return (
		<DatePickerBlock>
			<label htmlFor='date-input'>
				{`${label}: `}
				<span>{required ? '*' : ''}</span>
			</label>
			<StyledFlatpickr
				id='text-input'
				value={value}
				options={{ dateFormat: format }}
				onChange={(dates, date) => {
					action(moment(date).toISOString(true));
				}}
			/>
			<div className='error-label'>
				<p>{isInvalid ? errorMessage : ''}</p>
			</div>
		</DatePickerBlock>
	);
};
