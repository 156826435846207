import {
	Button,
	InverseButton,
} from '../../../../components/atom/buttons/Button.styles';
import { ButtonBlock2 } from '../../../../components/atom/inputs/Inputs.styles';
import { LoadingSmall } from '../../../../components/atom/loader/Loader.component';

export const ButtonSection = ({
	isResetPassword,
	isResetPasswordSuccess,
	isFetching,
	emailSignInStart,
	sendPasswordResetEmailStart,
	clearAfterPasswordReset,
	setResetPassword,
	className,
}) => {
	if (!isResetPassword)
		return isFetching ? (
			<LoadingSmall />
		) : (
			<Button onClick={emailSignInStart}>login</Button>
		);
	if (isResetPassword && !isResetPasswordSuccess)
		return (
			<ButtonBlock2 className={className}>
				<InverseButton
					onClick={() => {
						setResetPassword(false);
					}}
				>
					Cancel
				</InverseButton>
				<Button
					className='save-button'
					onClick={sendPasswordResetEmailStart}
				>
					Reset Password
				</Button>
			</ButtonBlock2>
		);
	if (isResetPasswordSuccess)
		return (
			<ButtonBlock2 className={className}>
				<InverseButton
					onClick={() => {
						clearAfterPasswordReset();
					}}
				>
					Back
				</InverseButton>
			</ButtonBlock2>
		);
};
