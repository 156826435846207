import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { compose } from 'redux';

import * as ROUTES from './routes/routes';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { MainBlock2 } from './App.styles';
import { MenuBlock } from './components/organism/menu-block/MenuBlock.component';
import { Login } from './pages/login/Login.page';
import ContentBlock from './components/organism/content-block/ContentBlock.component';

import { WithReduxCountries } from './components/hoc/WithReduxCountries.hoc';
import { WithReduxTeamTypes } from './components/hoc/WithReduxTeamTypes.hoc';
import { WithReduxUser } from './components/hoc/WithReduxUser.hoc';

function App({ fetchCountriesStart, fetchTeamTypesStart, currentUser }) {
	const isLoggedOut = currentUser === null;

	useEffect(() => {
		if (!isLoggedOut) {
			fetchCountriesStart();
			fetchTeamTypesStart();
		}
	}, [fetchCountriesStart, fetchTeamTypesStart, isLoggedOut]);

	return (
		<Route path={ROUTES.HOME}>
			{isLoggedOut ? (
				<Login />
			) : (
				<MainBlock2>
					<MenuBlock />
					<ContentBlock />
				</MainBlock2>
			)}
		</Route>
	);
}

export default compose(
	WithReduxCountries,
	WithReduxTeamTypes,
	WithReduxUser
)(App);
