export const UserTypes = {
	EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
	EMAIL_SIGN_IN_SUCCESS: 'EMAIL_SIGN_IN_SUCCESS',
	EMAIL_SIGN_IN_FAILURE: 'EMAIL_SIGN_IN_FAILURE',

	SET_LOGIN_EMAIL: 'SET_LOGIN_EMAIL',
	SET_LOGIN_PASSWORD: 'SET_LOGIN_PASSWORD',
	SET_RESET_PASSWORD: 'SET_RESET_PASSWORD',

	CLEAR_LOGIN_CREDENTIALS: 'CLEAR_LOGIN_CREDENTIALS',
	CLEAR_LOGIN_ERROR_MESSAGE: 'CLEAR_LOGIN_ERROR_MESSAGE',
	CLEAR_AFTER_PASSWORD_RESET: 'CLEAR_AFTER_PASSWORD_RESET',

	SEND_PASSWORD_RESET_EMAIL_START: 'SEND_PASSWORD_RESET_EMAIL_START',
	SEND_PASSWORD_RESET_EMAIL_SUCCESS: 'SEND_PASSWORD_RESET_EMAIL_SUCCESS',
	SEND_PASSWORD_RESET_EMAIL_FAILURE: 'SEND_PASSWORD_RESET_EMAIL_FAILURE',

	LOG_OUT: 'LOG_OUT',
};
