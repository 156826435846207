import PositionTypesActionTypes from './types';

const INITIAL_STATE = {
	positionTypes: [],
	isFetching: false,
	errorMessage: '',
};

const positionTypesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* ================================================================ */
		/*  Process Start                                                   */
		/* ================================================================ */
		case PositionTypesActionTypes.FETCH_POSITION_TYPES_START:
		case PositionTypesActionTypes.SET_POSITION_TYPES_IS_FETCHING:
			return {
				...state,
				isFetching: true,
			};
		case PositionTypesActionTypes.CLEAR_POSITION_TYPES_REDUCER:
			return {
				...state,
				positionTypes: [],
				isFetching: false,
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Success                                                 */
		/* ================================================================ */
		case PositionTypesActionTypes.FETCH_POSITION_TYPES_SUCCESS:
			return {
				...state,
				positionTypes: action.payload,
				isFetching: false,
			};
		/* ================================================================ */
		/*  Process Failure                                                 */
		/* ================================================================ */
		case PositionTypesActionTypes.FETCH_POSITION_TYPES_FAILURE:
			return {
				...state,
				errorMessage: action.payload,
				isFetching: false,
			};
		default:
			return state;
	}
};

export default positionTypesReducer;
