import { InputBlock, StyledNumberFormatter } from './NumberField.styles';

export const NumberField = ({
	label,
	value,
	action = () => {},
	errorMessage,
	required,
	isInvalid,
	size,
	className,
}) => {
	return (
		<InputBlock size={size} className={className}>
			<label htmlFor='text-input'>
				{`${label}: `}
				<span>{required ? '*' : ''}</span>
			</label>
			<StyledNumberFormatter
				id='text-input'
				value={value}
				onChange={event => action(parseInt(event.target.value, 10))}
			/>
			<div className='error-label'>
				<p>{isInvalid ? errorMessage : ''}</p>
			</div>
		</InputBlock>
	);
};
