import { MyTeamsTypes } from './types';

/* ================================================================ */
/*  Process Start  	                                                */
/* ================================================================ */

export const fetchMyTeamsStart = () => ({
	type: MyTeamsTypes.FETCH_MY_TEAMS_START,
});

export const setMyTeamsIsFetching = () => ({
	type: MyTeamsTypes.SET_MY_TEAMS_IS_FETCHING,
});

export const setMyTeamsIsEdit = bool => ({
	type: MyTeamsTypes.SET_MY_TEAMS_IS_EDIT,
	payload: bool,
});

export const clearMyTeamsReducer = () => ({
	type: MyTeamsTypes.CLEAR_MY_TEAM_REDUCER,
});

/* ================================================================ */
/*  Process Success	                                                */
/* ================================================================ */

export const fetchMyTeamsSuccess = myTeams => ({
	type: MyTeamsTypes.FETCH_MY_TEAMS_SUCCESS,
	payload: myTeams,
});

/* ================================================================ */
/*  Process Failure	       	                                        */
/* ================================================================ */

export const fetchMyTeamsFailure = errorMessage => ({
	type: MyTeamsTypes.FETCH_MY_TEAMS_FAILURE,
	payload: errorMessage,
});
