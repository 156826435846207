import { OppositionsTypes } from './types';

const INITIAL_STATE = {
	oppositions: [],
	filteredOppositions: [],
	filterTeamTypeId: '',
	isEdit: false,
	isFetching: false,
	errorMessage: '',
};

const oppositionsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		/* ================================================================ */
		/*  Process Start                                                   */
		/* ================================================================ */
		case OppositionsTypes.FETCH_OPPOSITIONS_START:
		case OppositionsTypes.SET_OPPOSITIONS_IS_FETCHING:
			return {
				...state,
				isFetching: true,
			};
		case OppositionsTypes.SET_OPPOSITIONS_IS_EDIT:
			return {
				...state,
				isEdit: action.payload,
			};
		case OppositionsTypes.SET_FILTERED_OPPOSITIONS:
			return {
				...state,
				filteredOppositions: action.payload,
			};
		case OppositionsTypes.SET_OPPOSITIONS_FILTER_TEAM_TYPE_ID:
			return {
				...state,
				filterTeamTypeId: action.payload,
			};
		case OppositionsTypes.CLEAR_OPPOSITIONS_FILTER_TEAM_TYPE_ID:
			return {
				...state,
				filterTeamTypeId: '',
				filteredOppositions: state.oppositions,
			};
		case OppositionsTypes.CLEAR_OPPOSITIONS_REDUCER:
			return {
				...state,
				oppositions: [],
				filteredOppositions: [],
				filterTeamTypeId: '',
				isEdit: false,
				isFetching: false,
				errorMessage: '',
			};
		/* ================================================================ */
		/*  Process Success                                                 */
		/* ================================================================ */
		case OppositionsTypes.FETCH_OPPOSITIONS_SUCCESS:
			return {
				...state,
				oppositions: action.payload,
				isFetching: false,
			};
		/* ================================================================ */
		/*  Process Failure                                                 */
		/* ================================================================ */
		case OppositionsTypes.FETCH_OPPOSITIONS_FAILURE:
			return {
				...state,
				errorMessage: action.payload,
				isFetching: false,
			};
		default:
			return state;
	}
};

export default oppositionsReducer;
